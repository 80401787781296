import React, { memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import ServiceBase from "../../utils/ServiceBase";
import { makeSelectIsAuthenticated } from "../../store/selectors";
import { setAuthenticated, logOut } from "../../store/actions";
import { Form, Row, Typography, Button, Checkbox, Col, Modal, Input } from "antd";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import { BASE_URL } from "../../utils/constants";
import Globals from "../../utils/globals";

const { Title } = Typography;

const error_sigin = {
  'code : invalid_user_authentication': '이메일 또는 비밀번호를 다시 확인해주세요.',
  'code : invalid_user_deactivated': '해당 계정은 정지상태입니다. 관리자에게 문의해주세요.',
}

const SignIn = ({ className, isAuthenticated, setAuthenticated, logOut }) => {
  const [isFetching, setIsFetching] = useState(false);

  const [error, setError] = useState(null);
  const [loadding, setLoadding] = useState(false);
  const [email, setEmail] = useState('');  
  const [password, setPassword] = useState('');  

  const _handleLogIn = useCallback(
    async () => {
      if(email === '' || password === '') return;
      setIsFetching(true);
      let result = await ServiceBase.requestJson({
        method: "POST",
        baseUrl: BASE_URL,
        url: "auth/login",
        data: {
          email: email,
          password: password,
        },
      });
      if (result.hasErrors) {
        try {
          if(result.errors[0].status === 400) {
            setError(error_sigin[result.errors[0].data.details[0]])
          }
          
        } catch (error) {
          
        }
      } else {
        const profile = {
          user: result.value.user,
        }
        Globals.setSession({
          public: {
            currentUser: JSON.stringify(profile),
          },
          private: {
            token: result.value.token,
          },
        });
        setAuthenticated({
          isAuthenticated: true,
          profile,
        });
      }
      setIsFetching(false);
    },
    [setAuthenticated, email, password]
  );

  if (isAuthenticated) {
    return <Redirect to="/my-workspace" />;
  }
  return (
    <div className={className}>
      <div className="content">
        <Row>
          <Col xs={24}>
            <Title className="loginTitle" level={3}>
              로그인
            </Title>
          </Col>
          <div className="icon">
            {/* <div>
              icon
            </div> */}
            {/* <img className="img" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="avatar" width="100" height="100"/>   */}
          </div>
        <div className="form_submit">
          <div style={{width: 380}}>
            <Input onChange={(e) => {
              setEmail(e.target.value)
            }} className="input" placeholder="이메일을 입력하세요." />
            <Input.Password onChange={(e) => {
              setPassword(e.target.value)
            }} className="input" placeholder="비밀번호를 입력하세요." />
             {
              error && (
                <div className="error">
                  {error}
                </div>
              )
            }
          </div>
         
        </div>
          <Button 
            disabled={email === '' || password === ''}
            className="btnLogin" style={{background: '#000'}} loading={isFetching} onClick={_handleLogIn}>
            <div>로그인</div>
          </Button>
        </Row>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  className: PropTypes.any.isRequired,
};
const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuthenticated,
      logOut,
    },
    dispatch
  );
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withConnect,
  memo
)(styled(SignIn)`
    opacity: 0.9;
    display: flex;
    justify-content: center;
    margin: 100px auto auto auto;
  .form_submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .content {
    max-width: 400px;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.2);
    opacity: 1;
    .ant-row {
      margin: 0px
    }
    .error {
      background: red;
      padding: 12px 10px;
      margin: 0px 0px; 
      color: #fff;
    }
    .icon {
   
    }
    .ant-form-item {
      padding: 0 20px;
    }
    .loginTitle {
      padding: 10px 20px;
      color: #000;
      border-bottom: 1px solid #ccc;
    }
    .input {
      min-width: 240px;
      border-color: #000;
      border-radius: 2px;
      min-height: 44px;
      margin: 10px 0px;
    }
  }
  .btnLogin {
    margin-top: 20px;
    width: 100%;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    }
  }
  .btnLogin:hover {
    margin-top: 20px;
    width: 100%;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: center;
    }
  }
`);
