import React, { useState, useCallback, useEffect, memo } from "react";
import { Input, Select, Form, InputNumber } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { Ui } from "../../utils/Ui";
import * as style from '../../components/Variables';
import NumberInput from '../../components/NumberInput';
const { Option } = Select;

const FormUser = memo(({ className, onSave }) => {
  const [form] = Form.useForm();
 
  const [isShowModalAdd, setShowModalAdd] = useState(false)
  const [phone_number, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')

  const onFinish = (values) => {
    onSave({
      ...values,
      phone_number: phone_number,
      email: email,
    })
  }

  const onFinishFailed = () => { }
 
  return (
    <div className={className}>
      <Form
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
  
        initialValues={{ 

        }}
      >
        <div className="d-flex content">
            <div className="left">
                <div className="title_left">이름</div>
                <div className="title_left">이메일</div>
                <div className="title_left">전화번호</div>
            </div>
            <div className="right">
              <Form.Item name="username">
                <Input className="input_field" placeholder="이름을 입력하세요."/>
              </Form.Item>
              <div className="d-flex input_field input_content">
                <Input value={email} onChange={(e) => {
                  let value = e.target.value
                  value = value.replace(/[^\x00-\x7F]/ig, '')
                  value = value.replace('@', '')
                  setEmail(value)
                }} className="mail-children-1" placeholder=""/>
                <span>@</span>
                <Form.Item 
                  rules={[{ required: true, message: '메일 선택' }]}
                  name="email_sup">
                  <Select className="select-mail" placeholder={"메일 선택"} onChange={() => {}}>
                    <Option value="fnnews.com">fnnews.com</Option>
                    <Option value="coinplug.com">coinplug.com</Option>
                  </Select>
                </Form.Item>
                </div>
                <NumberInput onChange={(value) => {
                  setPhoneNumber(value)
                }} value={phone_number} className="input_field" placeholder="전화번호를 입력하세요."/>
            </div>
        </div>
        <div className="note">
           <p> 초기 비밀번호는 이메일1@ 입니다.</p>
            <div>예) user@coinplug.com 인 경우</div>
            <div>비밀번호는 user@coinplug.com1@ 입니다</div>
        </div>
        <div>
          <button htmlType="submit" className="footer">
            등록하기
          </button>
        </div>
      </Form>
    </div>
  );
});

FormUser.propTypes = {
  className: PropTypes.any,
};
export default styled(FormUser)`
  .note {
    margin: 10px 20px;
    margin-top: 30px;
    padding: 10px;
    background: ${style.color.backgroundGray};
  }
  .footer {
    justify-content: center;
    background: #000;
		display: flex;
		padding: 0;
    cursor: pointer;
    color: #fff;
    padding: 20px;
    width: 100%;
    text-align: center;
    border: none;
  }
  
 .content {
    padding: 10px 20px;
    .left {
		min-width: 130px;
		margin-right: 20px;
		background: ${style.color.backgroundGray};
		text-align: center;
		.title_left {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 35px;
			margin-bottom: 10px;
		}
	}
	.right {
		display: flex;
		flex-direction: column;
		.right_bottom {
			height: 35px;
			align-items: center;
			display: flex;
			color: #000;
    }
    .input_field {
      width: 300px;
      height: 35px;
      
    }
    .input_content {
      margin-bottom: 15px;
      span {
        width: 30px;
        text-align: center;
      }
      .mail-children-1 {
        width: 140px;
      }
      .select-mail {
        width: 130px;
        height: 35px;
      }
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
 
`;
