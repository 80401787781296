/* eslint-disable react/prop-types */
import React, { memo } from "react";
import { Modal } from "antd";
import styled from "styled-components";


const PopUpBase = memo(({ className, footer, children, onClickFooter, footerNew, ...restProps }) => {
  return (
	<Modal
		maskStyle={{borderRadius: 10}}
		style={{borderRadius: 10}}
		className={className}
		{...restProps}
		footer={footerNew ? footerNew : <div onClick={onClickFooter} className="btnFooter">{footer}</div>}
	>
		{children}
	</Modal>
  );
});
export default styled(PopUpBase)`
	.ant-modal-content {
		border-radius: 13px;
		overflow: hidden;
	}
	.ant-modal-header {
		border-bottom: 0.5px solid #c1bbbb;
	}
	.ant-modal-footer {
		justify-content: center;
    	background: #000;
		display: flex;
		padding: 0;
		.btnFooter {
			cursor: pointer;
			color: #fff;
			padding: 20px;
			width: 100%;
			text-align: center;
		}
	}
	.ant-modal-close-x {
		color: #000;
		.anticon anticon-close ant-modal-close-icon {
			position: absolute;
			top: 20px;
			right: 20px;
			color: #000;
		}
	}
	
`;
