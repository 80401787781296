/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import React, { memo, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Dropdown, Row, Col, Menu } from "antd";
import _ from "lodash";
import {Link, useLocation, useHistory } from "react-router-dom";
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import { LogoutOutlined, NotificationOutlined } from '@ant-design/icons';

const TopMenu = memo(
  ({
    isAuthenticated,
    profile,
    className,
    onLogOut,
    onSetAuthenticated,
  }) => {

    const history = useHistory();

    const handleLogOut = useCallback(async () => {
      let result = await ServiceBase.requestJson({
        method: "POST",
        url: "auth/logout",
        data: {},
      });
      if (result.hasErrors) {
      } else {
        onLogOut()
      }
    })

    // useEffect(() => {
    // }, []);
    const location = useLocation();
    if(location.pathname.includes("/event/")) {
      return null
    }
    const menu = (
      <Menu selectedKeys={[location.pathname.includes("/my-profile") ? '1' : (location.pathname.includes("/admin-setting") ? '2' : '')]}
        onClick={(item) => {
        if(item.key === "1") {
          history.push('/my-profile')
        }
        if(item.key === "2") {
          history.push('/admin-setting')
        }
        if(item.key === "3") {
          handleLogOut()
        }
      }}>
        <Menu.Item key="1">내 정보 </Menu.Item>
        <Menu.Item key="2">관리자 관리 </Menu.Item>
        <Menu.Item key="3">로그아웃</Menu.Item>
      </Menu>
    );
    
    return (
      <div className={className}>
        <Row justify="space-between" className="header">
          <Col>
            <ul>
              <li>
                <Link
                  to={`/my-workspace`}>
                  ThePol Admin
                </Link>
              </li>
              <li>
                <Link className={location.pathname.includes("/my-workspace") ? "active" : ""} 
                  to={`/my-workspace`}>
                  MY WORKSPACE
                </Link>  
              </li>
              <li>
                <Link className={location.pathname.includes("/rewards") ? "active" : ""} 
                  to={`/rewards`}>
                  REWARDS
                </Link> 
              </li>
            </ul>
          </Col>

          <Col>
          {
            isAuthenticated ? (
              <div className="menuright">
                <img src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="avatar" width="30" height="30"/>  
                <Dropdown overlay={menu} placement="bottomRight">
                  <div className="name">{profile && profile.user.username}</div>
                </Dropdown>
                <div className="btn_noti">
                  <Link to={`/notifi-page`}>
                    <NotificationOutlined style={{color: '#fff', fontSize: 20, paddingLeft: 20}}/>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="menuright">
                <div className="title_login">로그인</div>
              </div>
            )
          }
          </Col>
        </Row>
      </div>
    );
  }
);
TopMenu.propTypes = {
  className: PropTypes.any.isRequired,
  // profile: PropTypes.any.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  // onLogOut: PropTypes.func.isRequired,
};
export default styled(TopMenu)`
  padding: 0;
  height: 54px;
  line-height: inherit;
  background-color: #333333;
  display: flex;
  flex: 1;
  justify-content: space-between;
  .header {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  .title_login {
    color: #ccc;
  }
  .menuright {
    padding: 0 16px;
    flex: 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 15px;
    }
  }
  .name {
    cursor: pointer;
    color: #fff;
    padding: 0 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-row {
    flex: 1;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  li {
    float: left;
  }
  .active {
    color: #fff;
    font-weight: 600;
  }
  
  li a {
    display: block;
    color: #ccc;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }
  
  li a:hover {
  }
  .btn_noti {
    
  }
  
`;
