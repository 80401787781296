import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import { useLocation } from "react-router-dom";
import ItemText from './ItemText';
import ItemTextNew from './ItemTextNew';
const item_name_gender = {
    'male': '남성',
    'female': '여성',
    'other': '알수없음',
};

const item_last = {
    'THEPOL': '더폴',
    'FNSURVEY': '바로참여'
}

const array_age = ['teenager', '20s', '30s', '40s', '50s', 'over_60s', 'other'];
const age_title = {
    'teenager': '10대',
    '20s': '20대',
    '30s': '30대',
    '40s': '40대',
    '50s': '50대',
    'over_60s': '60대 이상',
    'other': '알수없음'
}

const DetailSurveyText = memo(({ className, item_question, indexParent }) => {
    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");
    return (
        <div className={className} style={{ padding: isNotEvent ? 20 : 0, }}>
            {
                isNotEvent ? <div className="title_s">성별</div> : null
            }
            {/* GENDERE */}
            <div style={{ width: '100%' }}>
                {
                    Object.keys(item_name_gender).map((item, index) => {
                        let question_answers_new_gender = _.values(item_question.answers);
                        if (item === 'total') return;
                        if (!item_question?.statistics?.gender?.[item]) return (
                            <div key={index} className="item_content">
                                <div style={{ breakBefore: 'auto' }} />
                                <div className="pb_one_item">
                                    {
                                        (index === 0 && !isNotEvent) ? (
                                            <div style={{ width: '100%' }}>
                                                <div className="title" style={isNotEvent ? {} : {fontSize: 14}}>답변 통계 (자세히)</div>
                                                <div className="header_black" style={isNotEvent ? {} : {fontSize: 12}}>{`${indexParent + 1}. ${item_question.content}?`}</div>
                                                <div className={isNotEvent ? "title_s" : "title_s title_s_center"}>성별</div>
                                            </div>
                                        ) : null
                                    }
                                    <div className="padding_content">
                                        <div className="title_gender" style={isNotEvent ? {} : {fontSize: 12}}>{item_name_gender[item]}</div>
                                        <div style={{ marginLeft: 60}}>
                                            {
                                                question_answers_new_gender.map((item, index) => (
                                                    <div key={index} className="item_text">
                                                        <ItemTextNew key={index} index={index} is_max={item.is_max}
                                                            content={item?.content || ''}
                                                            percent={item?.percent || 0}
                                                            count={item?.count || 0}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ breakAfter: 'auto' }} />
                            </div>
                        );
                        let total_count_gender = Object.values(item_question?.statistics?.gender?.[item]?.total).reduce((a, b) => a + b, 0)

                        question_answers_new_gender.map(answer => {
                            let array_count = Object.values(item_question?.statistics?.gender?.[item][answer.hash_value])
                            answer.count = array_count.reduce((a, b) => a + b, 0)
                            answer.percent = (answer.count / total_count_gender) * 100
                        })

                        let temp_question = JSON.parse(JSON.stringify(question_answers_new_gender))
                        temp_question.sort((a, b) => { return b.count - a.count })
                        let max_count = 0
                        try {
                            max_count = temp_question[0].count
                            question_answers_new_gender.map(answer => {
                                if (answer.count === max_count) answer.is_max = true
                                else answer.is_max = false
                            })
                        } catch (error) {
                        }

                        return <div key={index} className="item_content">
                            <div style={{ breakBefore: 'auto' }} />
                            <div className="pb_one_item">
                                {
                                    (index === 0 && !isNotEvent) ? (
                                        <div style={{ width: '100%' }}>
                                            <div className="title" style={isNotEvent ? {} : {fontSize: 14}}>답변 통계 (자세히)</div>
                                            <div className="header_black" style={isNotEvent ? {} : {fontSize: 12}}>{`${indexParent + 1}. ${item_question.content}?`}</div>
                                            <div className={isNotEvent ? "title_s" : "title_s title_s_center"}>성별</div>
                                        </div>
                                    ) : null
                                }
                                <div className="padding_content">
                                    <div className="title_gender" style={isNotEvent ? {} : {fontSize: 12}}>{item_name_gender[item]}</div>
                                    <div style={{ marginLeft: 60}}>
                                        {
                                            question_answers_new_gender.map((item, index) => (
                                                <div key={index} className="item_text">
                                                    <ItemTextNew key={index} index={index} is_max={item.is_max}
                                                        content={item?.content || ''}
                                                        percent={item?.percent || 0}
                                                        count={item?.count || 0}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ breakAfter: 'auto' }} >&nbsp;</div>
                        </div>
                    })
                }
            </div>
            {/* AGEEEEEEEEEEE */}
            <div style={{ width: '100%' }}>
                {
                    isNotEvent ?  <div className="title_s">연령</div> : null
                }
                {
                    array_age.map((item, index) => {
                        if (item === 'total') return;
                        let question_answers_new_age = _.values(item_question.answers);
                        if (!item_question?.statistics?.age?.[item]) return (
                            <div key={index} className="item_content ">
                                <div style={{ breakBefore: 'auto' }} />
                                <div className="pb_one_item">
                                    {
                                        (index === 0 && !isNotEvent) ? (
                                            <div className={isNotEvent ? "title_s" : "title_s title_s_center"}>연령</div>
                                        ) : null
                                    }
                                    <div className="padding_content">
                                        <div className="title_gender" style={isNotEvent ? {} : {fontSize: 12}}>{age_title[item]}</div>
                                        <div style={{ marginLeft: 60}}>
                                            {
                                                question_answers_new_age.map((item, index) => (
                                                    <div key={index} className="item_text">
                                                        <ItemTextNew key={index} index={index} is_max={false}
                                                            content={item?.content || ''}
                                                            percent={0}
                                                            count={0}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ breakAfter: 'auto' }} >&nbsp;</div>
                            </div>
                        )

                        let total_count_age = Object.values(item_question?.statistics?.age?.[item]?.total).reduce((a, b) => a + b, 0)
                        question_answers_new_age.map(answer => {
                            let array_count = Object.values(item_question?.statistics?.age?.[item][answer.hash_value])
                            answer.count = array_count.reduce((a, b) => a + b, 0)
                            answer.percent = (answer.count / total_count_age) * 100
                        })

                        let temp_question = JSON.parse(JSON.stringify(question_answers_new_age))
                        temp_question.sort((a, b) => { return b.count - a.count })
                        let max_count = 0
                        try {
                            max_count = temp_question[0].count
                            question_answers_new_age.map(answer => {
                                if (answer.count === max_count) answer.is_max = true
                                else answer.is_max = false
                            })
                        } catch (error) {
                        }

                        return (
                            <div key={index} className="item_content">
                                <div style={{ breakBefore: 'auto' }} />
                                <div className="pb_one_item">
                                    {
                                        (index === 0 && !isNotEvent) ? (
                                            <div className={isNotEvent ? "title_s" : "title_s title_s_center"}>연령</div>
                                        ) : null
                                    }
                                    <div className="padding_content">
                                        <div className="title_gender" style={isNotEvent ? {} : {fontSize: 12}}>{age_title[item]}</div>
                                        <div style={{ marginLeft: 60}}>
                                            {
                                                question_answers_new_age.map((item, index) => (
                                                    <div key={index} className="item_text">
                                                        <ItemTextNew key={index} index={index} is_max={item.is_max}
                                                            content={item?.content || ''}
                                                            percent={item?.percent || 0}
                                                            count={item?.count || 0}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ breakAfter: 'auto' }} >&nbsp;</div>
                            </div>
                        )
                    })
                }
            </div>
            {/* CAi cuoi */}
            <div className={isNotEvent ? "title_s" : "title_s title_s_center"}>참여방법</div>
            {
                item_question?.statistics?.overview?.age?.total ? Object.keys(item_question?.statistics?.overview?.age?.total).map((item, index) => {
                    let question_answers_last = _.values(item_question.answers);
                    question_answers_last.map(answer => {
                        answer.count = _.get(item_question, 'statistics.overview.age')[answer.hash_value][item]
                        answer.percent = (answer.count / _.get(item_question, 'statistics.overview.age.total')[item]) * 100
                    })
                    let temp_question = JSON.parse(JSON.stringify(question_answers_last))
                    temp_question.sort((a, b) => { return b.count - a.count })
                    let max_count = 0
                    try {
                        max_count = temp_question[0].count
                        question_answers_last.map(answer => {
                            if (answer.count === max_count) answer.is_max = true
                            else answer.is_max = false
                        })
                    } catch (error) {
                    }
                    return (
                        <div key={index} className="item_content">
                            <div style={{ breakBefore: 'auto' }} />
                            <div className="pb_one_item">
                                <div className="padding_content">
                                    <div className="title_gender" style={isNotEvent ? {} : {fontSize: 12}}>
                                            {item_last[item]}
                                        </div>
                                        <div style={{ marginLeft: 60}}>
                                            {
                                                question_answers_last.map((item, index) => (
                                                    <div key={index} className="item_text">
                                                        <ItemTextNew key={index} index={index} is_max={item.is_max}
                                                            content={item?.content || ''}
                                                            percent={item?.percent || 0}
                                                            count={item?.count || 0}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                </div>
                            </div>
                            <div style={{ breakAfter: 'auto' }} >&nbsp;</div>
                        </div>
                    )
                }) : (
                        Object.keys(item_last).map((item, index) => {
                            let question_answers_last = _.values(item_question.answers);
                            return (
                                <div key={index} className="item_content ">
                                    <div style={{ breakBefore: 'auto' }} />
                                    <div  className="pb_one_item">
                                        <div className="padding_content">
                                            <div className="title_gender" style={isNotEvent ? {} : {fontSize: 12}}>
                                                    {item_last[item]}
                                                </div>
                                                <div style={{ marginLeft: 60}}>
                                                    {
                                                        question_answers_last.map((item, index) => (
                                                            <div key={index} className="item_text">
                                                                <ItemTextNew key={index} index={index} is_max={item.is_max}
                                                                    content={item?.content || ''}
                                                                    percent={item?.percent || 0}
                                                                    count={item?.count || 0}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                        </div>
                                    </div>
                                    <div style={{ breakAfter: 'auto' }} >&nbsp;</div>
                                </div>
                            )
                        })
                    )
            }

        </div>
    );
});

DetailSurveyText.propTypes = {
    className: PropTypes.any,
};
export default styled(DetailSurveyText)`
    .header_black {
        font-family: MalgunGothicRegular;
    }
    .item_text {
        margin: 15px 0;
    }
    .title_s {
        width: 100%;
        font-size: 17px;
        font-weight: 700;
    }
    .title_s_center {
        margin-top: 18px;
        margin-bottom: 5px;
        font-size: 13px;
        text-align: center;
    }
    .padding_content {
        padding: 5px 0px;
    }
    .item_content {
        width: 100%;
        .title_gender{
            padding-left: 30px;
            font-size: 16px;
            font-weight: bold;
    
        }
    }
`;
