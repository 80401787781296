import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import SurveyImage from './SurveyImage';
import SurveyText from './SurveyText';

const SurveyMultiple = memo(({ className, questions_info }) => {
    return (
        <div className={className}>
            {
                questions_info && questions_info.map((item_question, index) => {
                    if(item_question.answer_type === 'text') {
                        return <SurveyText key={index} index={index} item_question={item_question}/>
                    } else {
                        return <SurveyImage answer_type={item_question.answer_type} key={index} index={index} item_question={item_question}/>
                    }
                })
            }

        </div>
    );
});

SurveyMultiple.propTypes = {
  className: PropTypes.any,
};
export default styled(SurveyMultiple)`

    .header_black {
        padding: 10px;
        justify-content: space-between;
        display: flex;
        .title_header {
            font-size: 17px;
            color: #000;
        }
        .view_btn {
            color: #fff;
            display: flex;
        
            .btn {
                min-width: 83px;
                cursor: pointer;
                margin: 0 5px;
                text-align: center;
                justify-content: center;
                align-items: center;
                background: #ffff;
                border-radius: 10px;
                padding: 2px 17px;
                color: #9e9e9e;
                display: flex;
            }
            .btn_active {
                color: #000;
                font-weight: 700;
            }
        }
    }
    .sum_content {
        border: 1px solid;
        padding: 20px;
        margin-top: 10px;
    }
   
`;
