import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import ItemTextNew from './ItemTextNew';

const StaticSurveyText = memo(({ className, item_question }) => {
    const renderContent = () => {
        let question_answers_new = _.values(item_question.answers);
        if(item_question?.statistics?.overview?.age) {
            let total_count = Object.values(item_question?.statistics?.overview?.age?.total).reduce((a, b) => a + b, 0)
            
            question_answers_new.map(answer => {
                let array_count = Object.values(item_question?.statistics?.overview?.age[answer.hash_value])
                answer.count = array_count.reduce((a, b) => a + b, 0)
                answer.percent = (answer.count / total_count) * 100
            })

            let temp_question = JSON.parse(JSON.stringify(question_answers_new))
            temp_question.sort((a, b) => { return b.count - a.count })
            let max_count = 0
            try {
                max_count = temp_question[0].count
                question_answers_new.map(answer => {
                    if (answer.count === max_count) answer.is_max = true
                    else answer.is_max = false
                })
            } catch (error) {
            }

            return <div className="summay_text">
                {
                    question_answers_new.map((item, index) => (
                        <div key={index} className="item_text">
                            <ItemTextNew key={index} index={index} is_max={item.is_max}
                                content={item?.content || ''}
                                percent={item?.percent || 0}
                                count={item?.count || 0}
                            />
                        </div>
                    ))
                }
            </div>
        } else {
            return <div className="summay_text">
                {
                    question_answers_new.map((item, index) => (
                        <div key={index} className="item_text">
                            <ItemTextNew key={index} index={index} is_max={item.is_max}
                                content={item?.content || ''}
                                percent={item?.percent || 0}
                                count={item?.count || 0}
                            />
                        </div>
                    ))
                }
                
            </div>
        }
    }
    return (
        <div className={className}>
            {renderContent()}
        </div>
    );
});

StaticSurveyText.propTypes = {
  className: PropTypes.any,
};
export default styled(StaticSurveyText)`
    .summay_text {
        padding-left: 60px;
        width: 100%;
        .item_text {
            margin: 15px 0;
        }
    }
`;
