
import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const ItemTextNew = memo(({ className, index, content, percent, count, is_max, answer_type}) => {
    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");
    return (
        <div className={className} key={index}>
           <div className="d-flex"  style={isNotEvent ? {} : {fontSize: 12}, {fontFamily: 'MalgunGothicRegular'}}>
              <div style={{minWidth: 25, fontFamily: 'MalgunGothicRegular'}}>{`${index + 1}.`}</div>

              <div style={{marginRight: isNotEvent ? 29 : 10}}>
                {
                    answer_type === 'image' ? (
                        <img className={is_max ? "border" : ""} src={content} width={isNotEvent ? 100 : 90} height={isNotEvent ? 80 : 65}/>
                    ) : (
                            <div className={is_max ? "border video_content" : "video_content"} style={isNotEvent ? {width: 130, height: 63} : {width: 85, height: 60}}>
                                <video width="100%" height="100%" controls className='video'>
                                    <source src={content} type="video/mp4" />
                                </video>
                            </div>
                        )
                }
              </div>

              <div className="wrap_process" style={{fontFamily: 'MalgunGothicRegular'}}>
                <div className="d-flex process" >
                  <div style={{backgroundColor: is_max ? 'rgb(18, 115, 226)' : '#CDCDCD', width: `${percent.toFixed(2) || 0}%`, textAlign: 'center'}}></div>
                </div>
                <div style={{width: isNotEvent ? 200 : 100, marginLeft: 25, justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex', flexDirection: 'column', marginTop: 5, }}>
                  <div style={{color: is_max ? 'rgb(18, 115, 226)' : '#000'}}>{percent.toFixed(2) || 0}%</div>
                  <div style={{color: is_max ? '#000' : '#9E9E9E' }}>{count || 0}명</div>
                </div>
              </div>

           </div>
        </div>
    );
});

ItemTextNew.propTypes = {
  className: PropTypes.any,
};
export default styled(ItemTextNew)`
    width: 100%;
    margin: 3px 0;
    .border {
      border: 1.5px solid rgb(18, 115, 226);
    }
    .wrap_process {
      justify-content: space-between;
      flex-direction: column;
      display: flex;
    }
    .process {
      margin-top: 5px;
      background-color: #F4F4F4;
      height: 10px;
      width: 100%;
    }
    img {
      object-fit: cover;
    }
    .video_content {
      overflow: hidden;
      position: relative;
      background-color: #000;
      border: 2px solid rgb(18, 115, 226);
      padding-top: 0px;
      .video {
          position: absolute;
          left: 0;
          top: 0;
          -webkit-object-fit: cover;
          object-fit: cover;
          width: 100%;
          height: 100%;
      }
  }
`;
