import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import ListNoti from './ListNoti';
import Filter from './Filter';

const NotifiPage = memo(({ className, profile, history }) => {
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [loadding, setLoadding] = useState(false)
  const [params, setParams] = useState({
    limit: 10,
    offset: 1,
    currentPage: 1,
    search: "",
  })


  const getListNotifi = useCallback(async () => {
    setLoadding(true)
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: "notifications",
      data: {
        limit: params.limit,
        offset: (params.currentPage - 1) * params.limit,
        search: params.search,
      }
    });
    if (result.hasErrors) {
      // Ui.showErrors(result.errors[0].data.details)
    } else {
      setTotal(result.value.count)
      setData(result.value.results)
    }
    setLoadding(false)
  }, [params]);

  const onUnRead = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "POST",
      url: "notifications/readall",
      data: {},
    });
    if (result.hasErrors) {
      // Ui.showErrors(result.errors[0].data.details)
    } else {
      getListNotifi()
    }
  })

  useEffect(() => {
    getListNotifi();
  }, [getListNotifi]);

  return (
    <div>
      <Filter total={total} data={data} params={params} setParams={setParams} onUnRead={onUnRead}/>
      <ListNoti data={data} loadding={loadding} params={params} setParams={setParams}/>
    </div>
  );
});

NotifiPage.propTypes = {
  className: PropTypes.any,
};
export default styled(NotifiPage)`
  
`;
