import React, { useState, useCallback, useEffect, memo } from "react";
import { Table, Checkbox, Empty } from "antd";
import styled from "styled-components";
import { DownOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import EmptyTable from '../EmptyTable';

import PopUpBase from '../../components/PopUpBase'
import ServiceBase from "../../utils/ServiceBase";
import { THIRD_PARTY_EVENT_CATEGORY_LIST, EVENT_TYPE_LIST, STATUS_EVENT } from "../../utils/constants";
import { Ui } from "../../utils/Ui";
import { formatMETA } from "../../utils/helper";
import serialize from './serialize';

const EVENT_TYPE_ALL = {
    '': '전체',
    ...EVENT_TYPE_LIST,
}

const THIRD_PARTY_EVENT_CATEGORY_ALL = {
    '': '전체',
    ...THIRD_PARTY_EVENT_CATEGORY_LIST,
}

const STATUS_EVENT_ALL = {
    '': '전체',
    ...STATUS_EVENT,
}

const ListWorkSpace = memo(
    ({ className, data, loadding, params, setParams, onRefreshList, getListEvent }) => {
    const history = useHistory();
    const [isShowModal, setShowModal] = useState(false)

    const [isShowModalStop, setShowModalStop] = useState(false)
    const [isShowModalStopOK, setShowModalStopOK] = useState(false)

    const [isShowModalClose, setShowModalClose] = useState(false)
    const [isShowModalCloseOK, setShowModalCloseOK] = useState(false)

    const [itemSelected, setItemSelected] = useState(null)
    const [isShowModalResume, setShowModalResume] = useState(false)
    const [isShowModalResumeSuccess, setShowModalResumeSuccess] = useState(false)
    const [isShowModalResumeError, setShowModalResumeError] = useState(false)

    const onFilterForColumn = ( objectNew, data, item ) => {
        if(objectNew.includes(item)) { /// Trạng thái active
            if(item === '') {
                objectNew = []
            } else {
                const index = objectNew.indexOf(item);
                objectNew.splice(index, 1);
            }
        } else { // trạng thái not_active
            if(item === '') {
                objectNew = [];
                for (var key in data) {
                    objectNew.push(key);
                }
            } else {
                objectNew.push(item)
            }
        }
        if(!objectNew.includes("") && objectNew.length ===  Object.keys(data).length - 1) {
            objectNew.push("")
        }
        if(objectNew.includes("") && item !== "" && objectNew.length <  Object.keys(data).length) {
            objectNew = objectNew.filter(x => x !== "")
        }
        return objectNew;
      
    }

    const getColumnSearchPropsEvent = useCallback(
        (dataIndex, dataName) => ({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <div style={{ padding: 8 }}>
                {
                    Object.keys(EVENT_TYPE_ALL).map((item, index) => (
                        <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} key={index} className="d-flex" 
                            onClick={async () => {
                                let event_type = params.event_type;
                                const arrayNew  = await onFilterForColumn(event_type, EVENT_TYPE_ALL, item)
                                if(arrayNew.length === 0) {
                                    confirm()
                                } 
                                setParams({...params, 
                                    event_type: arrayNew,
                                    offset: 0,
                                    currentPage: 1,
                                })

                                getListEvent({...params, 
                                    event_type: arrayNew,
                                    offset: 0,
                                    currentPage: 1,
                                })

                                history.push({
                                    pathname: '/my-workspace',
                                    search: serialize({
                                        ...params,
                                        event_type: arrayNew.includes("") ? '' : arrayNew,
                                        offset: 0,
                                        currentPage: 1,
                                    }),
                                });

                            }}
                        >
                            <Checkbox checked={params.event_type.includes(item)}/> 
                            <div style={{marginLeft: 10, color: params.event_type.includes(item) ? '#1890ff' : ''}}>
                                {EVENT_TYPE_ALL[item]}</div>
                        </div>
                    ))
                }
              </div>
            ),
            filterIcon: filtered => <DownOutlined style={{ color: params.event_type.length > 0 ? '#1890ff' : '#000' }} />,
        }),
        [params]
    );
    const getColumnSearchPropsStatus = useCallback(
        (dataIndex, dataName) => ({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <div style={{ padding: 8 }}>
                {
                    Object.keys(STATUS_EVENT_ALL).map((item, index) => (
                        <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} key={index} className="d-flex" 
                            onClick={async () => {
                                let status = params.status;
                                const arrayNew  = await onFilterForColumn(status, STATUS_EVENT_ALL, item)
                                if(arrayNew.length === 0) {
                                    confirm()
                                }
                                setParams({...params, 
                                    status: arrayNew,
                                    offset: 0,
                                    currentPage: 1,
                                })
                                getListEvent({...params, 
                                    status: arrayNew,
                                    offset: 0,
                                    currentPage: 1,
                                })
                                history.push({
                                    pathname: '/my-workspace',
                                    search: serialize({
                                        ...params,
                                        status: arrayNew.includes("") ? '' : arrayNew,
                                        offset: 0,
                                        currentPage: 1,
                                    }),
                                });
                            }}
                        >
                            <Checkbox checked={params.status.includes(item)}/> 
                            <div style={{marginLeft: 10, color: params.status.includes(item) ? '#1890ff' : ''}}>
                                {STATUS_EVENT_ALL[item]}</div>
                        </div>
                    ))
                }
              </div>
            ),
            filterIcon: filtered => <DownOutlined style={{ color: params.status.length > 0 ? '#1890ff' : '#000' }} />,
        }),
        [params]
    );

    const getColumnSearchPropsCategory = useCallback(
        (dataIndex, dataName) => ({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <div style={{ padding: 8 }}>
                {
                    Object.keys(THIRD_PARTY_EVENT_CATEGORY_ALL).map((item, index) => (
                        <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} key={index} className="d-flex" 
                            onClick={async () => {
                                let category = params.category;
                                const arrayNew  = await onFilterForColumn(category, THIRD_PARTY_EVENT_CATEGORY_ALL, item)
                                if(arrayNew.length === 0) {
                                    confirm()
                                }
                                setParams({...params, 
                                    category: arrayNew,
                                    offset: 0,
                                    currentPage: 1,
                                })
                                getListEvent({...params, 
                                    category: arrayNew,
                                    offset: 0,
                                    currentPage: 1,
                                })
                                history.push({
                                    pathname: '/my-workspace',
                                    search: serialize({
                                        ...params,
                                        category: arrayNew.includes("") ? '' : arrayNew,
                                        offset: 0,
                                        currentPage: 1,
                                    }),
                                });
                            }}
                        >
                            <Checkbox checked={params.category.includes(item)}/> 
                            <div style={{marginLeft: 10, color: params.category.includes(item) ? '#1890ff' : ''}}>
                                {THIRD_PARTY_EVENT_CATEGORY_ALL[item]}</div>
                        </div>
                    ))
                }
              </div>
            ),
            filterIcon: filtered => <DownOutlined style={{ color: params.category.length > 0 ? '#1890ff' : '#000' }} />,
        }),
        [params]
    );
    const columns = [
        {
            title: "유형",
            dataIndex: "event_type",
            key: "event_type",
            align: 'center',
            render: (value) => value && EVENT_TYPE_LIST[value],
            width: 100,
            ...getColumnSearchPropsEvent("event_type"),
        },
        {
            title: "카테고리",
            dataIndex: "category",
			align: 'center',
            render: (value) => value && THIRD_PARTY_EVENT_CATEGORY_LIST[value],
            width: 120,
            ...getColumnSearchPropsCategory("category"),
        },
        {
            title: "세부 항목",
            dataIndex: "description",
            align: 'center',
            width: 250,
        },
        {
            title: "제목",
            dataIndex: "title",
            align: 'center',
            width: 250,
        },
        {
            //cái đó stand_by nhưng is_paused bằng true nó là stopped mà
            title: "상태",
			dataIndex: "status",
            align: 'center',
            render: (value, row) => {
                if(value === 'stand_by') {
                    if(row.is_paused) {
                        return STATUS_EVENT['stopped']
                    }
                    return STATUS_EVENT['waiting_display']
                } return value && STATUS_EVENT[value]
            },
            width: 120,
            ...getColumnSearchPropsStatus("status"),
        },
        {
            title: "리워드",
            dataIndex: "total_budget",
            align: 'center',
            render: (value) => {
                return <div><span style={{fontSize: formatMETA(value).length > 8 ? 13 : 14}}>{`${parseInt(formatMETA(value)).toFixed(2)}`}</span> META</div>
            },
            width: 250,
        },
        {
            title: "진행기간",
            dataIndex: "survey_duration",
            align: 'center',
            render: (value, record) => <div>{`${record.start_date ? moment(record.start_date).format("YYYY.MM.DD") : 'N/A'} - ${record.end_date ? moment(record.end_date).format("YYYY.MM.DD") : 'N/A'}`}</div> ,
            width: 200,
        },
        {
            title: "참여자 수",
            dataIndex: "participation_count",
            align: 'center',
            width: 200,
        },
        {
            title: "주최",
            dataIndex: "entity",
            align: 'center',
            width: 200,
        },
        {
            title: "등록일",
            dataIndex: "approved_date",
            align: 'center',
            render: (value) => value ? moment(value).format("YYYY.MM.DD") : '',
            width: 200,
        },
        {
            title: "관리",
            dataIndex: "manage",
            align: 'center',
            render: (value, row) => {
                return renderManageButton(value, row)
            },
            width: 220,
            fixed: 'right'
        },
    ]

    const renderBtnStop = (record) => {
        return <><div onClick={() => {
            setItemSelected(record)
            setShowModalStop(true)
        }} className="btn-point">중단 </div> <span>|</span></>
    }

    const renderBtnResume = (record) => {
        return <><div onClick={() => {
            setItemSelected(record)
            setShowModalResume(true)
        }} className="btn-point">재진행 </div> <span>|</span></>
    }

    const renderBtnClose = (record) => {
        return <><div onClick={() => {
            setItemSelected(record)
            setShowModalClose(true)
        }} className="btn-point">종료 </div> <span>|</span></>
    }

    const renderBtnDetail = (record, isHidden) => {
        return <><div onClick={() => {
            history.push({
                pathname: '/survey-detail',
                search: `?hash_value=${record.hash_value}`,
                state: { hash_value: record.hash_value }
            });
        }} className="btn-point">상세 </div> {
            isHidden ? '' : <span>|</span>
        }</>
    }

    const renderBtnViewResult = (record) => {
        return <div 
            onClick={() => {
                history.push({
                    pathname: '/survey-result',
                    search: `?hash_value=${record.hash_value}`,
                    state: { hash_value: record.hash_value }
                });
            }} 
            className="btn-point">결과보기 </div>
    }
    
    const renderManageButton = (value, record) => {
        switch(record.status) {
            case 'waiting_display':
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {renderBtnDetail(record)}
                        {renderBtnStop(record)}
                        {renderBtnViewResult(record)}
                    </div>
                )
            case 'stand_by':
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        
                        {
                            record.is_paused ? ( // trạng thái stopped khi  is_paused == true
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {renderBtnDetail(record)}
                                    {renderBtnResume(record)}
                                    {renderBtnClose(record)}
                                    {renderBtnViewResult(record)}
                                </div>
                            ) : ( /// ngược lại thì nó là trạng thái waiting_display
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {renderBtnDetail(record)}
                                    {renderBtnStop(record)}
                                    {renderBtnViewResult(record)}
                                </div>
                            )
                        }
                    </div>
                )
            case 'on_going':
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {renderBtnDetail(record)}
                        {renderBtnStop(record)}
                        {renderBtnViewResult(record)}
                    </div>
                )
            case 'draft':
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {renderBtnDetail(record, true)}
                    </div>
                )
            case 'completed':
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {renderBtnDetail(record)}
                        {renderBtnViewResult(record)}
                    </div>
                )
            case 'stopped':
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {renderBtnDetail(record)}
                        {renderBtnResume(record)}
                        {renderBtnClose(record)}
                        {renderBtnViewResult(record)}
                    </div>
                )
            default:
        }
    }

    return (
		<div className={className}>
			<Table
                locale={{ emptyText: <EmptyTable/>}}
                loading={loadding}
				columns={columns} 
				dataSource={data}
				rowKey="hash_value"
				scroll={{ x: 'max-content' }}
				pagination={false}
			/>
            {/* STOPPPPPPPPPPPPP  ===================*/}
            <PopUpBase
                className={className}
                title={"설문 중단"}
                visible={isShowModalStop}
                closable={true}
                footer={"설문 중단하기"}
                onClickFooter={useCallback(async() => {
                    const result = await ServiceBase.requestJson({
                        method: "POST",
                        url: `events/${itemSelected.hash_value}/pause`,
                        data: {},
                      });
                      if (result.hasErrors) {
                        Ui.showErrors(result.errors[0].data.details)
                      } else {
                        onRefreshList()
                        await setShowModalStop(false)
                        setShowModalStopOK(true)
                      }
                }, [itemSelected])}
                onCancel={() => {setShowModalStop(false)}}
                width={460}
            >
                <div className="modal_stop">
                    <div className="title">{itemSelected && itemSelected.title}</div>
                    <div>설문을 정말로 중단하시겠습니까?</div>
                </div>
            </PopUpBase>
            {/* StopEDDDDDDD */}
            <PopUpBase
                className={className}
                title={"설문 중단 완료"}
                visible={isShowModalStopOK}
                closable={true}
                footer={"확인"}
                onClickFooter={() => setShowModalStopOK(false)}
                onCancel={() => {setShowModalStopOK(false)}}
                width={460}
            >
                <div className="modal_stop">
                    <div className="title">{itemSelected && itemSelected.title}</div>
                    <div>설문이 중단되었습니다.</div>
                </div>
            </PopUpBase>


            {/* CLOSEEEEEEEEEEEE  ===================*/}
            <PopUpBase
                className={className}
                title={"설문 중단"}
                visible={isShowModalClose}
                closable={true}
                footer={"설문 종료하기"}
                onClickFooter={useCallback(async() => {
                    const result = await ServiceBase.requestJson({
                        method: "POST",
                        url: `events/${itemSelected.hash_value}/close`,
                        data: {},
                      });
                      if (result.hasErrors) {
                        // Ui.showErrors(result.errors[0].data.details)
                      } else {
                        onRefreshList()
                        await setShowModalClose(false)
                        setShowModalCloseOK(true)
                      }
                }, [itemSelected])}
                onCancel={() => {setShowModalClose(false)}}
                width={460}
            >
                <div className="modal_stop">
                    <div className="title">{itemSelected && itemSelected.title}</div>
                    <div className="">설문을 정말로 종료하시겠습니까?</div>
                </div>
            </PopUpBase>
            {/* CLOSEEEDDDDDDDDDD */}
            <PopUpBase
                className={className}
                title={"설문 종료 완료"}
                visible={isShowModalCloseOK}
                closable={true}
                footer={"확인"}
                onClickFooter={() => setShowModalCloseOK(false)}
                onCancel={() => {setShowModalCloseOK(false)}}
                width={460}
            >
                <div className="modal_stop">
                <div className="title">{itemSelected && itemSelected.title}</div>
                    <div className="">설문이 종료되었습니다</div>
                </div>
            </PopUpBase>


            
             {/*RESSSSSSMUUUUUUU */}
             <PopUpBase
                className={className}
                title={"설문 재진행"}
                visible={isShowModalResume}
                closable={true}
                footer={"설문 재진행하기"}
                onClickFooter={useCallback(async() => {
                    const result = await ServiceBase.requestJson({
                        method: "POST",
                        url: `events/${itemSelected.hash_value}/resume`,
                        data: {},
                      });
                      if (result.hasErrors) {
                        await setShowModalResume(false)
                        setShowModalResumeError(true)
                        try {
                            Ui.showErrors(result.errors[0].data.details)
                        } catch (error) { }
                      } else {
                        onRefreshList()
                        await setShowModalResume(false)
                        setShowModalResumeSuccess(true)
                      }
                }, [itemSelected])}
                onCancel={() => {setShowModalResume(false)}}
            >
                <div className="modal_resume">
                    <div className="content_resume">
                        <div className="title">{itemSelected && itemSelected.title}</div>
                        {
                            itemSelected && <div className="">{`설문을 다시 진행하는 경우 진행 기간은 동일하게 유지됩니다.`}</div>
                        }
                        {
                            itemSelected && <div className="">{`진행기간: ${itemSelected.start_date ? moment(itemSelected.start_date).format("YYYY.MM.DD") : 'N/A'} - ${itemSelected.end_date ? moment(itemSelected.end_date).format("YYYY.MM.DD") : 'N/A'}`}</div>
                        }
                        <div className="">설문을 다시 진행하시겠습니까?</div>
                    </div>
                </div>
            </PopUpBase>
            <PopUpBase
                className={className}
                title={"설문 재진행 완료"}
                visible={isShowModalResumeSuccess}
                closable={true}
                footer={"확인"}
                onClickFooter={() => setShowModalResumeSuccess(false)}
                onCancel={() => {setShowModalResumeSuccess(false)}}
                width={460}
            >
                <div className="modal_stop">
                    <div className="title">{itemSelected && itemSelected.title}</div>
                    <div className="">설문이 다시 진행됩니다.</div>
                </div>
            </PopUpBase>
            <PopUpBase
                className={className}
                title={"설문 재진행 오류"}
                visible={isShowModalResumeError}
                closable={true}
                footer={"확인"}
                onClickFooter={() => setShowModalResumeError(false)}
                onCancel={() => {setShowModalResumeError(false)}}
                width={460}
            >
                <div className="modal_stop">
                    <div className="title">{itemSelected && itemSelected.title}</div>
                    <div className="">진행기간이 지난 설문은 다시 진행할 수 없습니다.</div>
                </div>
            </PopUpBase>
		</div>
    );
});

ListWorkSpace.propTypes = {
  className: PropTypes.any,
};
export default styled(ListWorkSpace)`
    .ant-table-cell {
        word-break: break-word;
    }
    .btn-point {
        cursor: pointer;
        font-weight: bold;
        padding: 0px 4px;
    }
    .btn-point:hover {
        color: #1890ff;
    }
    .modal_stop {
        margin: 40px 0;
        text-align: center;
        .title {
            // color: #000;
            font-size: 19px;
            font-weight: 600;
        }
    }
    .modal_resume {
        margin: 20px 0;
        text-align: center;
        .title {
            // color: #000;
            font-size: 17px;
            font-weight: 600;
        }
        .content_resume {
            text-align: center;
            margin: 0 30px;
        }
    }
`;
