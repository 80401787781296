import React, { useState, useCallback, useEffect, memo, } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import SurveyMultiple from './SurveyMultiple';
import SurveyYesNo from './SurveyYesNo';
import Campaign from '../SurveyDetail/Result/Tab1/Campaign';
import HeaderStatus from './HeaderStatus';
import Chart from './Chart';
import { STATUS_EVENT } from "../../utils/constants";
import * as style from '../../components/Variables';


const EventDetail = memo(({ className }) => {
	const location = useLocation();
	const hash_value = location.pathname.replace("/event/", "")

	const [event, setEvent] = useState(null)
	const [event_info, set_event_info] = useState(null)
	const [questions_info, set_questions_info] = useState(null)

	const getEventDetail = useCallback(async () => {
		const result = await ServiceBase.requestJson({
		  method: "GET",
		  url: `events/${hash_value}`,
		  data: {},
		});
		if (result.hasErrors) {
		  // Ui.showErrors(result.errors[0].data.details)
		} else {
			setEvent(result.value)
		}
	}, [hash_value]);

	

	const getEventResult = useCallback(async () => {
		const result = await ServiceBase.requestJson({
		  method: "GET",
		  url: `events/${hash_value}/statistics`,
		  data: {},
		});
		if (result.hasErrors) {
		 try {
			Ui.showError({ message: result });
		 } catch (error) {
		   
		 }
		} else {
		  set_event_info(result.value.event_info)
		  set_questions_info(result.value.questions_info)
		}
	  }, [hash_value]);

	  useEffect(() => {
		getEventDetail();
		getEventResult()
	}, [getEventDetail, getEventResult]);
	
	const renderDetailEvent = () => {
		if(event?.event_type === "survey_multiple") {
			return <SurveyMultiple event={event} event_info={event_info} questions_info={questions_info}/>
		}
		if(event?.event_type === "survey_yes_no") {
			return <SurveyYesNo event={event} event_info={event_info} questions_info={questions_info}/>
		}
		if(event?.event_type === "campaign") {
			return <Campaign event={event} isDetail/>
		}
	}
	const renderStatus = () => {
		if(event?.status === "stand_by") {
		  if(event?.is_paused) {
			return STATUS_EVENT['stopped']
		  } else {
			return STATUS_EVENT['waiting_display']
		  }
		}
		return STATUS_EVENT[event?.status]
	}

	const renderColorStatus = () => {
		if(event?.status === "stand_by") {
			if(event?.is_paused) {
			  return '#000';
			}
		}
		if(event?.status === "completed") {
			return '#000';
		}
		else {
			return `${style.color.blueDefault}`
		}
	}

	return (
		<div className={className}>
			<div style={{width: 780, paddingLeft: 75, paddingRight: 40}}>
				<div className="title">{event?.title}</div>
				<div className="d-flex j-c-b a-i-c header1" style={{marginBottom: 10, marginTop: 6}}>
					<div className="desc">{event?.description}</div>
					<div className="status" style={{color: renderColorStatus()}}>{renderStatus()} 
						<span style={{color: renderColorStatus(), fontWeight: 400, fontSize: 10}}>상태입니다.</span> 
					</div>
				</div>
				<div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6', marginBottom: 16 }}></div>
				<div>{renderDetailEvent()}</div>
				<div className="pb_before"/>
				<div className="pb_one_item">
					<HeaderStatus isDetail={true} hash_value={event?.hash_value}/>
				</div>
				<Chart hash_value={event?.hash_value}/>
			</div>
		</div>
	);
});

EventDetail.propTypes = {
  className: PropTypes.any,
};
export default (styled(EventDetail)`
	font-family: MalgunGothicRegular;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	.desc {
		font-size: 14px;
		color: #000;
		font-weight: 500;
	}
	.title {
		width: 500px;
		word-break: break-all;
		font-size: 18px;
		color: #000;
		font-weight: bold;
	}
	.header1 {
		margin-bottom: 0px 10px 10px 5px;
		.status {
			font-size: 14px;
			font-weight: bold;
			span {
				margin-left: 4px;
				font-size: 12px;
				color: #777575;
			}
		
		}
	}
	@media print {
		// /* Page Breaks */

		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
  	}
 
	@media screen {
		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
	}

	.no_padding {
		padding: 0;
	}
	.background_red {
	}
	
	
`);



// python3 html2pdf_report.py <event_hash>  