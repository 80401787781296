
import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const ItemBase = memo(({ className, item, index, content, percent, count, is_max}) => {
    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");
    return (
        <div className={className} key={index} style={{fontSize: isNotEvent ? 14 : 12, fontFamily: 'MalgunGothicRegular'}}>
           <div className="d-flex">
              <div style={{minWidth: 25, fontFamily: 'MalgunGothicRegular'}}>{`${index + 1}.`}</div>

              <div style={{flex: 1}}>
                <div style={{marginBottom: 7}}>{content}</div>
                <div className="d-flex process" >
                  <div style={{backgroundColor: is_max ? 'rgb(18, 115, 226)' : '#CDCDCD', width: `${percent.toFixed(2) || 0}%`, textAlign: 'center'}}></div>
                </div>
              </div>
              <div style={{paddingLeft: isNotEvent ? 20 : 0}}>
                <div style={{marginBottom: 1.4}}>&nbsp;</div>
                <div className="d-flex" style={{ textAlign: 'center'}}>
                  <div style={{ width: 76, color: is_max ? 'rgb(18, 115, 226)' : '#000', paddingRight: 15, textAlign: 'right'}}>{percent.toFixed(2) || 0}%</div>
                  <div style={{color: is_max ? '#000' : '#9E9E9E', minWidth: 100, textAlign: 'left' }}>{count || 0}명</div>
                </div>
              </div>

           </div>
        </div>
    );
});

ItemBase.propTypes = {
  className: PropTypes.any,
};
export default styled(ItemBase)`
    width: 100%;
    margin: 12px 0;
    .process {
      background-color: #F4F4F4;
      height: 10px;
      width: 100%;
    }
`;
