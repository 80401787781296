import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';

import ItemImageNew from './ItemImageNew';

const StaticSurveyImage = memo(({ className, index, item_question, answer_type }) => {
    const renderContent = () => {
        let question_answers_new = _.values(item_question.answers);
        if (item_question?.statistics?.overview?.age) {
            let total_count = Object.values(item_question?.statistics?.overview?.age?.total).reduce((a, b) => a + b, 0)
            question_answers_new.map(answer => {
                let array_count = Object.values(item_question?.statistics?.overview?.age[answer.hash_value])
                answer.count = array_count.reduce((a, b) => a + b, 0)
                answer.percent = (answer.count / total_count) * 100
            })
            let temp_question = JSON.parse(JSON.stringify(question_answers_new))
            temp_question.sort((a, b) => { return b.count - a.count })
            let max_count = 0
            try {
                max_count = temp_question[0].count
                question_answers_new.map(answer => {
                    if (answer.count === max_count) answer.is_max = true
                    else answer.is_max = false
                })
            } catch (error) {
            }
            return <div className="summay_text pb_one_item">
                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {
                        question_answers_new.map((item, index) => (
                            <div key={index} className="item_text">
                                <ItemImageNew key={index} index={index} is_max={item.is_max}
                                    content={item?.content || ''}
                                    answer_type={answer_type}
                                    percent={item?.percent || 0}
                                    count={item?.count || 0}
                                />
                            </div>
                        ))
                    }

                </div>
            </div>
        } else {
            return (
                <div className="summay_text pb_one_item">
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        {
                            question_answers_new.map((item, index) => (
                                <div key={index} className="item_text">
                                    <ItemImageNew key={index} index={index} is_max={item.is_max}
                                        content={item?.content || ''}
                                        answer_type={answer_type}
                                        percent={item?.percent || 0}
                                        count={item?.count || 0}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
    }
    return (
        <div className={className}>
            {renderContent()}
        </div>
    );
});

StaticSurveyImage.propTypes = {
    className: PropTypes.any,
};
export default styled(StaticSurveyImage)`
    .summay_text {
        width: 100%;
        margin-left: 40px;
        .item_text {
            width: 43%;
            margin: 8px 20px;
        }
    }
`;
