import React, { useCallback } from "react";
import { Row, Col, Button, Select, Input } from "antd";
import styled from "styled-components";
import Pagination from '../../components/Pagination'
import PropTypes from "prop-types";
const { Option } = Select;
let inputTimer = null;

const Filter = ({ className, setParams, params, total, onUnRead, data }) => {
 
  return (
    <div className={className}>
      <div onClick={onUnRead} className="b-s-btn btn-unread">
        모두 읽음 처리
      </div>
      <div className="search">
        <div>검색</div>
        <Input
          allowClear
          placeholder="제목으로 검색하세요"
          onChange={e => {
            const value = e.target.value
            if (inputTimer) {
              clearTimeout(inputTimer);
            }
            inputTimer = setTimeout(() => {
              setParams((prevState) => {
                let nextState = { ...prevState };
                nextState.search = value;
                nextState.offset = 1;
                nextState.currentPage = 1;
                return nextState;
              });
            }, 500);
          }}
          style={{ width: 400, marginLeft: 20 }}
        />
      </div>
      <div className="pagination">
        <Pagination
          onFirstPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              return nextState;
            });
          }}
          onEndPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = Math.ceil(total / params.limit);
              return nextState;
            });
          }}
          onChangeSize={(pageSize) => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              nextState.limit = pageSize;
              return nextState;
            });
          }}
          onChange={(currentPage) => {
             setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = currentPage;
              return nextState;
            });
          }}
          pageSize={params.limit}
          current={params.currentPage}
          total={data.length === 0 ? total + 1 : total}
        />
      </div>
      

    </div>
  );
};
Filter.propTypes = {
  className: PropTypes.any,
};
export default styled(Filter)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px 0px 20px 0;
  .btn-unread {
    background: #000;
    color: #fff;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0px 30px;
    cursor: pointer;
  }
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
