import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import PopUpBase from '../../components/PopUpBase'
import { formatNumber, formatMETA } from "../../utils/helper";
import { BigNumber } from "bignumber.js";

const Header = memo(({ className, onClickReceive, onClickPageSend, detail }) => {
  return (
    <div className={className}>
      <div className="title_header">리워드 </div>
      <div className="coins">
        <div className="name_coins">누적 지급 META 코인</div>
        <div className="value">{detail && (formatMETA(detail.total_reward_amount))}&nbsp;<span>META</span></div>
      </div>
      <div className="current j-c-b">
        <div className="coins">
          <div className="name_coins">보유한 META 코인</div> 
          <div className="value">{detail && (formatMETA(detail.meta_current))}&nbsp;<span>META</span></div>
        </div>
        <div className="d-flex">
          <div onClick={onClickReceive} className="receive button_click">
            받기
          </div>
          <div onClick={onClickPageSend} className="send button_click">
            보내기
          </div>
        </div>

      </div>
      
    </div>
  );
});

Header.propTypes = {
  className: PropTypes.any,
};
export default styled(Header)`
  .button_click {
    cursor: pointer;
    border-radius: 5px;
    width: 170px;
    margin-left: 20px;
    box-shadow: #ccc 0px 0px 4px 1px, rgba(9,30,66,0.25) 0px 5px 5px 0px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .receive {
    color: #000;
  }
  .send {
    color: #fff;
    background: #000;
  }
  .title_header {
    color: #000;
    font-size: 20px;
    padding-left: 4px;
    margin-bottom: 10px;
  }
  .current {
    display: flex;
    flex-direction: row;
    margin: 30px 0px;
  }
  .coins {
    width: 60%;
    border-radius: 10px;
    padding: 21px;
    box-shadow: #000 0px 0px 2px 0px, rgba(9,30,66,0.25) 0px 5px 5px 0px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    .name_coins {
      display: flex;
      align-items: center;
      color: #000;
    }
    .value {
      color: #000;
      font-size: 20px;
      span {
        color: #ccc;
        font-size: 14px;
      }
    }
  }
  
`;
