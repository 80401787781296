import React, { useState, useCallback, useEffect, memo } from "react";
import { Input, Select, Form, InputNumber } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as style from '../../components/Variables';
import NumberInput from '../../components/NumberInput';
import ServiceBase from "../../utils/ServiceBase";
import { setAuthenticated, logOut } from "../../store/actions";
import { useSelector } from 'react-redux'
import Globals from "../../utils/globals";

const { Option } = Select;

const UpdateUser = memo(({ className, itemDetail, onRefreshList, setShowModalUpdate, setShowModalUpdateSuccess, setAuthenticated }) => {
  const userProfile = useSelector(state => state.App.toJS().profile.user);
  const [resetDone, setResetDone] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const params = {
      ...values,
      email: `${values.email}@${values.email_sup}`,
      is_active: values.acitve === 1 ? true : false,
    }
   
    const result = await ServiceBase.requestJson({
      method: "PUT",
      url: `accounts/${itemDetail.id}`,
      data: params,
    });
    if (result.hasErrors) {
      await setShowModalUpdate(false)
    } else {
      const profile = {
        user: result.value,
      }
      if(userProfile.id === result.value.id && !result.value.is_active) {
        await setShowModalUpdate(false)
        Globals.clear();
        setAuthenticated({
          isAuthenticated: false,
          profile,
        });
      } else {
        if(userProfile.id === result.value.id) {
          setAuthenticated({
            isAuthenticated: true,
            profile,
          });
        }
        await setShowModalUpdate(false)
        setShowModalUpdateSuccess(true)
        onRefreshList()
      }
      
    }
  }

  const onResetPassword = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "POST",
      url: `accounts/${itemDetail.id}/resetpass`,
      data: {},
    });
    if (result.hasErrors) {
     
    } else {
      setResetDone(true)
    }
  })

  const onFinishFailed = () => { }
 
  return (
    <div className={className}>
      {
        itemDetail && (
          <Form
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
      
            initialValues={{ 
              username: itemDetail && itemDetail.username,
              email_sup: itemDetail ? itemDetail.email.substring(itemDetail.email.lastIndexOf("@") +1) : '',
              email: itemDetail ? itemDetail.email.substring(0, itemDetail.email.lastIndexOf("@")) : '',
              phone_number: itemDetail && itemDetail.phone_number,
              acitve: itemDetail && itemDetail.is_active ? 1 : 0
            }}
          >
            <div className="d-flex content">
                <div className="left">
                    <div className="title_left">이름</div>
                    <div className="title_left">이메일</div>
                    <div className="title_left">전화번호</div>
                    <div className="title_left">상태</div>
                    <div className="title_left">비밀번호</div>
                </div>
                <div className="right">
                  <Form.Item name="username">
                    <Input className="input_field" placeholder="이름을 입력하세요."/>
                  </Form.Item>
                  <div className="d-flex input_field input_content">
                    <Form.Item name="email">
                      <Input disabled className="mail-children-1" placeholder=""/>
                    </Form.Item>
                    <span>@</span>
                    <Form.Item name="email_sup">
                      <Input disabled className="select-mail" placeholder="메일 선택"/>
                    </Form.Item>
                    </div>
                  <Form.Item 
                    name="phone_number">
                    <NumberInput className="input_field" placeholder="전화번호를 입력하세요."/>
                  </Form.Item>
                  <Form.Item name="acitve">
                    <Select className="select-mail" placeholder={"메일 선택"} onChange={() => {}}>
                      <Option value={1}>활성화</Option>
                      <Option value={0}>비활성화</Option>
                    </Select>
                  </Form.Item>
                  <div onClick={onResetPassword} className="reset_password">비밀번호 초기화</div>
                </div>
            </div>
            {
              resetDone && (
                <div className="note">
                  <p> 초기 비밀번호는 이메일1@ 입니다.</p>
                    <div>
                      {`예) ${itemDetail.email} 인 경우`}
                    </div>
                    <div>{`비밀번호는 ${itemDetail.email}1@ 입니다`}</div>
                </div>
              )
            }
            <div>
              <button htmlType="submit" className="footer">
                수정하기
              </button>
            </div>
          </Form>
        )
      }
      
    </div>
  );
});


UpdateUser.propTypes = {
  className: PropTypes.any,
};


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuthenticated,
      logOut,
    },
    dispatch
  );
const withConnect = connect(
	null,
	mapDispatchToProps
);
UpdateUser.propTypes = {
  className: PropTypes.any,
};
export default compose(
	withConnect,
	memo
)(styled(UpdateUser)`
	.modal_success {
    text-align: center;
    padding: 45px 35px;
    font-size: 20px;
  }
  .note {
    margin: 10px 20px;
    margin-top: 30px;
    padding: 10px;
    background: ${style.color.backgroundGray};
  }
  .footer {
    justify-content: center;
    background: #000;
		display: flex;
		padding: 0;
    cursor: pointer;
    color: #fff;
    padding: 20px;
    width: 100%;
    text-align: center;
  }
 .content {
    padding: 10px 20px;
    .left {
      min-width: 130px;
      margin-right: 20px;
      background: ${style.color.backgroundGray};
      text-align: center;
		.title_left {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 35px;
			margin-bottom: 15px;
		}
	}
	.right {
		display: flex;
    flex-direction: column;
    .reset_password {
      padding-top: 10px;
      cursor: pointer;
      font-weight: bold;
      padding-bottom: 3px;
      text-decoration: underline;
    }
		.right_bottom {
			height: 35px;
			align-items: center;
			display: flex;
			color: #000;
    }
    .input_field {
      width: 300px;
      height: 35px;
      
    }
    .input_content {
      margin-bottom: 15px;
      span {
        width: 30px;
        text-align: center;
      }
      .mail-children-1 {
        width: 140px;
      }
      .select-mail {
        width: 130px;
        height: 35px;
      }
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
  `);

