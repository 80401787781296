import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import { useLocation } from "react-router-dom";
import ItemBaseNew from '../ItemBaseNew';

const item_name_gender = {
    'male': '남성',
    'female': '여성',
    'other': '알수없음',
};

const item_last = {
    'THEPOL': '더폴',
    'FNSURVEY': '바로참여'
}

const array_age = ['teenager', '20s', '30s', '40s', '50s', 'over_60s', 'other'];
const age_title = {
    'teenager': '10대',
    '20s': '20대',
    '30s': '30대',
    '40s': '40대',
    '50s': '50대',
    'over_60s': '60대 이상',
    'other': '알수없음'
}


const Detail = memo(({ className, question_info, event_info }) => {
    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");
    return (
        <div className={className}>
            {/* GENDERE */}
            <div className="pb_one_item ">
                {
                    isNotEvent ? null : (
                        <div>
                            <div className="title" style={{fontSize: 14}}>답변 통계 (자세히)</div>
                            <div className="header_black" style={{fontSize: isNotEvent ? 14 : 12, fontWeight: 'bold', fontFamily: 'NotoSansCJKkr'}}>1.&nbsp;&nbsp;&nbsp;{`${question_info.content}`}</div>
                        </div>
                    )
                }
                <div className="item">
                    <div className="title_s" style={isNotEvent ? {marginTop: 20} : {textAlign: 'center', fontSize: 14, marginTop: 20}}>성별</div>
                    {
                        Object.keys(question_info?.statistics?.gender).sort().map((item, index) => {
                            if(item === 'total') return;
                            let question_answers_new_gender = _.values(question_info.answers);
                            if(!question_info?.statistics?.gender?.[item]) {
                                return <div key={index} className="item_content" style={{marginLeft: isNotEvent ? 20 : 0}}>
                                    <div className="title_gender" style={{fontSize: isNotEvent ? 14 : 12}}>{item_name_gender[item]}</div>
                                    <div style={{marginLeft: 40}}>
                                        {
                                            question_answers_new_gender.map((item, index) => (
                                                <ItemBaseNew key={index} index={index}
                                                    content={item?.content}
                                                    percent={0}
                                                    count={0}
                                                    is_max={item?.is_max}
                                                />
                                            ))
                                        }
                                    </div>  
                                </div>
                            }
                            let total_count_gender = Object.values(question_info?.statistics?.gender?.[item]?.total).reduce((a, b) => a + b, 0)
                            question_answers_new_gender.map(answer => {
                                let array_count = Object.values(question_info?.statistics?.gender?.[item][answer.hash_value])
                                answer.count = array_count.reduce((a, b) => a + b, 0)
                                answer.percent = (answer.count / total_count_gender) * 100
                            })
                       
                            return <div key={index} className="item_content" style={{marginLeft: isNotEvent ? 20 : 0}}>
                                    <div className="title_gender" style={{fontSize: isNotEvent ? 14 : 12}}>{item_name_gender[item]}</div>
                                    <div style={{marginLeft: 40}}>
                                        {
                                            question_answers_new_gender.map((item, index) => (
                                                <ItemBaseNew key={index} index={index}
                                                    content={item?.content || ''}
                                                    percent={item?.percent || 0}
                                                    count={item?.count || 0}
                                                    is_max={item?.is_max}
                                                />
                                            ))
                                        }
                                    </div>  
                                </div>
                        })
                    }
                </div>
            </div>
            {/* AGEEEEEEEEEEE */}
            <div className="pb_one_item item">
                <div className="title_s" style={isNotEvent ? {marginTop: 20} : {textAlign: 'center', fontSize: 14, marginTop: 26}}>연령</div>
                {
                    array_age.map((item, index) => {
                        let question_answers_new_age = _.values(question_info.answers);
                        if(!question_info?.statistics?.age?.[item]) {
                            return  <div key={index} className="item_content" style={{marginLeft: isNotEvent ? 20 : 0}}>
                                <div className="title_gender" style={{fontSize: isNotEvent ? 14 : 12}}>{age_title[item]}</div>
                                <div style={{marginLeft: 40}}>
                                    {
                                        question_answers_new_age.map((item, index) => (
                                            <ItemBaseNew key={index} index={index}
                                                content={item?.content || ''}
                                                percent={0}
                                                count={0}
                                                is_max={false}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        let total_count_age = Object.values(question_info?.statistics?.age?.[item]?.total).reduce((a, b) => a + b, 0)
                        question_answers_new_age.map(answer => {
                            let array_count = Object.values(question_info?.statistics?.age?.[item][answer.hash_value])
                            answer.count = array_count.reduce((a, b) => a + b, 0)
                            answer.percent = (answer.count / total_count_age) * 100
                        })
                        let temp_question = JSON.parse(JSON.stringify(question_answers_new_age))
                        temp_question.sort((a, b) => { return b.count - a.count })
                        let max_count = 0
                        try {
                            max_count = temp_question[0].count
                            question_answers_new_age.map(answer => {
                                if (answer.count === max_count) answer.is_max = true
                                else answer.is_max = false
                            })
                        } catch (error) {
                        }
                        console.log("question_answers_new_age", question_answers_new_age)
                        return <div key={index} className="item_content" style={{marginLeft: isNotEvent ? 20 : 0}}>
                                <div className="title_gender" style={{fontSize: isNotEvent ? 14 : 12}}>{age_title[item]}</div>
                                <div style={{marginLeft: 40}}>
                                    {
                                        question_answers_new_age.map((item, index) => (
                                            <ItemBaseNew key={index} index={index}
                                                content={item?.content || ''}
                                                percent={item?.percent || 0}
                                                count={item?.count || 0}
                                                is_max={item?.is_max}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                    })
                }
            </div>
            {/* CAi cuoi */}
            <div className="pb_one_item item">
                <div className="title_s" style={isNotEvent ? {marginTop: 20} : {textAlign: 'center', fontSize: 14, marginTop: 26}}>참여방법</div>
                {
                    question_info?.statistics?.overview?.age?.total ? Object.keys(question_info?.statistics?.overview?.age?.total).map((item, index) => {
                        let question_answers_last = _.values(question_info.answers);
                        question_answers_last.map(answer => {
                            answer.count = _.get(question_info, 'statistics.overview.age')[answer.hash_value][item] 
                            answer.percent = (answer.count / _.get(question_info, 'statistics.overview.age.total')[item]) * 100
                        })

                        let temp_question = JSON.parse(JSON.stringify(question_answers_last))
                        temp_question.sort((a, b) => { return b.count - a.count })
                        let max_count = 0
                        try {
                            max_count = temp_question[0].count
                            question_answers_last.map(answer => {
                                if (answer.count === max_count) answer.is_max = true
                                else answer.is_max = false
                            })
                        } catch (error) {
                        }

                        return <div key={index} className="item_content" style={{marginLeft: isNotEvent ? 20 : 0}}>
                            <div className="title_gender" style={{fontSize: isNotEvent ? 14 : 12}}>
                                {item_last[item]}
                            </div>
                            <div style={{marginLeft: 40}}>
                                {
                                    question_answers_last.map((item, index) => (
                                        <ItemBaseNew key={index} index={index}
                                            content={item?.content || ''}
                                            percent={item?.percent || 0}
                                            count={item?.count || 0}
                                            is_max={item?.is_max}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    }) : (
                        Object.keys(item_last).map((item, index) => {
                            let question_answers_last = _.values(question_info.answers);
                            
                            return (
                                <div key={index} className="item_content" style={{marginLeft: isNotEvent ? 20 : 0}}>
                                    <div className="title_gender" style={{fontSize: isNotEvent ? 14 : 12}}>
                                        {item_last[item]}
                                    </div>
                                    <div style={{marginLeft: 40}}>
                                        {
                                            question_answers_last.map((item, index) => (
                                                <ItemBaseNew key={index} index={index}
                                                    content={item?.content || ''}
                                                    percent={item?.percent || 0}
                                                    count={item?.count || 0}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>
            
        </div>
    );
});

Detail.propTypes = {
  className: PropTypes.any,
};
export default styled(Detail)`
    .item {
        margin-left: 20px;
        // padding: 20px;
    }
    .title_s {
        font-weight: 700;
    }
    .item_content {
        // margin: 20px 25px 0 25px;
        .title_gender {
            padding: 10px 0px 5px 0px;
            font-size: 16px;
            font-weight: bold;
       
        }
    }
`;
