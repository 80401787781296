import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { formatMETA } from "../../../utils/helper";


const Header = memo(({ className, detail, isDetail }) => {

  return (
    <div className={className}>
      <div className="list_status">
        <div className="item item_border">
          <div className="item_name" style={{fontSize: isDetail ? 12 : 16}}>{'예산'}</div>
          <div className="item_value"  style={{fontSize: isDetail ? 22 : 26}}>{formatMETA(detail.total_budget)}<span style={isDetail ? {fontSize: 12} : {}}>META</span></div>
        </div>
        <div className="item item_border">
          <div className="item_name" style={{fontSize: isDetail ? 12 : 16}}>{'지급 완료'}</div>
          <div className="item_value"  style={{fontSize: isDetail ? 22 : 26}}>{formatMETA(detail.reward_distributed)}<span style={isDetail ? {fontSize: 12} : {}}>META</span></div>
        </div>
        <div className="item">
          <div className="item_name" style={{fontSize: isDetail ? 12 : 16}}>{'잔여 수량'}</div>
          <div className="item_value"  style={{fontSize: isDetail ? 22 : 26}}>{formatMETA(detail.reward_remaining)}<span style={isDetail ? {fontSize: 12} : {}}>META</span></div>
        </div>
      </div>
    </div>
  );
});

Header.propTypes = {
  className: PropTypes.any,
};
export default styled(Header)`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  .list_status {
    box-shadow: #000 0px 0px 2px 0px, rgba(9,30,66,0.25) 0px 5px 5px 0px;
    display: flex;
    justify-content: space-around;
    border-radius: 12px;
    padding: 16px 20px;
    width: 100%;
    .item_border {
      border-right: 1px solid #f0f0f0;
    }
    .item {
      width: 33.33333%;
      
      text-align: center;
      color: #000;
      .item_name {
        font-size: 16px;
        color: #696969;
      }
      .item_value {
        font-weight: 600;
        font-size: 26px;
        color: #000;
        span {
          font-size: 14px;
          color: #000;
        }
      }
    }
  }
  
`;
