import React, { useState, useCallback, useEffect, memo } from "react";
import { Tabs, Spin } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../../utils/ServiceBase";
import { Ui } from "../../../utils/Ui";
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import {useLocation } from "react-router-dom";
import { STATUS_EVENT, REACT_APP_WEB } from "../../../utils/constants";

import { saveAs } from 'file-saver';

const ref = React.createRef();


const { TabPane } = Tabs;

const Result = memo(({ className, profile, hash_value }) => {
  const location = useLocation();

  const [event_info, set_event_info] = useState(null)
  const [loaddingPdf, setLoaddingPdf] = useState(false)
  const [detail_event, set_detail_event] = useState(null)
  const [questions_info, set_questions_info] = useState(null)

  const getDetailEvent = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `events/${hash_value}`,
      data: {},
    });
    if (result.hasErrors) {
    } else {
        set_detail_event(result.value)          
    }
  }, [hash_value]);

  useEffect(() => {
      getDetailEvent();
  }, []);

  const getEventResult = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `events/${hash_value}/statistics`,
      data: {},
    });
    if (result.hasErrors) {
     try {
        Ui.showError({ message: result });
     } catch (error) {
       
     }
    } else {
      set_event_info(result.value.event_info)
      set_questions_info(result.value.questions_info)
    }
  }, [hash_value]);

  const onExportExcel = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "EXPORT",
      url: `events/${hash_value}/excel-reports`,

      data: {},
    });
    if (result.hasErrors) {
     try {
        Ui.showError({ message: result });
     } catch (error) {
       
     }
    } else {
      var blob = new Blob([result.value], {type: "application/vnd.ms-excel;charset=utf-8"});
      saveAs(blob, `${event_info.title}.xlsx`);
    }
  }, [hash_value, event_info]);

  const onExportPDF = useCallback(async () => {
    setLoaddingPdf(true)
    const result = await ServiceBase.requestJson({
      method: "EXPORT",
      url: `events/${hash_value}/pdf-reports`,
      data: {},
    });
    if (result.hasErrors) {
     try {
        Ui.showError({ message: result });
     } catch (error) {
       
     }
    } else {
      var blob = new Blob([result.value], {type: "application/pdf;charset=utf-8"});
      saveAs(blob, `${event_info.title}.pdf`);
    }
    setLoaddingPdf(false)
  }, [hash_value, event_info]);

  const renderStatus = () => {
    if(detail_event?.status === "stand_by") {
      if(detail_event?.is_paused) {
        return STATUS_EVENT['stopped']
      } else {
        return STATUS_EVENT['waiting_display']
      }
    }
    return STATUS_EVENT[detail_event?.status]
  }

  useEffect(() => {
    getEventResult();
  }, [getEventResult]);

  if(event_info) {
    return (
      <div className={className}>
        <div className="header">
          <div className="d-flex j-c-b a-i-c header1">
            <div className="title">{event_info.title}</div>
            <div className="status">{renderStatus()} 
              <span>상태입니다.</span> 
              <span 
                 style={(event_info?.status === 'draft') ? null : {cursor: 'pointer'}}
                onClick={() => {
                  if(event_info?.status === 'draft') return;
                  window.open(`${REACT_APP_WEB}event/${event_info.hash_value}`, "_blank") //to open new page
              }} className="link_open"><i className="fa fa-external-link" aria-hidden="true"></i></span>
            </div>
            
          </div>
          {
            (event_info.status !== 'waiting_display' && event_info.status !== 'draft') ? (
              <div className="d-flex a-i-c j-c-b header2">
                <div className="title_btn"> </div>
                <div className="d-flex j-c-b">
                  <div onClick={onExportExcel} className="b-s-btn btn">데이터 내보내기 (excel)</div>
                  <Spin spinning={loaddingPdf}>
                    <div onClick={onExportPDF} className="b-s-btn btn black">리포트 생성 (pdf)</div>
                  </Spin>
                </div>
              </div>
            ) : <div>&nbsp;</div>
          }
        </div>
        {
          (event_info.status === 'waiting_display') || (event_info.status === 'draft') 
            || (detail_event?.status === 'stand_by' && detail_event?.is_pause === false) ? (
              <div className="waiting_display">
                <div>승인 전 상태의 설문은 결과를 확인할 수 없습니다.  </div>
                <div>설문이 진행된 후 다시 확인해주세요. </div>
              </div>
          ) : (
            <Tabs tabBarStyle={{color: '#ccc'}} defaultActiveKey="1">
            <TabPane tab="답변 통계" key="1">
              <Tab1 event_info={event_info} questions_info={questions_info} 
                detail_event={detail_event} // Sử dụng cho event_type là campain
              />
            </TabPane>
            <TabPane tab="참여자 분포" key="2">
              <Tab2 event_info={event_info} />
            </TabPane>
          </Tabs>
          )
        }
        </div>
    );
  }
});

Result.propTypes = {
  className: PropTypes.any,
};
export default styled(Result)`
  .header {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    .header1 {
      margin-bottom: 5px;
      .title {
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }
      
      .status {
        font-size: 17px;
        color: #000;
        span {
          margin-left: 4px;
          font-size: 12px;
          color: #777575;
        }
        .link_open {
          color: #000;
          font-size: 20px;
        }
      }
    }
    .header2 {
      .title_btn {
        color: #ccc;
      }
      .btn {
        cursor: pointer;
        margin-left: 20px;
        padding: 7px 50px;
        border-radius: 4px;
      }
      .black {
        background: #000;
        color: #fff;
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: #000;
  }
  .ant-tabs-tab:hover {
    color: #000;
  }
  .ant-tabs-nav {
    margin-bottom: 8px !important;
  }
  .waiting_display {
    text-align: center;
    margin-top: 200px;
    font-size: 18px;
  }
`;
