import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import FormUser from './FormUser';
import PopUpBase from '../../components/PopUpBase'

const CreateUser = memo(({ className, onRefreshList, setShowModalAdd, setShowModalError, setShowModalSuccess }) => {

  const onCreate = useCallback(async (values) => {
    await setShowModalAdd(false)
    const params = {
      ...values,
      email: `${values.email}@${values.email_sup}`,
      is_active: true,
    }
    const result = await ServiceBase.requestJson({
      method: "POST",
      url: "accounts",
      data: params,
    });
    if (result.hasErrors) {
      if(result.errors && result.errors[0] && result.errors[0].status === 400) {
        setShowModalError(true)
      }
    } else {
      setShowModalSuccess(true)
      onRefreshList()
    }
   
  })
 
  return (
    <div className={className}>
      <FormUser onSave={onCreate}/>
      
    </div>
  );
});

CreateUser.propTypes = {
  className: PropTypes.any,
};
export default styled(CreateUser)`
  
`;
