import React, { useState } from "react"
import constants from '../constants';
import moment from 'moment'
import {formatMETA} from '../../../utils/helper';

export default function Stop({ event, isDetail }) {
    return (
        <div className='d-flex flex-column' style={{ height: '100vh' }}>
            <div className='flex-fill d-flex flex-column'>
                <div style={{ height: '100%' }} className='d-block d-sm-none'>
                    <div className='d-flex flex-column' style={{ backgroundColor: '#F8F8F8', height: '100%', paddingTop: 10 }}>
                        {
                            isDetail ? null : (
                                <div style={{ backgroundColor: '#ed2e2e' }}>
                                    <div style={{ fontSize: 15, color: 'white', padding: '11px 20px', textAlign: 'center' }}>
                                        <b>{constants.EVENT_STOP[event.event_type]}</b>
                                    </div>
                                </div>
                            )
                        }
                        <div style={{ padding: 20, backgroundColor: 'white', fontSize: 15 }}>
                            <div style={{ fontSize: 14 }}><b>{constants.EVENT_REWARD_TITLE[event.event_type]}</b></div>
                            {
                                event.rewards?.reward_amount ?
                                    <div className='d-flex flex-row' style={{ margin: '16px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                        <div style={{ width: 90, fontWeight: 'bold' }}>리워드</div>
                                        <div style={{ color: '#686868', fontWeight: 'bold' }}>{`참여 시 ${formatMETA(event.rewards?.reward_amount)} META (THEPOL 참여)`}</div>
                                    </div> : null
                            }
                            {
                                event.start_date ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>진행기간</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>
                                        {moment(event.start_date).format('YYYY.MM.DD')} - {event.end_date ? moment(event.end_date).format('YYYY.MM.DD') : ''}
                                    </div>
                                </div> : null
                            }
                            {
                                event.category ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>카테고리</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{constants.EVENT_CATEGORY_LIST[event.category]}</div>
                                </div> : null
                            }
                            {
                                event.survey_duration ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>예상시간</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{event.survey_duration}분</div>
                                </div> : null
                            }
                            {
                                event.entity ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>주최</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{event.entity}</div>
                                </div> : null
                            }
                        </div>
                        <div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6' }}></div>
                    </div>
                </div>
            </div>

        </div>
    );
}