/**
 * Global Variables (Objects)
 */

// Breakpoints
export const breakpoint = {
    xsmall: "30.00em", // 30.00 / 480px
    small: "46.25em", //  46.25 / 700px
    med: "53.75em", //  52.50 / 860px
    large: "64.00em", // 64.00 / 1024px
    xl: "80.00em", // 80.00 / 1280px
  };
  
  // Border Radius
  export const radius = "5px";
  
  // Colors
  export const color = {
    blueDefault: '#3773E2',
    backgroundGray: '#f0f0f0',
    cornflowerblue: "cornflowerblue",
    danger: "#ff4d4f",
    black: "#1D1D1D",
    charcoal: "#3C3C3C",
    white: "#FFFFFF",
    gray: {
      default: "#B3B3B3",
      dark: "#909090",
      light: "#E3E3E3",
      hover: "#F3F3F3",
    },
    primary: {
      // blue
      default: "#004997",
      dark: "#002f61",
      light: "#91ADCD",
      hover: "#002f61",
    },
    secondary: {
      // green
      default: "#91B57F",
      dark: "#799b68",
      light: "#abd198",
      hover: "#799b68",
    },
    footer: {
      // footer
      default: "#cacaca",
      bg: "#1b1b1b",
      bold: "#FFC20E",
      label: "#fff;",
    },
    topMenu: {
      // topMenu
      default: "#cacaca",
    },
    haiVan: {
      default: "#333",
      primary: "#58595b",
      bg: "#ffc20e",
      border: "#CC9800",
      input: {
        border: "#dcdcdc",
      },
      select: {
        border: "#dcdcdc",
      },
      scrollBar: {
        bg: "#f5f5f5",
        thumb: {
          bg: "#888",
        },
      },
    },
  };
  
  // Fonts
  export const fontfamily = {
    roboto: "Roboto",
    sans: "'Open Sans', sans-serif",
    serif: "'Cormorant Garamond', serif",
  };
  
  // Font Sizes
  export const fontsize = {
    tiny: "10px", // 0.75em / 10px
    small: "12px", // 0.75em / 12px
    reg: "14px", // 0.875em / 14px
    large: "16px", // 1em / 16px
    plus: "18px", // 1.125em / 18px
  };
  
  // Font Sizes
  export const fontweight = {
    sansbold: "600",
    sans: "400",
    serifbold: "700",
    serif: "400",
  };
  
  // Margin
  export const margin = "2%";
  