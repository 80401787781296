import React, { useState, useEffect, useCallback } from "react"
import {formatMETA} from '../../../utils/helper';
import constants from '../constants';
import moment from 'moment'
import ServiceBase from "../../../utils/ServiceBase";
import { Ui } from "../../../utils/Ui";

export default function Open({ event, isDetail, hash_value }) {

    const [event_info, set_event_info] = useState(null)
    
    const renderEventStatus = () => {
        switch (event.status) {
            case 'draft':
            case 'waiting_display':
            case 'stand_by': {
                if (event.is_paused) return constants.EVENT_STOP[event.event_type]
                else return constants.EVENT_STANDBY[event.event_type]
            }
            case 'on_going': return constants.EVENT_OPEN[event.event_type]
            case 'completed': return constants.EVENT_CLOSE[event.event_type]
            default:
                break;
        }
    }

    const renderPartici = () => {
        if(event.status === 'completed' || (event.status === 'stand_by' && event.is_paused === true)) {
            return (
                <div style={{marginTop: 40}}>
                    <b style={{fontSize: 14}}>결과</b>
                    <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#000', marginTop: 16, justifyContent: 'center', alignItems: 'center',  }}>
                        <div className='d-flex justify-content-center align-items-center'
                            style={{ fontWeight: 'bold', borderRadius: 4, textAlign: 'center', color: '#fff', padding: 13, alignItems: 'center',  width: '93%', marginRight: 30, marginLeft: 30, fontSize: isDetail ? 12 : 14 }}>
                            총 <b style={{ fontSize: 14, padding: '0 4px 0 4px' }}>{event_info?.participation_count}</b> 명이 참여하였습니다.
                        </div>
                        <div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6' }}></div>
                    </div>
                </div>
            )
        } else return null
        
    }

    const getEventResult = useCallback(async () => {
        const result = await ServiceBase.requestJson({
          method: "GET",
          url: `events/${event.hash_value}/statistics`,
          data: {},
        });
        if (result.hasErrors) {
         try {
            Ui.showError({ message: result });
         } catch (error) {
           
         }
        } else {
          set_event_info(result.value.event_info)
        }
      }, [event]);
    useEffect(() => {
        getEventResult();
    }, [getEventResult]);
    return (
        <div className='d-flex flex-column' style={{ minHeight: isDetail ? 300 : '100vh' }}>
            <div className='flex-fill d-flex flex-column'>
                <div className='d-block'>
                    <div className='d-flex flex-column' style={{ margin: '10px 0' }}>
                        {
                            isDetail ? null : (
                                <div style={{ backgroundColor: '#111111' }}>
                                    <div style={{ fontSize: 15, color: 'white', padding: '11px 20px', textAlign: 'center' }}>
                                        <b>{renderEventStatus()}</b>
                                    </div>
                                </div>
                            )
                        }
                        <div style={{ fontSize: 14, marginBottom: 18, marginTop: 15 }}><b>{constants.EVENT_REWARD_TITLE[event.event_type]}</b></div>
                        <div style={{ padding: 20, backgroundColor: '#fbfbfb', fontSize: 15 }}>
                            {
                                event.rewards?.reward_amount ?
                                    <div className='d-flex flex-row' style={{ margin: '16px 10px 0 0', fontSize: isDetail ? 12 : 14 }}>
                                        <div style={{ width: 90, fontWeight: 'bold' }}>리워드</div>
                                        <div style={{ color: '#686868', fontWeight: 'bold' }}>{`참여 시 ${formatMETA(event.rewards?.reward_amount)} META (THEPOL 참여)`}</div>
                                    </div> : null
                            }
                            {
                                event.start_date ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14 }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>진행기간</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>
                                        {moment(event.start_date).format('YYYY.MM.DD')} - {event.end_date ? moment(event.end_date).format('YYYY.MM.DD') : ''}
                                    </div>
                                </div> : null
                            }
                            {
                                event.category ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14 }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>카테고리</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{constants.EVENT_CATEGORY_LIST[event.category]}</div>
                                </div> : null
                            }
                            {
                                event.survey_duration ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14 }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>예상시간</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{event.survey_duration}분</div>
                                </div> : null
                            }
                            {
                                event.entity ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14 }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>주최</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{event.entity}</div>
                                </div> : null
                            }
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <div style={{ fontSize: 14, marginBottom: 15, marginTop: 18 }}><b>상세 내용</b></div>
                            <div style={{ padding: 20, backgroundColor: '#fbfbfb', fontSize: 15 }}>
                                <div style={{ marginTop: 6, fontSize: 14 }}  dangerouslySetInnerHTML={{ __html: event.content }}></div>
                            </div>
                        </div>
                    </div>
                    {
                        // láy từ event detail
                        isDetail ? null : (
                            <div className='d-flex flex-column align-items-center' style={{ backgroundColor: 'white', marginTop: 10, justifyContent: 'center', alignItems: 'center',  }}>
                                <div className='d-flex justify-content-center align-items-center'
                                    style={{ fontWeight: 'bold', borderRadius: 4, border: 'solid 1px #1273e2', textAlign: 'center', color: '#1273e2', padding: 15, alignItems: 'center',  width: '93%', marginRight: 30, marginLeft: 30 }}>
                                    총 <b style={{ fontSize: 14, padding: '0 4px 0 4px' }}>{event_info?.participation_count}</b> 명이 참여하였습니다.
                                </div>
                                <div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6' }}></div>
                            </div>
                        )
                    }

                    {/* theo báo mới mới  */}
                    {renderPartici()}
                    
                </div>
            </div>
        </div>
    );
}