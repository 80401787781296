import React, { memo, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import _ from 'lodash';

const ItemText = memo(({ className, name_answer, value, percent, is_max, isStatic}) => {
    const location = useLocation();
    return (
        <div className={className} style={{width: location.search.includes("?hash_value") ? "80%" : "100%"}}>
            <div style={{ breakBefore: 'auto' }} />
            <div className="content">
                <div className="tile_name">
                    {name_answer}
                </div>
                <div className="process" style={{marginRight: isStatic ? 25 : 0 }}>
                    <div className="process_children" style={{width: `${percent}%`, background: is_max ? '#1890ff' : 'lightgray'}}/>
                </div>
                <div className="title_bottom" style={{textAlign: isStatic ? 'left' : 'center'}}>
                    {`${value}명 (${percent && percent.toFixed(2)}%)`}
                </div>
            </div>
            <div style={{ breakAfter: 'auto' }} />
        </div>
    );
});

ItemText.propTypes = {
  className: PropTypes.any,
};
export default styled(ItemText)`
    align-items: center;
    .content {
        height: 30px;
        align-items: center;
        display: flex; 
    }
    .tile_name {
        min-width: 150px;
    }
    .title_bottom {
        text-align: center;
        min-width: 150px;
    }

    .process {
        height: 30px;
        width: 100%;
        border: 1px solid #000;
        position: relative;
        .process_children {
            position: absolute;
            background: #1890ff;
            height: 100%;
        }
    }

    @media print {
		// /* Page Breaks */

		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
  	}
 
	@media screen {
		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
	}
`;
