import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import Header from './Header';
import {useHistory } from "react-router-dom";
import ListRewards from './ListRewards';
import Filter from './Filter';
import PopUpBase from '../../components/PopUpBase'

const Rewards = memo(({ className }) => {

  const history = useHistory();
  const [data, setData] = useState([])
  const [detail, setDetail] = useState(null)
  const [total, setTotal] = useState(0)
  const [loadding, setLoadding] = useState(false)
  const [isShowReceive, setShowReceive] = useState(false)
  const [params, setParams] = useState({
    limit: 10,
    offset: 1,
    currentPage: 1,
    transaction_type: [],
    search: "",
    date: '',
    date_gte: undefined,
    date_lte: undefined
  })

  const getListTransactions = useCallback(async () => {
    setLoadding(true)
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: "transactions",
      data: {
        limit: params.limit,
        offset: (params.currentPage - 1) * params.limit,
        transaction_type: params.transaction_type.includes("") ? "" : params.transaction_type.toString(),
        search: params.search,
        date: params.date,
        date_gte: params.date_gte,
        date_lte: params.date_lte,
      },
    });
    if (result.hasErrors) {
     try {
      Ui.showErrors(result.errors[0].data.details)
     } catch (error) {}
    } else {
      setTotal(result.value.count)
      setData(result.value.results)
      setDetail(result.value)
    }
    setLoadding(false)
  }, [params]);

  useEffect(() => {
    getListTransactions();
  }, [getListTransactions]);


  return (
    <div className={className}>
      <Header 
        detail={detail}
        onClickReceive={() => {setShowReceive(true)}} 
        onClickPageSend={() => {history.push('/rewards-send')}}
      />
      <Filter data={data} total={total} params={params} setParams={setParams}/>
      <ListRewards data={data} loadding={loadding} params={params} setParams={setParams} detail={detail}/>
      <PopUpBase
        className={className}
        title={"META 코인 받기"}
        visible={isShowReceive}
        closable={true}
        footer={"주소 복사하기"}
        width={450}
        onClickFooter={() => {
          const el = document.createElement('textarea');
          el.value = detail && detail.address;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          Ui.showSuccess({message: '주소가 복사되었습니다.'})
        }}
        onCancel={() => {setShowReceive(false)}}
      >
       <div className="modal_receive">
          <div>잠깐! 주의하세요. </div>
          <div>META 코인만 받을 수 있습니다.</div>
          <div className="qrcode">
            <img src={detail && detail.wallet_qr_code} width={200} height={200}/>
          </div>
          <div>{detail && detail.address}</div>
       </div>
      </PopUpBase>
    </div>
  );
});

Rewards.propTypes = {
  className: PropTypes.any,
};
export default styled(Rewards)`
  .modal_receive {
    text-align: center;
    padding: 20px 0;
    .qrcode {
      padding: 20px 0;
    }
  }
`;
