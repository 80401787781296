import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import DetailSurveyImage from './DetailSurveyImage';
import StaticSurveyImage from './StaticSurveyImage';

const SurveyImage = memo(({ className, index, item_question, answer_type }) => {
    const [isShowDetail, setShowDetail] = useState(false);
    const renderContent = () => {
        if(isShowDetail) {
            return <DetailSurveyImage item_question={item_question} answer_type={answer_type}/>
        } else {
            return <StaticSurveyImage item_question={item_question} answer_type={answer_type}/>
        }
    }
    return (
        <div className={className}>
            <div className="header_black">
                <div className="title_header">{`${index + 1}. ${item_question.content}?`}</div>
                <div className="view_btn">
                <div // Summary //statistics
                    onClick={() => {
                        setShowDetail(false)
                    }}
                    className={isShowDetail ? "btn b-s-btn" : "btn btn_active b-s-btn"}>요약</div>
                <div // detail  su dung quession info
                    onClick={() => {
                        setShowDetail(true)
                    }}
                    className={isShowDetail ? "btn btn_active b-s-btn" : "btn b-s-btn"}>자세히</div>
                </div>
            </div>
            <div style={{marginRight: 60}}>
                {renderContent()}
            </div>
            

        </div>
    );
});

SurveyImage.propTypes = {
  className: PropTypes.any,
};
export default styled(SurveyImage)`
    .summay_text {
        padding-left: 30px;
        .item_text {
            margin: 15px 0;
        }
    }
   
    .header_black {
        padding: 10px;
        justify-content: space-between;
        display: flex;
        .title_header {
            font-size: 20px;
            color: #fff;
        }
        .view_btn {
            color: #fff;
            display: flex;
        
            .btn {
                min-width: 83px;
                cursor: pointer;
                margin: 0 5px;
                text-align: center;
                justify-content: center;
                align-items: center;
                background: #ffff;
                border-radius: 10px;
                padding: 2px 17px;
                color: #9e9e9e;
                display: flex;
            }
            .btn_active {
                color: #000;
                font-weight: 700;
            }
        }
    }
`;
