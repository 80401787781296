import React, {memo} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import TopMenu from './TopMenu';
import SignIn from '../SignIn';
import styled from "styled-components";

import { useSelector } from 'react-redux'
import PrivateRoute from './PrivateRoute';
import { useDispatch } from 'react-redux'

import {
  logOut,
} from "../../store/actions";

import AdminSetting from '../AdminSetting';
import MyWorkSpace from '../MyWorkSpace';
import Rewards from '../Rewards';
import NotifiPage from '../NotifiPage';
import MyProfile from '../MyProfile';
import SurveyDetail from '../SurveyDetail';
import RewardsSend from '../RewardsSend';
import EventDetail from '../EventDetail';


const AppRouter = memo((className) => {
  const dispatch = useDispatch()

  const App = useSelector(state => state.App)
  const isAuthenticated = App.get("isAuthenticated")
  const profile = App.get("profile")

  return (
    <Router className={className}>
        <TopMenu onLogOut={() => dispatch(logOut())} isAuthenticated={isAuthenticated} profile={profile}/>
        <div style={{paddingRight: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10}}>
          <Switch>
              <Route path="/sign-in">
                <SignIn/>
              </Route>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/admin-setting">
                <AdminSetting />
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/my-workspace">
                <MyWorkSpace/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/rewards">
                <Rewards/>
              </PrivateRoute>
              
              <PrivateRoute isAuthenticated={isAuthenticated} path="/survey-detail">
                <SurveyDetail/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/survey-result">
                <SurveyDetail/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/reward-status">
                <SurveyDetail/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/notifi-page">
                <NotifiPage/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/my-profile">
                <MyProfile/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/rewards-send">
                <RewardsSend/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/event">
                <EventDetail/>
              </PrivateRoute>
              <PrivateRoute isAuthenticated={isAuthenticated} path="/">
                <MyWorkSpace/>
              </PrivateRoute>
            </Switch>
        </div>
    </Router>
  )
})

export default styled(AppRouter)`
  .main {
    margin-left: 120px;
    margin-top: 30px;
  }
`;
