import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import Detail from './Detail';
import Result from './Result';
import Status from './Status';

const SurveyDetail = memo(({ className }) => {
	const location = useLocation();
	const history = useHistory();
	const [itemMenuSelected, setMenuSelected] = useState({
		id: 1,
		name: '설문상세',
	})
	const menuBar = [
		{
			id: 1,
			name: '설문상세',
			path: `/survey-detail`
		},
		{
			id: 2,
			name: '결과보기',
			path: '/survey-result'
		},
		{
			id: 3,
			name: '리워드현황',
			path: '/reward-status'
		},
	]

	const renderContent = () => {
		switch(location.pathname) {
			case `/survey-detail`:
				return <Detail hash_value={location.search.replace("?hash_value=", "")}/>
			case '/survey-result':
				return <Result 
					hash_value={location.search.replace("?hash_value=", "")}
					/>
				break;
			case '/reward-status':
				return <Status 
						hash_value={location.search.replace("?hash_value=", "")}	
					/>
				break;
			default:
		}
		
	}
  return (
    <div className={className}>
      <div className="side_menu">
			{
				menuBar.map((item, index) => (
					<div onClick={() => {
						history.push({
							pathname: item.path,
							search: location.search,
							state: { hash_value: location.search.replace("?hash_value=", "") }
						});
					}}
						className={location.pathname === item.path ? "active menu_name" : "menu_name"} key={index}>
						{item.name}
					</div>
				))
			}
		</div>
		<div className="content">
			{renderContent()}
		</div>
    </div>
  );
});

SurveyDetail.propTypes = {
  className: PropTypes.any,
};
export default styled(SurveyDetail)`
	display: flex;
	background: #fff;
	.side_menu {
		min-width: 100px;
		min-height: 400px;
		border-right: 1px solid #000;
		padding: 0 20px;
		.menu_name {
			font-size: 13px;
			color: #ccc;
			cursor: pointer;
			min-width: 90px;
			padding: 6px;
			font-weight: bold;
		}
		.active {
			color: #000;
		}
	}
	.content {
		width: 100%;
		padding: 0 20px;
	}
	
`;
