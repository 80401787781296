import React, { useState } from "react"
import styled from "styled-components";
import { useLocation } from "react-router-dom";

function OpenDescYesNo({ event }) {
    const [position_answer, SetPositionAnswer] = useState(null)
    const question = event.questions[0]
    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");

    return (
        <div className='d-flex flex-column' style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}>
            <div className='flex-fill d-flex flex-column'>
                <div className='d-block d-sm-none'>
                    <div className='d-flex flex-column' style={{ backgroundColor: '#F8F8F8', margin: '10px 0 0 0', padding: '0 20px 30px 20px', backgroundColor: 'white' }}>
                        <div className='d-flex flex-row align-items-center' style={{ margin: '20px 0 40px 0' }}>
                            <img src='/icons/icon_q.svg' style={{ width: 22, height: 29 }} />
                            <div style={{ marginLeft: 16, fontWeight: 'bold', fontSize: isNotEvent ? 15 : 14 }}>{question?.content}</div>
                        </div>
                        <div className='d-flex flex-row'
                            style={{ backgroundColor: '#FCFCFC' }}>
                            <div className='d-flex justify-content-center align-items-center noselect'
                                style={{
                                    minHeight: 164, flex: 1, fontSize: isNotEvent ? 17 : 13, alignItems: 'center',
                                    fontWeight: position_answer === question?.answers[0].hash_value ? 'bold' : 'normal',
                                    color: position_answer === question?.answers[0].hash_value ? 'white' : '#111111',
                                    backgroundColor: position_answer === question?.answers[0].hash_value ? '#1273e2' : '#FCFCFC',
                                    borderTopLeftRadius: 8, borderBottomLeftRadius: 8,
                                    border: 'solid 1px #ededed',
                                    borderWidth: position_answer === question?.answers[0].hash_value ? 0 : '1px 0 1px 1px'
                                }}
                                onClick={() => {}}>
                                {question?.answers[0]?.content}
                            </div>
                            {
                                position_answer === undefined || position_answer === null ? <div className='d-flex justify-content-center align-items-center'
                                    style={{ border: 'solid 1px #ededed', borderRightWidth: 0, borderLeftWidth: 0 }}>
                                    <div style={{ width: 1, height: 128, backgroundColor: '#ededed', fontSize: isNotEvent ? 14 : 12 }}></div>
                                </div> : null
                            }
                            <div className='d-flex justify-content-center align-items-center noselect'
                                style={{
                                    minHeight: 164, flex: 1, fontSize: isNotEvent ? 17 : 13, alignItems: 'center',
                                    fontWeight: position_answer === question?.answers[1].hash_value ? 'bold' : 'normal',
                                    color: position_answer === question?.answers[1].hash_value ? 'white' : '#111111',
                                    backgroundColor: position_answer === question?.answers[1].hash_value ? '#1273e2' : '#FCFCFC',
                                    borderTopRightRadius: 8, borderBottomRightRadius: 8,
                                    border: 'solid 1px #ededed',
                                    borderWidth: position_answer === question?.answers[1].hash_value ? 0 : '1px 1px 1px 0',
                                }}
                                onClick={() => {}}>
                                {question?.answers[1]?.content}
                            </div>
                        </div>

                        <div className='d-flex flex-row justify-content-center align-items-center'
                            style={{ backgroundColor: '#fae7e7', borderRadius: 8, padding: 11, marginTop: 16, alignItems: 'center' }}>
                            <img src='/icons/icon_alert.svg' style={{ width: 12, height: 12 }} />
                            <div style={{ marginLeft: 8, fontSize: isNotEvent ? 13 : 11 }}>
                                더폴 또는 바로참여하기를 통해 설문을 마무리해주세요.
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center' style={{ backgroundColor: 'white', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: 320, height: 56, borderRadius: 4, backgroundColor: '#ccc', margin: '16px 0', justifyContent: 'center', alignItems: 'center' }}
                                className='d-flex justify-content-center align-items-center noselect' onClick={() => {
                                }}>
                                <span style={{ fontSize: isNotEvent ? 15 : 12, fontWeight: 'bold', color: 'white' }}>{'투표완료'}</span>
                            </div>
                            <div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6' }}></div>
                        </div>

                    </div>
                    <div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6' }}></div>
                </div>
            </div>
            
        </div>
    );
}


export default styled(OpenDescYesNo)`
    .noselect {
        align-items: center;
    }
    
`;
  