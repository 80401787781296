import React, { memo } from "react";
import { Row, Col, Button, Form, Input } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import Pagination from "../../../components/Pagination";
const { Search } = Input;

let inputTimer = null;


const Filter = memo(({ className, setParams, params, total, data }) => {
 
  return (
    <div className={className}>
      <div className="search">
        <div>검색</div>
        <Input
          allowClear
          placeholder="검색어를 입력하세요."
          onChange={e => {
            const value = e.target.value
            if (inputTimer) {
              clearTimeout(inputTimer);
            }
            inputTimer = setTimeout(() => {
              setParams((prevState) => {
                let nextState = { ...prevState };
                nextState.search = value;
                nextState.offset = 1;
                nextState.currentPage = 1;
                return nextState;
              });
            }, 500);
          }}
          style={{ width: 400, marginLeft: 20 }}
        />
      </div>
      <div className="pagination">
        <Pagination
          onFirstPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              return nextState;
            });
          }}
          onEndPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = Math.ceil(total / params.limit);
              return nextState;
            });
          }}
          onChangeSize={(pageSize) => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              nextState.limit = pageSize;
              return nextState;
            });
          }}
          onChange={(currentPage) => {
             setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = currentPage;
              return nextState;
            });
          }}
          pageSize={params.limit}
          current={params.currentPage}
          total={data.length === 0 ? total + 1 : total}
        />
      </div>
    </div>
  );
});
Filter.propTypes = {
  className: PropTypes.any,
};
export default styled(Filter)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0px 0px 20px 0;
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    .name {
      padding-right: 20px;
    }
  }

`;
