import React, { useState, useCallback, useEffect, memo } from "react";
import { Table } from "antd";
import styled from "styled-components";
import PopUpBase from '../../../components/PopUpBase'
import * as style from '../../../components/Variables';
import {META_BLOCK_EXPLORER_URL} from '../../../utils/constants';
import PropTypes from "prop-types";
import moment from "moment";
import { formatNumber, formatMETA } from "../../../utils/helper";
import EmptyTable from '../../EmptyTable';

const ListWorkSpace = memo(
    ({ className, data, loadding }) => {

    const [itemSelected, setItemSelected] = useState(null)
    const [isShowDetail, setShowDetail] = useState(false)

    const columns = [
        {
            title: "수령인 (META ID)",
            dataIndex: "receiver_meta_id",
            key: "receiver_meta_id",
            align: 'center',
            width: 140,
            render: (value, row) => <div className="curor"
                onClick={() => {
                    setShowDetail(true)
                    setItemSelected(row)
                }}
            >{value ? `${value.substring(0, 2)}...${value.substr(value.length - 4)}` : ''}</div>
        },
        {
            title: "수량",
            dataIndex: "value",
			align: 'center',
            width: 150,
            render: (value, row) => <div className="curor"
                onClick={() => {
                    setShowDetail(true)
                    setItemSelected(row)
                }}
            >{`${formatMETA(value)} META`}</div>
        },
        {
            title: "거래일자",
            dataIndex: "created_date",
            align: 'center',
            width: 120,
            render: (value, row) => <div className="curor"
                onClick={() => {
                    setShowDetail(true)
                    setItemSelected(row)
                }}
            >{value ? moment(value).format("YYYY.MM.DD") : ''}</div>
        },
        {
            title: "TXID",
            dataIndex: "transaction_hash",
            align: 'center',
            width: 300,
            render: (value, row) => <div className="curor" 
                onClick={() => {
                setShowDetail(true)
                setItemSelected(row)
            }}>{value}</div>
        },
        {
            title: "",
			dataIndex: "status",
            align: 'center',
            width: 100,
            render: (value, row) => <div onClick={() => {
                // setShowDetail(true)
                // setItemSelected(row)
                window.open(`${META_BLOCK_EXPLORER_URL}tx/${row.transaction_hash}`, "_blank") //to open new page
            }} style={{cursor: 'pointer'}}><i className="fa fa-external-link" aria-hidden="true"></i></div>
        },
      
    ]
    return (
		<div className={className}>
			<Table
                locale={{ emptyText: <EmptyTable/>}}
                loading={loadding}
				columns={columns} 
				dataSource={data}
				rowKey="receiver_meta_id"
				// scroll={{ x: 'max-content' }}
				pagination={false}
			/>
            <PopUpBase
                className={className}
                title={"META 코인 거래내역"}
                visible={isShowDetail}
                closable={false}
                footer={"닫기"}
                onClickFooter={() => {setShowDetail(false)}}
                onCancel={() => {setShowDetail(false)}}
            >
                {
                    itemSelected && (
                       <div className="modal_detail">
                           <div className="header d-flex j-c-b">
                               <div>리워드를 지급한 내역입니다.</div>
                                <div>{moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                           </div>
                           <div className="title_event" style={{textAlign: 'center'}}>{itemSelected.event_title}</div>
                            <div className="content">
                                <div className="left">
                                    <div className="title_left">구분</div>
                                    <div className="title_left">수량</div>
                                    <div className="title_left">수수료</div>
                                    <div className="title_left">수령인 (META ID)</div>
                                    <div className="title_left m_b_10">수령 주소</div>
                                    <div className="title_left m_b_10">TXID</div>
                                </div>
                                <div className="right">
                                    <div className="right_bottom">리워드 지급 </div>
                                    <div className="right_bottom">{`${formatMETA(itemSelected.value)} META`}</div>
                                    <div className="right_bottom">{`${formatMETA(itemSelected.tx_fee)} META`}</div>
                                    <div className="right_bottom">{`${itemSelected.receiver_meta_id.substring(0, 2)}...${itemSelected.receiver_meta_id.substr(itemSelected.receiver_meta_id.length - 4)}`}</div>
                                    <div className="right_bottom m_b_10 word-break ">{itemSelected.receiver}</div>
                                    <div onClick={() => {
                                        window.open(`${META_BLOCK_EXPLORER_URL}tx/${itemSelected.transaction_hash}`, "_blank") //to open new page
                                    }} className="right_bottom m_b_10 underline word-break curor">{itemSelected.transaction_hash}</div>
                                </div>
                            </div>
                       </div>
                    )
                }
            </PopUpBase>
		</div>
    );
});

ListWorkSpace.propTypes = {
  className: PropTypes.any,
};
export default styled(ListWorkSpace)`
    .ant-table-cell {
        word-break: break-word;
    }
    .title_event {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .curor {
        cursor: pointer;
    }
.modal_detail {
    .header {
        margin-bottom: 20px; 
    }
    .content {
        display: flex;
        .m_b_10 {
            margin-bottom: 10px;
        }
        .underline {
            text-decoration: underline;
        }
        .left {
            padding-top: 4px;
            min-width: 140px;
            padding-right: 20px;
            background: ${style.color.backgroundGray};
            text-align: right;
            .title_left {
                display: flex;
                justify-content: flex-end;
                height: 40px;
            }
        }
        .right {
            padding-top: 4px;
            display: flex;
            margin-left: 10px;
            flex-direction: column;
            .word-break {
                word-break: break-all;
                white-space: normal;
                width: 300px;
            }
            .right_bottom {
                overflow: hidden;
                display: flex;
                justify-content: flex-start;
                height: 40px;
            }
        }
     }
}

`;
