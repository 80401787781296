import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../../utils/ServiceBase";
import { Ui } from "../../../utils/Ui";
import { STATUS_EVENT, REACT_APP_WEB } from "../../../utils/constants";

import Header from './Header';
import Filter from './Filter';
import List from './List';
import { saveAs } from 'file-saver';


const Status = memo(({ className, hash_value }) => {
  const [loadding, setLoadding] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [detail_event, set_detail_event] = useState(null)
  const [detail, setDetail] = useState(null)
  const [event_info, set_event_info] = useState(null)
  const [params, setParams] = useState({
    limit: 10,
    offset: 1,
    currentPage: 1,
    search: '',
  })

  const getDetailEvent = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `events/${hash_value}`,
      data: {},
    });
    if (result.hasErrors) {
    } else {
        set_detail_event(result.value)          
    }
  }, [hash_value]);

  useEffect(() => {
      getDetailEvent();
  }, []);

  const getListReward = useCallback(async () => {
    setLoadding(true);
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `events/${hash_value}/reward`,
      data: {
        ...params,
        offset: (params.currentPage - 1) * params.limit,
      },
    });
    if (result.hasErrors) {
      // Ui.showErrors(result.errors[0].data.details)
    } else {
      setTotal(result.value.count)
      setData(result.value.results)
      setDetail(result.value)
    }
    setLoadding(false);
  }, [params, hash_value]);

  const getEventResult = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `events/${hash_value}/statistics`,
      data: {},
    });
    if (result.hasErrors) {
    } else {
      set_event_info(result.value.event_info)
    }
  }, [hash_value]);

  const onExportExcel = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "EXPORT",
      url: `events/${hash_value}/reward/reports`,
      data: {},
    });
    if (result.hasErrors) {
      Ui.showError({ message: result });
    } else {
      var blob = new Blob([result.value], {type: "application/vnd.ms-excel;charset=utf-8"});
      saveAs(blob, `${event_info.title}.xlsx`);
    }
  }, [hash_value, event_info]);

  const renderStatus = () => {
    if(detail_event?.status === "stand_by") {
      if(detail_event?.is_paused) {
        return STATUS_EVENT['stopped']
      } else {
        return STATUS_EVENT['waiting_display']
      }
    }
    return STATUS_EVENT[detail_event?.status]
  }

  useEffect(() => {
    getListReward();
  }, [getListReward]);

  useEffect(() => {
    getEventResult();
  }, []);

  return (
    <div className={className}>
      <div className="header">
        <div className="d-flex j-c-b a-i-c header1">
          <div className="title">{event_info && event_info.title}</div>
          <div className="status">{renderStatus()} 
              <span>상태입니다.</span> 
              <span 
                style={(event_info?.status === 'draft') ? null : {cursor: 'pointer'}}
                onClick={() => {
                  if(event_info?.status === 'draft') return;
                  window.open(`${REACT_APP_WEB}event/${event_info.hash_value}`, "_blank") //to open new page
              }} className="link_open"><i className="fa fa-external-link" aria-hidden="true"></i></span>
            </div>
        </div>
        <div className="d-flex a-i-c j-c-b header2">
          <div className="title_btn"></div>
          <div className="d-flex j-c-b">
            <div className="b-s-btn btn black" onClick={onExportExcel}>데이터 내보내기 (excel) </div>
          </div>
        </div>
      </div>
      {
        detail && <Header detail={detail}/>
      }
      <div>지급 내역</div>
      <Filter data={data} params={params} setParams={setParams} total={total}/>

      <List data={data} params={params} setParams={setParams} loadding={loadding} />
    </div>
  );
});

Status.propTypes = {
  className: PropTypes.any,
};
export default styled(Status)`
  .header {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    .header1 {
      margin-bottom: 5px;
      .title {
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }
      .status {
        font-size: 17px;
        color: #000;
        span {
          margin-left: 4px;
          font-size: 12px;
          color: #777575;
        }
        .link_open {
          color: #000;
          font-size: 20px;
        }
      }
    }
    .header2 {
      .title_btn {
        color: #ccc;
      }
      .btn {
        margin-left: 20px;
        padding: 7px 66px;
        border-radius: 4px;
      }
      .black {
        cursor: pointer;
        background: #000;
        color: #fff;
      }
    }
  }
`;
