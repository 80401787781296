import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducers from './store/reducers';
import createSagaMiddleware from 'redux-saga';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import './fonts/malgun.ttf'; 

const sagaMiddleware = createSagaMiddleware()

var store = createStore(
    rootReducers,
    applyMiddleware(sagaMiddleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={ store }>
      <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
