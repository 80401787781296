import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import Detail from './Detail';
import StaticYesNo from './StaticYesNo';

const SurveyYesNo = memo(({ className, event_info, question_info }) => {
    const [isShowDetail, setShowDetail] = useState(false);
    
    const renderContent = () => {
        if(isShowDetail) {
            return (
                <div className="detail_yes_no">
                    <Detail question_info={question_info} event_info={event_info}/>
                </div>
            )
        } else {
            return <StaticYesNo question_info={question_info} event_info={event_info}/>
        }
    }
    return (
        <div className={className}>
            <div className="header_black">
                <div className="title_header">{question_info.content}</div>
                <div className="view_btn">
                <div // Summary //statistics
                    onClick={() => {
                        setShowDetail(false)
                    }}
                    className={isShowDetail ? "btn b-s-btn" : "btn btn_active b-s-btn"}>요약</div>
                <div // detail  su dung quession info
                    onClick={() => {
                        setShowDetail(true)
                    }}
                    className={isShowDetail ? "btn  btn_active b-s-btn" : "  btn b-s-btn"}>자세히</div>
                </div>
            </div>
            <div style={{marginRight: 60}}>
                {renderContent()}
            </div>
            

        </div>
    );
});

SurveyYesNo.propTypes = {
  className: PropTypes.any,
};
export default styled(SurveyYesNo)`
    .detail_yes_no {
        margin-top: 2px;
    }
    .header_black {
        padding: 10px;
        justify-content: space-between;
        display: flex;
        .title_header {
            font-size: 17px;
            color: #000;
        }
        .view_btn {
            color: #fff;
            display: flex;
        
            .btn {
                min-width: 83px;
                cursor: pointer;
                margin: 0 5px;
                text-align: center;
                justify-content: center;
                align-items: center;
                background: #ffff;
                border-radius: 10px;
                padding: 2px 17px;
                color: #9e9e9e;
                display: flex;
            }
            .btn_active {
                color: #000;
                font-weight: 700;
            }
        }
    }
   
   
`;
