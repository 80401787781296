import React, { useState, useCallback, useEffect, memo, } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import Header from '../SurveyDetail/Status/Header';

const HeaderStatus = memo(({ className, hash_value, isDetail }) => {
    if(!hash_value) return null;
    const [detail, setDetail] = useState(null)

    const getListReward = useCallback(async () => {
        const result = await ServiceBase.requestJson({
          method: "GET",
          url: `events/${hash_value}/reward`,
          data: {
            limit: 10,
            offset: 1,
            currentPage: 1,
            search: '',
          },
        });
        if (result.hasErrors) {
          // Ui.showErrors(result.errors[0].data.details)
        } else {
          setDetail(result.value)
        }
      }, [hash_value]);

	useEffect(() => {
		getListReward();
	}, [getListReward]);
	
	return (
		<div className={className} style={isDetail ? {marginBottom: 50} : {marginRight: 20, marginLeft: 20}}>
			<div style={{fontSize: 14, fontWeight: 'bold', marginTop: 20, fontFamily: 'MalgunGothicBold'}}>리워드</div>
        {
            detail && <Header isDetail={isDetail} detail={detail}/>
        }
		</div>
	);
});

HeaderStatus.propTypes = {
  className: PropTypes.any,
};
export default (styled(HeaderStatus)`
`);

