import React, { useState } from "react"
import OpenDetail from './OpenDetail';
import YesNoContent from './YesNoContent';
import OpenDescMulti from './OpenDescMulti';

export default function Open({ event }) {
   if(event.event_type === 'survey_yes_no') {
       return (
            <div className='d-flex flex-column' style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}> 
                <YesNoContent event={event}/>
            </div>
       )
   }
   if(event.event_type === 'campaign') {
        return (
            <div className='d-flex flex-column' style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}> 
                <OpenDetail event={event}/>
            </div>
        )
    }
   if(event.event_type === 'survey_multiple') {
        return (
            <div className='d-flex flex-column' style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}> 
                <OpenDescMulti event={event}/>
            </div>
        )
   }
   return null;
}