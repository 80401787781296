import React, { useState, useCallback, useEffect, memo, } from "react";
import { Input, Form, Spin } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux'
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import PopUpBase from '../../components/PopUpBase'
import ChangePassWord from './ChangePassWord';
import NumberInput from '../../components/NumberInput';
import { useDispatch } from 'react-redux'
import { setAuthenticated, logOut } from "../../store/actions";

const MyProfile = memo(({ className, profile, setAuthenticated }) => {
	const dispatch = useDispatch()

	const [textWarinng, setTextWarning] = useState('');
	const [data, setData] = useState(null);
	const [loadding, setLoadding] = useState(false);
	const [isShowModalChangePass, setShowModalChangePass] = useState(false)
	const [isShowModalChangePassSuccess, setShowModalChangePassSuccess] = useState(false)

	const _changeQuery = useCallback((payload) => {
		setData((prevState) => {
			let nextState = { ...prevState };
			nextState[payload.name] = payload.value;
			return nextState;
		})
	}, [setData])


	const onSubmit = useCallback(async () => {
		const result = await ServiceBase.requestJson({
			method: "PUT",
			url: `accounts/${data.id}`,
			data: data
		});
		if (result.hasErrors) {
			try {
				Ui.showErrors(result.errors[0].data.details)
			} catch (error) {}
		} else {
			getProfile()
		}
		
	}, [data])

	const onChangePassWord = useCallback(async (value) => {
		const result1 = await ServiceBase.requestJson({
			method: "POST",
			url: `accounts/checkpass`,
			data: {
				old_password: value.old_password,
			}
		});
		if (result1.hasErrors) {
			setTextWarning("현재 비밀번호를 다시 확인해주세요.")
		} else {
			let regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\"'()+,\-.\/:;<=>?\\_`{|}~!@#\$%\^&\*])(?=.{8,})")
			let regex_ = new RegExp("(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789|qwe|wer|ert|rty|tyu|yui|uio|iop|asd|sdf|dfg|fgh|ghj|hjk|jkl|zxc|xcv|cvb|vbn|bnm)+")
			if (regex.test(value.new_password) && !regex_.test(value.new_password.toLowerCase())) {
				if(value.new_password !== value.new_password_1) {
					return setTextWarning("변경할 비밀번호가 일치하지 않습니다.")
				} else {
					const result = await ServiceBase.requestJson({
						method: "POST",
						url: `accounts/changepass`,
						data: {
							old_password: value.old_password,
							new_password: value.new_password
						}
					});
					if (result.hasErrors) {
						
					} else {
						setTextWarning("")
						await setShowModalChangePass(false)
						setShowModalChangePassSuccess(true)
					}
				}
			} else {
				return setTextWarning("비밀번호 작성 규칙을 확인한 후 비밀번호를 다시 설정해주세요.")
			}
		}
	}, [])

	  
	const getProfile = useCallback(async () => {
		await setLoadding(true)
		const result = await ServiceBase.requestJson({
				method: "GET",
				url: "accounts/profile",
				data: {},
			});
			if (result.hasErrors) {
				//  Ui.showErrors(result.errors[0].data.details)
			} else {
				setData(result.value)
				const profile = {
					user: result.value,
				  }
				setAuthenticated({
					isAuthenticated: true,
					profile,
				});
			}
			setLoadding(false)
	}, [])

	useEffect(() => {
		getProfile()
	}, [])

	return (
		<div className={className}>
			<div className="title_profile">내 정보 </div>
				<Spin spinning={loadding}>
					<div className="d-flex content">
						<div className="left">
							<div className="title_left">이름</div>
							<div className="title_left">이메일</div>
							<div className="title_left">전화번호</div>
							<div className="title_left">상태</div>
							<div className="title_left">비밀번호</div>
						</div>
						<div className="right">
							<Input
								onChange={(e) => {
									_changeQuery({ name: "username", value: e.target.value });
								}}
								value={data && data.username}  placeholder="홍길동"
							/>
							<Input disabled value={data && data.email} placeholder="email"/>
							<NumberInput
								onChange={(value) => {
									_changeQuery({ name: "phone_number", value: value });
								}}
								value={data && data.phone_number} placeholder="전화번호를 입력하세요."/>
							<Input disabled value={data && data.is_active ? '활성화' : '비활성화'}  placeholder="활성화"/>
							<div onClick={() => setShowModalChangePass(true)} className="right_bottom underline">비밀번호 변경</div>
						</div>
					</div>
				</Spin>
				<div className="d-flex btn_bottom">
					<div onClick={() => {
						getProfile()
					}} className="cancel">취소</div>
					<div onClick={onSubmit} className="submit">정보 변경</div>
				</div>
				<PopUpBase
					className={className}
					title={"비밀번호 변경"}
					visible={isShowModalChangePass}
					closable={true}
					footerNew={<div></div>}
					onCancel={() => {setShowModalChangePass(false)}}
				>
					<ChangePassWord onChangePassWord={onChangePassWord} textWarinng={textWarinng}/>
				
				</PopUpBase>
			<PopUpBase
				width={400}
				className={className}
				title={"비밀번호 변경 완료"}
				visible={isShowModalChangePassSuccess}
				closable={false}
				footer={"확인"}
				onClickFooter={async() => {
					await setShowModalChangePassSuccess(false)
					dispatch(logOut())
				}}
				onCancel={() => {setShowModalChangePassSuccess(false)}}
			>
				<div className="modal_success">
					<div>비밀번호 변경이 완료되었습니다. </div>
					<div>변경된 비밀번호로 다시 로그인해주세요.</div>
				</div>
				
			</PopUpBase>
		</div>
	);
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuthenticated,
      logOut,
    },
    dispatch
  );
const withConnect = connect(
	null,
	mapDispatchToProps
);
MyProfile.propTypes = {
  className: PropTypes.any,
};
export default compose(
	withConnect,
	memo
  )(styled(MyProfile)`
	.modal_success {
		text-align: center;
		padding: 50px 0;
	}
	.ant-form-item {
		margin-bottom: 0px;
	}
	.btn_bottom {
		margin-left: 120px;
		margin-top: 30px;
		.cancel {
			cursor: pointer;
			color: #000;
			padding: 9px 15px;
			background: #f2f2f2;
			margin-right: 20px;
			min-width: 100px;
			text-align: center;
		}
		.submit {
			text-align: center;
			cursor: pointer;
			min-width: 100px;
			color: #fff;
			background: #000;
			padding: 9px 15px;
		}
	}
	.title_profile {
		color: #000;
		margin-bottom: 30px;
	}
	.content {
		margin-left: 20px;
	}
	.left {
		min-width: 180px;
		margin-right: 20px;
		background: #f2f2f2;
		text-align: center;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		.title_left {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 35px;
			margin-bottom: 10px;
		}
	}
	.right {
		display: flex;
		flex-direction: column;
		.right_bottom {
			height: 35px;
			align-items: center;
			display: flex;
			color: #000;
		}
		.ant-input {
			width: 300px;
			height: 35px;
			margin-bottom: 10px;
		}
		.underline {
			cursor: pointer;
			font-weight: bold;
			text-decoration: underline;
		}
	}
	.modal_change {
		.modal_content {
			padding: 24px;
		}
		.left {
			min-width: 120px;
			margin-right: 20px;
			background: #f2f2f2;
			text-align: center;
			.title_left {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 35px;
				margin-bottom: 10px;
			}
		}
	}
	.footer {
		justify-content: center;
		background: #000;
		display: flex;
		padding: 0;
		cursor: pointer;
		color: #fff;
		padding: 20px;
		width: 100%;
		text-align: center;
		border: none;
	}
	.ant-modal-body {
		padding: 0px;
	}
  `);

