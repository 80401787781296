import React, { useCallback, useRef } from "react";
import { Row, Col, DatePicker, Select, Input, AutoComplete } from "antd";
import styled from "styled-components";
import Pagination from '../../components/Pagination'
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';

const { RangePicker } = DatePicker;

const { Option } = Select;
const format = "YYYY-MM-DD";
let inputTimer = null;
const Filter = ({ className, setParams, params, total, data }) => {
 
  const onChange = (value) => {
    setParams((prevState) => {
      let nextState = { ...prevState };
      nextState.date = value;
      nextState.date_gte = undefined;
      nextState.date_lte = undefined;
      return nextState;
    });
  }
  return (
    <div className={className}>
      <div className="search">
        <div>검색</div>
        <Input
          allowClear
          placeholder="검색어를 입력하세요."
          onChange={e => {
            const value = e.target.value
            if (inputTimer) {
              clearTimeout(inputTimer);
            }
            inputTimer = setTimeout(() => {
              setParams((prevState) => {
                let nextState = { ...prevState };
                nextState.search = value;
                nextState.offset = 1;
                nextState.currentPage = 1;
                return nextState;
              });
            }, 500);
          }}
          style={{ width: 300, marginLeft: 20 }}
        />
      </div>
      <div style={{marginLeft: 20}} className="filter_date d-flex">
        기간 &nbsp;&nbsp;
        <div className="d-flex">
          <div 
            onClick={() => {
              onChange('1month')
            }} 
          className="item_date" style={{color: params.date === '1month' ? '#000' : '#ccc'}}>1개월 |</div>
          <div 
            onClick={() => {
              onChange('3month')
            }} 
          className="item_date" style={{color: params.date === '3month' ? '#000' : '#ccc'}}>3개월 | </div>
          <div 
            onClick={() => {
              onChange('6month')
            }} 
          className="item_date" style={{color: params.date === '6month' ? '#000' : '#ccc'}}>6개월 | </div>
        </div>
        <RangePicker
          value={(params.date === '1month' || params.date === '3month' || params.date === '6month') ? [undefined, undefined] : [params.date_gte ? moment(params.date_gte) : undefined, params.date_lte ? moment(params.date_lte) : undefined]}
          allowClear
          locale={locale}
          style={{ width: 260 }}
          placeholder={["", ""]}
          showToday={false}
          onChange={(dates) => {
            let date_gte =
              dates ? moment(dates[0].startOf("day")).format(format) : undefined;
            let date_lte =
              dates ? moment(dates[1].endOf("day")).format(format) : undefined;
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.date = '';
              nextState.date_gte = date_gte;
              nextState.date_lte = date_lte;
              return nextState;
            });
          }}
        />
      </div>
      <div className="pagination">
        <Pagination
          onFirstPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              return nextState;
            });
          }}
          onEndPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = Math.ceil(total / params.limit);
              return nextState;
            });
          }}
          onChangeSize={(pageSize) => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              nextState.limit = pageSize;
              return nextState;
            });
          }}
          onChange={(currentPage) => {
             setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = currentPage;
              return nextState;
            });
          }}
          pageSize={params.limit}
          current={params.currentPage}
          total={data.length === 0 ? total + 1 : total}
        />
      </div>
    </div>
  );
};
Filter.propTypes = {
  className: PropTypes.any,
};
export default styled(Filter)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0px 0px 20px 0;
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .filter_date {
    .ant-picker-focused {
      box-shadow: none;
    }
    align-items: center;
      .active_date {
        color: #000;
      }
    .item_date {
      color: #ccc;
      padding: 0 4px;
      cursor: pointer;
    }
    // .ant-picker {
    //   border: none;
    //   .ant-picker-suffix {
    //     display: none;
    //   }
    // }
    .ant-picker-dropdown {
      .ant-picker-footer {
        .month {
          font-weight: 600;
          padding: 0 10px;
        }
      }
    }
  }
  .render_month {
    background: red;
  }
`;
