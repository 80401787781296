import React, { useState } from "react"
import moment from 'moment'
import OpenDescYesNo from './OpenDescYesNo';
import OpenDetail from './OpenDetail';
import Pagination from './Pagination';

const YesNoContent = ({ event }) => {
    const [currentPage, setCurrentPage] = useState(1)
    return (
        <div className='d-flex flex-column' style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}>
            <div style={{justifyContent: 'flex-end', display: 'flex',}}>
                <Pagination
                        onFirstPage={() => {
                            setCurrentPage(1)
                        }}
                        onEndPage={() => {
                            setCurrentPage(2)
                        }}
                    
                        onChange={(currentPage) => {
                            setCurrentPage(currentPage)
                        }}
                        current={currentPage}
                        total={2}
                    />
            </div>
            {
                currentPage === 1 ? (
                    <OpenDetail event={event}/>
                ) : (
                    <OpenDescYesNo event={event}/>
                )
            }
        </div>
    );
}

export default YesNoContent;