import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import PopUpBase from '../../components/PopUpBase'
import { useLocation, useHistory } from "react-router-dom";

import Header from './Header';
import ListWorkSpace from './ListWorkSpace';
import Filter from './Filter';
import serialize from './serialize';

const queryString = require('query-string');


const MyWorkSpace = memo(({ className, profile }) => {

  const location = useLocation();
  const parsed_location = queryString.parse(location.search);
  const history = useHistory();

  const [loadding, setLoadding] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    search: '',
    category: [],
    status: [],
    event_type: [],
    source: 'web'
  })


  const [statistics, setStatistics] = useState(null)

  const getListEvent = useCallback(async (params) => {
    setLoadding(true)
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: "events",
      data: {
        limit: params.limit,
        offset: (params.currentPage - 1) * params.limit,
        search: params.search,
        category: params.category.includes("") ? "" : params.category.toString(),
        status: params.status.includes("") ? "" : (params.status.includes("waiting_display") ? `${params.status.toString()},stand_by` : params.status.toString()),
        event_type: params.event_type.includes("") ? "" : params.event_type.toString(),
        source: 'web',
      },
    });
    if (result.hasErrors) {
      //  Ui.showErrors(result.errors[0].data.details)
    } else {
      setData(result.value.results)
      setTotal(result.value.count)
    }
    setLoadding(false)
  }, []);


  const getListStatistics = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: "statistics",
      data: {},
    });
    if (result.hasErrors) {
      //  Ui.showErrors(result.errors[0].data.details)
    } else {
      setStatistics(result.value)
    }
  }, []);

  useEffect(() => {
    getListStatistics();
  }, [getListStatistics]);


  useEffect(() => {
    if(Object.keys(parsed_location).length > 0) {
      const params = {
        category: parsed_location.category ? parsed_location.category.split(",") : [],
        status: parsed_location.status ? parsed_location.status.split(",") : [],
        event_type: parsed_location.event_type ? parsed_location.event_type.split(",") : [],
        limit: parsed_location.limit ? parseInt(parsed_location.limit) : 10,
        offset: parsed_location.offset ? parseInt(parsed_location.offset) : 0,
        currentPage: parsed_location.currentPage ? parseInt(parsed_location.currentPage) : 1,
        search: parsed_location.search ? parsed_location.search : "",
        source: "web",
      }
      setParams(params)
      getListEvent(params);
    } else {
      getListEvent(params);
    }
  }, []);


  return (
    <div>
      {
        statistics &&  <Header statistics={statistics} params={params} setParams={setParams} history={history} getListEvent={getListEvent}/>
      }
     
      <Filter data={data} history={history} params={params} setParams={setParams} total={total} getListEvent={getListEvent}/>
      <ListWorkSpace data={data} history={history} getListEvent={getListEvent}
        params={params} setParams={setParams} loadding={loadding} onRefreshList={() => {
          getListEvent(params)
        }}/>
      <PopUpBase
        title={"hoanfjsb"}
        visible={false}
        closable={false}
        footer={"guyeenx hoagjbdhN"}
      >
        hsdfbdshj
      </PopUpBase>
    </div>
  );
});

MyWorkSpace.propTypes = {
  className: PropTypes.any,
};
export default styled(MyWorkSpace)`
  
`;
