import React, { useState, useEffect, useCallback } from "react"
import {formatMETA} from '../../../utils/helper';
import constants from '../constants';
import moment from 'moment'
import ServiceBase from "../../../utils/ServiceBase";
import { Ui } from "../../../utils/Ui";


export default function Close({ event, hash_value, isDetail }) {
    const [event_info, set_event_info] = useState(null)
    const [questions_info, set_questions_info] = useState(null)

    const getEventResult = useCallback(async () => {
        const result = await ServiceBase.requestJson({
          method: "GET",
          url: `events/${hash_value}/statistics`,
          data: {},
        });
        if (result.hasErrors) {
         try {
            Ui.showError({ message: result });
         } catch (error) {
           
         }
        } else {
          set_event_info(result.value.event_info)
          set_questions_info(result.value.questions_info)
        }
      }, [hash_value]);
    useEffect(() => {
        getEventResult();
    }, [getEventResult]);
    
    let total_count = 0, yes_count = 0, no_count = 0, yes_content, no_content, yes_percent = 0, no_percent = 0
    if (event.event_type === 'survey_yes_no' && questions_info) {
        try {
            let answers_key = Object.keys(questions_info[0]?.answers)
            let answers_value = Object.values(questions_info[0]?.answers)
            yes_content = answers_value[0].content
            no_content = answers_value[1].content
            let age = questions_info[0]?.statistics?.overview?.age
            let array_total_count = age['total']
            let array_yes_count = age[answers_key[0]]
            let array_no_count = age[answers_key[1]]
            Object.values(array_total_count).map(item => total_count = total_count + item)
            Object.values(array_yes_count).map(item => yes_count = yes_count + item)
            Object.values(array_no_count).map(item => no_count = no_count + item)
            yes_percent = total_count ? Math.round((yes_count / total_count) * 100) : 0
            no_percent = total_count ? Math.round((no_count / total_count) * 100) : 0
        } catch (error) {
        }
    }

    return (
        <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
            <div className='flex-fill d-flex flex-column'>
                <div className='d-block d-sm-none'>
                    <div className='d-flex flex-column' style={{ margin: '10px 0' }}>
                        {
                            isDetail ? null : (
                            <div style={{ backgroundColor: '#111111' }}>
                                <div style={{ fontSize: 15, color: 'white', padding: '11px 20px', textAlign: 'center' }}>
                                    <b>{constants.EVENT_CLOSE[event.event_type]}</b>
                                </div>
                            </div>
                            )
                        }
                        
                        <div style={{ fontSize: 14, marginBottom: 18, marginTop: 15 }}><b>{constants.EVENT_REWARD_TITLE[event.event_type]}</b></div>
                        <div style={{ padding: 20, backgroundColor: '#FBFBFB', fontSize: 15 }}>
                            {
                                event.rewards?.reward_amount ?
                                    <div className='d-flex flex-row' style={{ margin: '16px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>

                                        <div style={{ width: 90, fontWeight: 'bold' }}>리워드</div>
                                        <div style={{ color: '#686868', fontWeight: 'bold' }}>{`참여 시 ${formatMETA(event.rewards?.reward_amount)} META (THEPOL 참여)`}</div>
                                    </div> : null
                            }
                            {
                                event.start_date ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>진행기간</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>
                                        {moment(event.start_date).format('YYYY.MM.DD')} - {event.end_date ? moment(event.end_date).format('YYYY.MM.DD') : ''}
                                    </div>
                                </div> : null
                            }
                            {
                                event.category ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>카테고리</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{constants.EVENT_CATEGORY_LIST[event.category]}</div>
                                </div> : null
                            }
                            {
                                event.survey_duration ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>예상시간</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{event.survey_duration}분</div>
                                </div> : null
                            }
                            {
                                event.entity ? <div className='d-flex flex-row' style={{ margin: '10px 10px 0 0', fontSize: isDetail ? 12 : 14  }}>
                                    <div style={{ width: 90, fontWeight: 'bold' }}>주최</div>
                                    <div style={{ color: '#686868', fontWeight: 'bold' }}>{event.entity}</div>
                                </div> : null
                            }
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <div style={{ fontSize: 14, marginBottom: 15, marginTop: 18 }}><b>상세 내용</b></div>
                            <div style={{ padding: 20, backgroundColor: '#FBFBFB', fontSize: 15 }}>
                                <div style={{ marginTop: 16 }} style={{fontSize: isDetail ? 12 : 14}} dangerouslySetInnerHTML={{ __html: event.content }}></div>
                            </div>
                        </div>
                        {
                            event.event_type === 'campaign' ? <div style={{ marginTop: 10 }}>
                                <div style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: 'white', marginTop: 15}}>
                                    <div style={{ marginBottom: 16, fontSize: 14 }}><b>결과</b></div>
                                    <div className='d-flex justify-content-center align-items-center'
                                        style={{ fontWeight: 'bold', borderRadius: 4, background: '#000', color: '#fff', textAlign: 'center', padding: 13, alignItems: 'center', fontSize: isDetail ? 12 : 14 }}>
                                        총 <b style={{ fontSize: 16, padding: '0 4px 0 4px' }}>{event_info?.participation_count}</b> 명이 참여하였습니다.
                                    </div>
                                </div>
                            </div> : (
                                    event.event_type === 'survey_yes_no' ? <div style={{ marginTop: 10 }}>
                                        {
                                            total_count ? <>
                                                <div style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: 'white', fontSize: 15, marginTop: 15 }}>
                                                    <div style={{ marginBottom: 20, fontSize: 14 }}><b>결과</b></div>
                                                    <div className='d-flex flex-row' style={{ width: '100%', backgroundColor: '#D6D6D6', borderRadius: 4, fontSize: isDetail ? 12 : 14 }}>
                                                        <div className='d-flex flex-column' style={{
                                                            width: `${yes_percent}%`, backgroundColor: '#1273e2', height: 60,
                                                            borderRadius: 4, color: 'white'
                                                        }}>
                                                        </div>
                                                        <div className='d-flex flex-column align-items-end' style={{
                                                            width: `${no_percent}%`, backgroundColor: '#D6D6D6', height: 60,
                                                            borderRadius: 4
                                                        }}>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-row' style={{ marginTop: 10, fontSize: 14 }}>
                                                        <div className='flex-fill'>
                                                            <div style={{ fontWeight: 'bold', color: '#1273e2' }}>{yes_content}</div>
                                                            <div className='d-flex align-items-center' style={{marginTop: 8, alignItems: 'center'}}>
                                                                <b style={{ fontSize: 20, color: '#1273e2' }}>{yes_percent}%</b>
                                                                <span style={{ color: '#1273e2', marginLeft: 8 }}>({yes_count}명)</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-column align-items-end'>
                                                            <div style={{ fontWeight: 'bold', fontSize: 15 }}>{no_content}</div>
                                                            <div className='d-flex align-items-center' style={{marginTop: 8, alignItems: 'center'}}>
                                                                <b style={{ fontSize: 20 }}>{no_percent}%</b>
                                                                <span style={{ marginLeft: 8 }}>({no_count}명)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </div> : <div style={{ marginTop: 10 }}>
                                            <div style={{ padding: 20, backgroundColor: 'white', fontSize: 15, marginTop: 20 }}>
                                                <div style={{ marginBottom: 16,  fontSize: 14 }}><b>결과</b></div>
                                                <div className='d-flex justify-content-center align-items-center'
                                                    style={{fontWeight: 'bold', borderRadius: 4, background: '#000', color: '#fff', textAlign: 'center', padding: 13, alignItems: 'center' }}>
                                                    총 <b style={{  fontSize: 16, padding: '0 4px 0 4px' }}>{event_info?.participation_count}</b> 명이 참여하였습니다.
                                                </div>
                                            </div>
                                        </div>
                                )
                        }
                    </div>
                </div>
            </div>

        </div >
    );
}