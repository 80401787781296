import React, { useState, useCallback, useEffect, memo } from "react";
import { Input } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import PopUpBase from '../../components/PopUpBase'
import * as style from '../../components/Variables';
import { useSelector } from 'react-redux'
import {  formatMETA, formatToWei } from "../../utils/helper";
import coinAddressValidator from 'coin-address-validator';
import NumberInput from '../../components/NumberInput';
import TimeDown from './TimeDown';

const countDecimals = (value) => {
  if(Math.floor(value) === value) return 0;
  try {
    return value.toString() && value.toString().split(".") && value.toString().split(".")[1] ? value.toString().split(".")[1].length : 0; 
  } catch (error) {
    return 0;
  }
}

const ERROR_CODE = {
  'code : invalid_verification_code': '인증번호가 올바르지 않습니다. 다시 입력해주세요. '
}

const RewardsSend = memo(({ className }) => {
  const user = useSelector(state => state.App.toJS().profile.user);

  const [detail, setDetail] = useState(null)
  const [value, setValue] = useState('')
  const [receiver, setReceiver] = useState('')


  // Modal
  const [isShowModalFail, setShowModalFail] = useState(false)
  const [isShowModalSuccess, setShowModalSuccess] = useState(false)
  const [isShowModalSendEmail, setShowModalSendEmail] = useState(false)
  const [email, setEmail] = useState(user.email)
  const [verify_code, set_verify_code] = useState('')
  const [textError, setTextError] = useState('')
  const [relation_id, set_relation_id] = useState(null)
  const [isSend, setIsSend] = useState(false)
  const [textWarning, setTextWarning] = useState('')

  const [countNumber, setCountNumber] = useState(0)


  const getListRewards = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: "transactions",
      data: {},
    });
    if (result.hasErrors) {
      //  Ui.showErrors(result.errors[0].data.details)
    } else {
      setDetail(result.value)
    }
  }, []);

  const onSendEmail = useCallback(async () => {
    if(email === '') {
      setTextError("email is invalid")
    } else {
      setTextError("")
      setIsSend(true)
      const result = await ServiceBase.requestJson({
        method: "POST",
        url: "send-coin/authenticate",
        data: {
          // email: 'hoanganhnguyen1712@gmail.com',
          email: email,
        },
      });
      if (result.hasErrors) { 
        //  Ui.showErrors(result.errors[0].data.details)
      } else {
        set_relation_id(result.value.relation_id)
        
      }
    }
   
  }, [email]);

  const onSendVerification = useCallback(async () => {
    const params = {
      relation_id: relation_id, // khi send email sẽ gửi về cái này
      verify_code: parseInt(verify_code), // khi send email sẽ gửi về cái này
      receiver: receiver, /// Dịa chỉ nhập
      value: formatToWei(parseFloat(value)),
    }
    const result = await ServiceBase.requestJson({
      method: "POST",
      url: "send-coin/verification",
      data: params,
    });
    if (result.hasErrors) {
      try {
        set_verify_code("")
        if(result.errors[0].status === 406) {
          if(countNumber === 4) {
            setCountNumber(0)
            setShowModalFail(true)
            setShowModalSendEmail(false)
            setIsSend(false)
          } else {
            setTextError(`${ERROR_CODE[result.errors[0].data.details[0]]} (${countNumber + 1}/5)`)
            setCountNumber(countNumber + 1)
          }
          
        }
      } catch (error) {
        
      }
    } else {
      setShowModalSendEmail(false)
      setShowModalSuccess(true)
      setEmail(user.email)
      setTextError("")
      setIsSend(false)
      setCountNumber(0)
      setValue("")
      setReceiver("")
    }
  }, [relation_id, verify_code, value, receiver, user, countNumber])

  useEffect(() => {
    getListRewards();
  }, [getListRewards]);
  const renderer = useCallback(({ hours, minutes, seconds, completed }) => {
    if(completed) {
      setIsSend(false)
    }
    return (
      <div className="d-flex">
        {
          minutes === 2 && seconds < 30 && <div onClick={onSendEmail} className="red btn_re_send">재요청</div>
        }
        <span>{minutes}:{seconds}</span>
      </div>
    );
  }, [isSend]);

  return (
    <div className={className}>
      <div className="title">META 코인 보내기 </div>
      <div className="coins d-flex j-c-b b-s-btn">
          <div className="name_coins">전송가능 수량</div>
          <div className="valueHeader">{detail && formatMETA(detail.meta_avaible)}&nbsp;<span>META</span></div>
        </div>
      <div className="title_input">보낼 주소 
        <span>한번 전송한 코인은 절대 회수할 수 없으니 META 코인 지갑 주소가 맞는지 다시 한 번 확인해 주세요.</span>
      </div>
      <Input onChange={(e) => setReceiver(e.target.value)} 
        className="input_box" 
        value={receiver}
        placeholder="0xd2f0125B125471748f2156D430256bc3531b5F20" 
      />
      <div>
        <div className="amount_title">보낼 수량</div>
        <div className="send_amount d-flex">
          <Input onChange={(e) => {
            if(e.target.value.includes(".") && countDecimals(e.target.value) > 18) {
              return;
            }
            setValue(e.target.value)
          }} suffix={"META"}
            className="input_amount" 
            placeholder="보낼 수량" 
            value={value >= 0 ? value : ''}
          />
          <div 
            onClick={() => {setValue(parseFloat(formatMETA(detail.meta_avaible)) - 0.00168)}}
            className="btn_send b-s-btn d-flex">최대</div>
        </div>
      </div>

      <div className="t_free">수수료 0.00168 <span>META</span> </div>
      {
        textWarning !== '' ? <div className="warning">{textWarning}</div> : null
      }
       
      <div onClick={() => {
        // setShowModalSendEmail(true)

        if(receiver === '') { // When recipient’s address field is empty
          return setTextWarning('보낼 주소를 입력해 주세요.')
        }
        const isEthAddress = coinAddressValidator.validate(receiver, 'eth', 'both');
        if(!isEthAddress) { // When recipient’s address is invalid
          setReceiver("")
          return setTextWarning('보낼 주소를 다시 확인해 주세요.')
        }
        if(receiver === detail.address) { /// When recipient’s address is same as sender’s address
          setReceiver("")
          return setTextWarning('보내는 주소와 받는 주소가 동일합니다.')
        }

        if(value === '') { // When amount to send field is empty
          return setTextWarning('보낼 수량을 입력해 주세요')
        }
        if(parseFloat(value) === 0) { // When amount to send field is ‘0’
          return setTextWarning('0 META는 보낼 수 없습니다.')
        }
        
        if(parseFloat(value) > formatMETA(detail.meta_avaible)) { // When the amount exceeds available to send amount
          setValue(parseFloat(formatMETA(detail.meta_avaible)) - 0.00168)
          return setTextWarning('전송가능 수량을 초과하였습니다.')
        }
        if(value.toString().includes(".") && countDecimals(value) > 18) { // When the digit goes over 18 decimal
          setValue(parseFloat(value).toFixed(18))
          return setTextWarning('소수점 18번째 자리까지만 입력하실 수 있습니다')
        }
        else {
          setTextWarning('')
          setShowModalSendEmail(true)
        }
        
      }} className="btn_save d-flex a-i-c b-s-btn">
        보내기
      </div>


      {/* Step11111 */}
      <PopUpBase
        destroyOnClose
        className={className}
        title={"이메일 인증"}
   
        visible={isShowModalSendEmail}
        closable={true}
        footer={"인증 완료"}
        footerNew={<div/>}
        width={480}
        onClickFooter={() => {
            setShowModalSendEmail(false)
            setTextError("")
            setIsSend(false)
          }}
        onCancel={() => {
            set_verify_code("")
            setShowModalSendEmail(false) 
            setTextError("")
            setEmail(user.email)
            setIsSend(false)
            setCountNumber(0)
          }}
      >
        <div className="modal_content">
            <div className="modal_title">META 코인을 보내기 위해 이메일 인증을 진행해주세요.</div>
          <div className="text_support d-flex j-c-b">
            <div className="email_input">
              <Input 
                style={{width: '100%'}}
                 onChange={(e) => setEmail(e.target.value)}
                placeholder="Email" 
                value={email}
              />
            </div>
              {
                isSend ? (
                  <div className="d-flex">
                    <TimeDown onSendEmail={onSendEmail} setShowModalSendEmail={setShowModalSendEmail}
                      setIsSend={setIsSend} setShowModalFail={setShowModalFail}/>
                  </div>
                ) : 
                (
                  <div onClick={onSendEmail} className="btn_active">인증번호 요청</div>
                )
              }
          </div>
         
          {
            isSend ? (
              <div style={{marginTop: 20}}>
                <NumberInput onChange={(value) => {
                  setTextError("")
                  if(value.length < 7) {
                    set_verify_code(value)
                  }
                }}
                  className="input_amount" 
                  placeholder={"인증번호를 입력하세요."}
                  value={verify_code}
                />
              </div>
            ) : (
              <div className="text_support">인증번호를 입력하세요.</div>
            )
          }
          {
            textError !== "" ? (
              <div className="text_error">{textError}</div>
            ) : null
          }
        </div>
        {
          isSend && verify_code.length === 6 ? (
            <div onClick={onSendVerification} className="footer" style={{background: '#000'}}>
              인증 완료
            </div>
          ) : (
            <div className="footer" style={{background: '#ccc'}}>
              인증 완료
          </div>
          )
        }
         
      </PopUpBase>

       {/* // FAillllll */}
      <PopUpBase
        className={className}
        title={"이메일 인증 실패"}
        visible={isShowModalFail}
        closable={false}
        footer={"확인"}
        width={450}
        onClickFooter={() => {
          setCountNumber(0)
            setShowModalFail(false)
          }}
        onCancel={() => {
          setCountNumber(0)
          setShowModalFail(false)
        }}
      >
       <div className="modal_fail">
          <div>이메일 인증에 실패하였습니다.</div>
          <div>다시 시도해주세요.</div>
       </div>
      </PopUpBase>

        {/* SUCCESSSSS */}
      <PopUpBase
        className={className}
        title={"META 코인 보내기 완료"}
        visible={isShowModalSuccess}
        closable={false}
        footer={"확인"}
        width={450}
        onClickFooter={() => {
          setCountNumber(0)
          setShowModalSuccess(false)
        }}
        onCancel={() => {
          setCountNumber(0)
          setShowModalSuccess(false)
        }}
      >
       <div className="modal_success">
          <div>META 코인 보내기가 완료되었습니다.  </div>
       </div>
      </PopUpBase>
    </div>
  );
});

RewardsSend.propTypes = {
  className: PropTypes.any,
};
export default styled(RewardsSend)`
 

  .warning {
    background: #f55050;
    color: #fff;
    padding: 10px 10px;
    width: 60%;
    margin-bottom: 10px;
  }
  .text_error {
    padding: 10px;
    color: #fff;
    background: #f14343;
    margin-top: 10px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .email_input {
    border: none;
    background: #f5f5f0;
    width: calc(100% - 90px) !important;
  }
  .modal_content {
    padding: 40px 20px;
    .red {
      color: red;
    }
    .input_amount {
      height: 50px;
    }
    .btn_active {
      text-align: right;
      width: 90px;
      cursor: pointer;
      font-weight: 700;
    }
    .modal_title {
      text-align: center;
      padding-bottom: 20px;
    }
    .text_support {
      background: #f5f5f0;
      padding: 10px;
      margin-top: 20px;
    }
  }
  .footer {
    justify-content: center;
    display: flex;
    padding: 0;
    cursor: pointer;
    color: #fff;
    padding: 20px;
    width: 100%;
    text-align: center;
  }
  .title {
    color: #000;
    font-size: 20px;
    
  }
  .valueHeader {
    color: #000;
    font-size: 20px;
    span {
      font-size: 14px;
      color: ${style.color.backgroundGray},
    }
  }
  .coins {
    width: 60%;
    padding: 18px;
    border-radius: 10px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .input_box {
    border-radius: 7px;
    width: 60%;
    padding: 16px 10px;
  }
  .btn_save {
    cursor: pointer;
    width: 60%;
    background: #000;
    color: #fff;
    justify-content: center;
    padding: 15px 10px;
    border-radius: 3px;
  }
  .error {
    background: red;
    width: 60%;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 15px 10px;
    color: #fff;
  }
  .amount_title {
    margin-top: 30px;
    margin-bottom: 10px;
    color: #000;
  }
  .t_free {
    margin-top: 30px;
    margin-bottom: 10px;
    color: #000;
    span {
      color: #ccc;
      font-size: 12px;
    }
  }
  .title_input {
    margin-bottom: 15px;
    color: #000;
    span {
      color: red;
      font-size: 12px;
      padding-left: 10px;
  
    }
  }
  .send_amount {
    width: 60%;
    .ant-input {
      font-size: 20px;
    }
    .input_amount {
      width: calc(100% - 90px);
      border-radius: 7px;
      padding: 16px 10px;
      margin-right: 10px;
    }
    .btn_send {
      cursor: pointer;
      width: 90px;
      padding: 0px 20px;
      border-radius: 7px;
      color: #fff;
      background: #000;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  .modal_fail {
    text-align: center;
    padding: 70px;
  }
  .modal_success {
    text-align: center;
    padding: 70px;
  }
  
`;
