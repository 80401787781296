import React, { memo, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Close from '../../../Detail/Close';
import StandBy from '../../../Detail/StandBy';
import Stop from '../../../Detail/Stop';
import Open from '../../../Detail/Open';

const Campaign = memo(({ className, event, isDetail }) => {
    const renderContent = () => {
        if(event?.status === "draft" || event?.status === "waiting_display") {
            return <StandBy event={event} isDetail={isDetail}/>
        }
        if(event.status === 'completed') {
            return <Close event={event} hash_value={event?.hash_value} isDetail={isDetail}/>
        }

        if(event?.status === "on_going") {
            return <Open event={event} isDetail={isDetail}/>
        }
       
        if(event.status === 'stand_by') {
            if(event?.is_paused) {
                return <Stop event={event} isDetail={isDetail}/>
            } else {
                return <StandBy event={event} isDetail={isDetail}/>
            }
        }
    }
    return (
        <div className={className}>
            <div className="content_1" style={{width: '100%'}}>
                {
                    isDetail ? null : (
                        <div className="header">
                            <div className="title">{event?.title}</div>
                            <div className="desc">{event?.description}</div>
                        </div>
                    )
                }
                {
                    renderContent()
                }
            </div>
        </div>
    );
});

Campaign.propTypes = {
  className: PropTypes.any,
};
export default styled(Campaign)`
    .content_1 {
        font-family: MalgunGothicRegular;
        .header {
            border-bottom: 0px solid #000;
            padding: 10px;
            .title {
                font-size: 20px;
                font-weight: 600;
            }
        }
        
    }
   .bottom {
        background: #000;
        text-align: center;
        padding: 9px;
        color: #fff;
        span {
            font-size: 30px;
        }
   }

   @media print {
        // /* Page Breaks */

        /***Always insert a page break before the element***/
        .pb_before {
            page-break-before: always !important;
        }

        /***Always insert a page break after the element***/
        .pb_after {
            page-break-after: always !important;
        }

        /***Avoid page break before the element (if possible)***/
        .pb_before_avoid {
            page-break-before: avoid !important;
        }

        /***Avoid page break after the element (if possible)***/
        .pb_after_avoid {
            page-break-after: avoid !important;
        }

        /* Avoid page break inside the element (if possible) */
        .pbi_avoid {
            page-break-inside: avoid !important;
        }

        .pb_one_item {
            page-break-inside: avoid !important;
            page-break-after: avoid !important;
            page-break-before: avoid !important;
            border: none;
            width: 100%;
        }
    }

    @media screen {
        /***Always insert a page break before the element***/
        .pb_before {
            page-break-before: always !important;
        }

        /***Always insert a page break after the element***/
        .pb_after {
            page-break-after: always !important;
        }

        /***Avoid page break before the element (if possible)***/
        .pb_before_avoid {
            page-break-before: avoid !important;
        }

        /***Avoid page break after the element (if possible)***/
        .pb_after_avoid {
            page-break-after: avoid !important;
        }

        /* Avoid page break inside the element (if possible) */
        .pbi_avoid {
            page-break-inside: avoid !important;
        }

        .pb_one_item {
            page-break-inside: avoid !important;
            page-break-after: avoid !important;
            page-break-before: avoid !important;
            border: none;
            width: 100%;
        }
    }

`;
