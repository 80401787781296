import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../../utils/ServiceBase";
import { Ui } from "../../../utils/Ui";
import { STATUS_EVENT, REACT_APP_WEB } from "../../../utils/constants";
import PopUpBase from '../../../components/PopUpBase'
import moment from "moment";
import Open from './Open';
import Stop from './Stop';
import Close from './Close';
import StandBy from './StandBy';


const Detail = memo(({ className, hash_value }) => {
  const [itemDetail, setItemDetail] = useState(null)


  const [isShowModalStop, setShowModalStop] = useState(false)
  const [isShowModalStopOK, setShowModalStopOK] = useState(false)

  const [isShowModalClose, setShowModalClose] = useState(false)
  const [isShowModalCloseOK, setShowModalCloseOK] = useState(false)

  const [isShowModalResume, setShowModalResume] = useState(false)
  const [isShowModalResumeSuccess, setShowModalResumeSuccess] = useState(false)

  const getEventDetail = useCallback(async () => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `events/${hash_value}`,
      data: {},
    });
    if (result.hasErrors) {
      // Ui.showErrors(result.errors[0].data.details)
    } else {
      setItemDetail(result.value)
    }
  }, [hash_value]);

  const renderbtnAction = useCallback(
    () => {
      if(!itemDetail) return null;
      if(itemDetail.status === 'waiting_display' || itemDetail.status === 'draft' || itemDetail.status === 'on_going' || (itemDetail.status === 'stand_by' && !itemDetail.is_paused)) { /// STOP
        return <div onClick={() => {
          setShowModalStop(true)
        }} className="b-s-btn btn black">중단</div>
      }
      if(itemDetail.status === 'stopped' || (itemDetail.status === 'stand_by' && itemDetail.is_paused )) {
        return (
          <div className="d-flex">
            <div
              onClick={() => { /// RESUME
                setShowModalResume(true)
              }}
              className="b-s-btn btn white">재진행</div>
            <div onClick={() => {  /// CLOSE
                setShowModalClose(true)
            }} className="b-s-btn btn black">종료</div>
          </div>
        )
      }
      if(itemDetail.status === 'completed') {
        return <div 
          onClick={() => {
            window.open(`${REACT_APP_WEB}event/${hash_value}?view=admin`, "_blank") //to open new page
          }}
          className="b-s-btn btn black">바로가기</div>
      }
    }, [itemDetail]
  )

  const renderStatus = () => {
    if(itemDetail?.status === "stand_by") {
      if(itemDetail?.is_paused) {
        return STATUS_EVENT['stopped']
      } else {
        return STATUS_EVENT['waiting_display']
      }
    }
    return STATUS_EVENT[itemDetail?.status]
  }

  const renderEventType = () => {
    if(itemDetail?.status === "draft" || itemDetail?.status === "waiting_display") {
      return <StandBy event={itemDetail} />
    }
    if(itemDetail?.status === 'stand_by') {
      if(itemDetail?.is_paused) {
        return <Stop event={itemDetail}/>
      } else {
        return <StandBy event={itemDetail} />
      }
    }
    if(itemDetail?.status === "on_going") {
      return <Open event={itemDetail}/>
    }
    if(itemDetail?.status === "completed") {
      return <Close event={itemDetail} hash_value={hash_value}/>
    }
  }
  
  useEffect(() => {
    getEventDetail();
  }, [getEventDetail]);


  if(itemDetail) {
    return (
      <div className={className}>
        <div className="header">
          <div className="d-flex j-c-b a-i-c header1">
            <div className="title">{itemDetail.title}</div>
            <div className="status">{renderStatus()}
              <span>상태입니다.</span>
              <span 
              style={(itemDetail.status === 'draft') ? null : {cursor: 'pointer'}}
              onClick={() => {
                if(itemDetail.status === 'draft') return;
                  window.open(`${REACT_APP_WEB}event/${itemDetail.hash_value}`, "_blank") //to open new page
              }} 
              className="link_open"><i className="fa fa-external-link" aria-hidden="true"></i></span>
            </div>
          </div>
          <div className="d-flex a-i-c j-c-b header2">
            <div className="title_btn">
            </div>
            <div className="d-flex j-c-b">
              {(itemDetail.status === 'draft') ? <div>&nbsp;</div> : renderbtnAction()}
            </div>
          </div>
        </div>
  
        <div className="content1">
            {
              (itemDetail?.status === 'waiting_display') ? null : (
                <div className="header">
                <div className="title">{itemDetail.title}</div>
                <div className="desc">{itemDetail.description}</div>
              </div>
              )
            }
         {
           renderEventType()
         }
        </div>
  
         {/* STOPPPPPPPPPPPPP  ===================*/}
         <PopUpBase
              className={className}
              title={"설문 중단"}
              visible={isShowModalStop}
              closable={true}
              footer={"설문 중단하기"}
              onClickFooter={async () => {
                  const result = await ServiceBase.requestJson({
                    method: "POST",
                    url: `events/${hash_value}/pause`,
                    data: {},
                  });
                  if (result.hasErrors) {
                    await setShowModalStop(false)
                    // Ui.showErrors(result.errors[0].data.details)
                  } else {
                    getEventDetail()
                    await setShowModalStop(false)
                    setShowModalStopOK(true)
                  }
              }}
              onCancel={() => {setShowModalStop(false)}}
              width={460}
          >
              <div className="modal_stop">
                  <div className="title">{itemDetail && itemDetail.title}</div>
                  <div className="">설문을 정말로 중단하시겠습니까?</div>
              </div>
          </PopUpBase>
          {/* StopEDDDDDDD */}
          <PopUpBase
              className={className}
              title={"설문 중단 완료"}
              visible={isShowModalStopOK}
              closable={true}
              footer={"확인"}
              onClickFooter={() => setShowModalStopOK(false)}
              onCancel={() => {setShowModalStopOK(false)}}
              width={460}
          >
            <div className="modal_stop">
                <div className="title">{itemDetail && itemDetail.title}</div>
                <div className="">설문이 중단되었습니다.</div>
            </div>
          </PopUpBase>



            {/*RESSSSSSMUUUUUUU */}
            <PopUpBase
                className={className}
                title={"설문 재진행"}
                visible={isShowModalResume}
                closable={true}
                footer={"설문 재진행하기"}
                onClickFooter={async() => {
                    const result = await ServiceBase.requestJson({
                        method: "POST",
                        url: `events/${hash_value}/resume`,
                        data: {},
                      });
                      if (result.hasErrors) {
                        // Ui.showErrors(result.errors[0].data.details)
                        await setShowModalResume(false)
                      } else {
                        getEventDetail()
                        await setShowModalResume(false)
                        setShowModalResumeSuccess(true)
                      }
                }}
                onCancel={() => {setShowModalResume(false)}}
            >
                <div className="modal_resume">
                    <div className="content_resume">
                        <div className="title">{itemDetail && itemDetail.title}</div>
                        {
                            itemDetail && <div >{`설문을 다시 진행하는 경우 진행 기간은 동일하게 유지됩니다`}</div>
                        }
                        {
                            itemDetail && <div >{`진행기간:  ${itemDetail.start_date ? moment(itemDetail.start_date).format("YYYY.MM.DD") : 'N/A'} - ${itemDetail.end_date ? moment(itemDetail.end_date).format("YYYY.MM.DD") : 'N/A'}`}</div>
                        }
                        <div>설문을 다시 진행하시겠습니까?</div>
                    </div>
                </div>
            </PopUpBase>
            <PopUpBase
                className={className}
                title={"설문 재진행 완료"}
                visible={isShowModalResumeSuccess}
                closable={true}
                footer={"확인"}
                onClickFooter={() => setShowModalResumeSuccess(false)}
                onCancel={() => {setShowModalResumeSuccess(false)}}
                width={460}
            >
                <div className="modal_stop">
                    <div className="title">설문이 다시 진행됩니다.</div>
                </div>
            </PopUpBase>


            {/* CLOSEEEEEEEEEEEE  ===================*/}
            <PopUpBase
              className={className}
              title={"설문 중단"}
              visible={isShowModalClose}
              closable={true}
              footer={"설문 종료하기"}
              onClickFooter={async() => {
                  const result = await ServiceBase.requestJson({
                      method: "POST",
                      url: `events/${hash_value}/close`,
                      data: {},
                    });
                    if (result.hasErrors) {
                      // Ui.showErrors(result.errors[0].data.details)
                    } else {
                      getEventDetail()
                      await setShowModalClose(false)
                      setShowModalCloseOK(true)
                    }
              }}
              onCancel={() => {setShowModalClose(false)}}
              width={460}
          >
              <div className="modal_stop">
                  <div className="title">{itemDetail && itemDetail.title}</div>
                  <div className="">설문을 정말로 종료하시겠습니까?</div>
              </div>
          </PopUpBase>
          {/* CLOSEEEDDDDDDDDDD */}
          <PopUpBase
              className={className}
              title={"설문 종료 완료"}
              visible={isShowModalCloseOK}
              closable={true}
              footer={"확인"}
              onClickFooter={() => setShowModalCloseOK(false)}
              onCancel={() => {setShowModalCloseOK(false)}}
              width={460}
          >
              <div className="modal_stop">
                  <div className="title">{itemDetail && itemDetail.title}</div>
                  <div className="">설문이 종료되었습니다</div>
              </div>
          </PopUpBase>
      </div>
    );
  }
 
});

Detail.propTypes = {
  className: PropTypes.any,
};
export default styled(Detail)`
  .waiting_display {
    text-align: center;
    margin-top: 200px;
    font-size: 18px;
  }
  .header {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    .header1 {
      margin-bottom: 5px;
      .title {
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }
      .status {
        font-size: 17px;
        color: #000;
        span {
          margin-left: 4px;
          font-size: 12px;
          color: #777575;
        }
        .link_open {
         
          color: #000;
          font-size: 20px;
        }
      }
    }
    .header2 {
      .title_btn {
        color: #ccc;
      }
      .btn {
        cursor: pointer;
        margin-left: 20px;
        padding: 7px 66px;
        border-radius: 4px;
      }
      .white {
        background: #fff;
        color: #000;
      }
      .black {
        background: #000;
        color: #fff;
      }
    }
  }
  .content1 {
    margin-top: 10px;
    padding-left: 15px;
    .header {
      border-bottom: 0px solid #000;
      padding: 10px;
      .title {
          font-size: 20px;
          font-weight: 600;
      }
    }
  }



  .modal_stop {
    margin: 40px 0;
    text-align: center;
    .title {
        // color: #000;
        font-size: 20px;
    }
  }
  .modal_resume {
      margin: 20px 0;
      text-align: center;
      .title {
          // color: #000;
          font-size: 20px;
      }
      .content_resume {
          text-align: center;
          margin: 0 30px;
      }
  }
	
`;
