import React, {memo} from "react";
import { Empty } from "antd";
import styled from "styled-components";

const EmptyTable = memo((className) => {
  return (
    <Empty description={"결과가 없습니다."} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
  )
})

export default styled(EmptyTable)`
  
`;
