import React, { useState, useCallback, useEffect, memo, } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import Tab2 from '../SurveyDetail/Result/Tab2';

const Chart = memo(({ className, hash_value }) => {
    if(!hash_value) return null;
    const [event_info, set_event_info] = useState(null)
    const [questions_info, set_questions_info] = useState(null)

    const getEventResult = useCallback(async () => {
      const result = await ServiceBase.requestJson({
        method: "GET",
        url: `events/${hash_value}/statistics`,
        data: {},
      });
      if (result.hasErrors) {
        try {
        } catch (error) {
          
        }
      } else {
        set_event_info(result.value.event_info)
        set_questions_info(result.value.questions_info)
      }
    }, [hash_value]);

    useEffect(() => {
      getEventResult();
    }, [getEventResult]);
	
	return (
		<div className={className}>
      {
        event_info ? <Tab2 event_info={event_info} isDetail/> : null
      }
		</div>
	);
});

Chart.propTypes = {
  className: PropTypes.any,
};
export default (styled(Chart)`

`);

