import React, { useState, useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import ListUser from './ListUser';
import Filter from './Filter';
import PopUpBase from '../../components/PopUpBase'
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';

const AdminSetting = memo(({ className, profile, history }) => {
  const [isShowModalAdd, setShowModalAdd] = useState(false)
  const [isShowModalUpdate, setShowModalUpdate] = useState(false)
  const [isShowModalUpdateSuccess, setShowModalUpdateSuccess] = useState(false)
  const [itemDetail, setItemDetail] = useState(null)

  const [isShowModalError, setShowModalError] = useState(false)
  const [isShowModalSuccess, setShowModalSuccess] = useState(false)

  const [loadding, setLoadding] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [params, setParams] = useState({
    limit: 10,
    offset: 1,
    currentPage: 1,
    is_active: ""
  })

  const getListAccounts = useCallback(async () => {
    setLoadding(true)
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: "accounts",
      data: {
        ...params,
        offset: (params.currentPage - 1) * params.limit,
      },
    });
    if (result.hasErrors) {
     try {
      Ui.showErrors(result.errors[0].data.details)
     } catch (error) {}
    } else {
      setData(result.value.results)
      setTotal(result.value.count)
    }
    setLoadding(false)
  }, [params]);

  const onGetDetail = useCallback(async (id) => {
    const result = await ServiceBase.requestJson({
      method: "GET",
      url: `accounts/${id}`,
      data: {},
    });
    if (result.hasErrors) {
    } else {
      setShowModalUpdate(true)
      setItemDetail(result.value)
    }
  }, []);
  
  
  useEffect(() => {
    getListAccounts();
  }, [getListAccounts]);

  return (
    <div className={className}>
      <div className="d-flex j-c-b a-i-c">
        <div className="btn b-s-btn" onClick={() => {setShowModalAdd(true)}}>
          신규 등록
        </div>
        <Filter data={data} params={params} setParams={setParams} total={total}/>
      </div>
      <ListUser 
        data={data} history={history} 
        loadding={loadding} 
        params={params} setParams={setParams}
        onGetDetail={onGetDetail}
        onRefreshList={getListAccounts}
      />
      {/* /// Create UÊRRRRR */}
      <PopUpBase
        destroyOnClose
        className={className}
        title={"신규 등록"}
        visible={isShowModalAdd}
        closable={true}
        footerNew={<div></div>}
        onClickFooter={() => {}}
        onCancel={() => {setShowModalAdd(false)}}
      >
       <CreateUser 
        onRefreshList={getListAccounts} 
        setShowModalAdd={setShowModalAdd} 
        setShowModalError={setShowModalError}
        setShowModalSuccess={setShowModalSuccess}
      />
      </PopUpBase>


       {/* OnSAVE */}
      <PopUpBase
        width={470}
        className={className}
        title={"신규 등록 실패"}
        visible={isShowModalError}
        closable={false}
        footer={"확인"}
        onClickFooter={() => {setShowModalError(false)}}
      >
       <div className="modal_error">
        <div>이미 등록된 이메일은 추가할 수 없습니다.</div>
       </div>
      </PopUpBase>
      <PopUpBase
       width={470}
        className={className}
        title={"신규 등록 완료"}
        visible={isShowModalSuccess}
        closable={false}
        footer={"확인"}
        onClickFooter={() => {setShowModalSuccess(false)}}
      >
       <div className="modal_success">
        <div>새로운 관리자가 추가되었습니다.</div>
       </div>
      </PopUpBase>

      {/* //// UPDATE USER   */}
      <PopUpBase
        destroyOnClose
        className={className}
        title={"정보 수정 "}
        visible={isShowModalUpdate}
        closable={true}
        footerNew={<div></div>}
        onClickFooter={() => {}}
        onCancel={() => {
          setShowModalUpdate(false);
          setItemDetail(null);
        }}
      >
        <UpdateUser itemDetail={itemDetail}  onRefreshList={getListAccounts} setShowModalUpdate={setShowModalUpdate} setShowModalUpdateSuccess={setShowModalUpdateSuccess}/>
      </PopUpBase>

      <PopUpBase
       width={470}
        className={className}
        title={"정보 수정 완료"}
        visible={isShowModalUpdateSuccess}
        closable={false}
        footer={"확인"}
        onClickFooter={() => {
          setShowModalUpdateSuccess(false)
        }}
      >
       <div className="modal_success">
        <div>관리자 정보가 수정되었습니다.</div>
       </div>
      </PopUpBase>

    </div>
  );
});

AdminSetting.propTypes = {
  className: PropTypes.any,
};
export default styled(AdminSetting)`
  .btn {
    background: #000;
    color: #fff;
    padding: 5px 33px;
    border-radius: 6px;
    cursor: pointer;
  }
  .ant-modal-body {
    padding: 0;
  }
  .modal_error {
    text-align: center;
    padding: 45px 35px;
    font-size: 16px;
  }
  .modal_success {
    text-align: center;
    padding: 45px 35px;
    font-size: 16px;
  }
`;
