import React, {memo} from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children, ...rest }) => {
    const App = useSelector(state => state.App)
    const isAuthenticated = App.get("isAuthenticated")
    return (
        <Route
        {...rest}
        render={({ location }) =>
            isAuthenticated ? (
            children
            ) : (
            <Redirect
                to={{
                    pathname: "/sign-in",
                }}
            />
            )
        }
        />
    );
}

export default PrivateRoute;
