import React, { useState, memo } from "react";
import { Table } from "antd";
import styled from "styled-components";
import PopUpBase from '../../components/PopUpBase'
import PropTypes from "prop-types";
import moment from "moment";
import { NOTIFI_TYPE, TRANSACTION_TYPE, META_BLOCK_EXPLORER_URL } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import {  formatMETA } from "../../utils/helper";
import EmptyTable from '../EmptyTable';

const ListNoti = memo(
    ({ className, data, loadding, params, setParams }) => {
    const [isShowModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null)
    const history = useHistory();
    const columns = [
        {
            title: "제목",
            dataIndex: "template",
            align: 'center',
            render: (value, row) => {
                if(value === 'app_coin_received') {
                    if(row.transaction_history && row.transaction_history.transaction_type === 'receiver_coin') {
                        return <div style={{textAlign: 'left'}}>{`META 코인을 받았습니다.`}</div>
                    } else {
                        return <div style={{textAlign: 'left'}}>{`META 코인을 보냈습니다.`}</div>
                    }
                }
                return <div style={{textAlign: 'left'}}>{`${row.title}`}</div>
            }
        },
        {
            title: "날짜",
            dataIndex: "created_date",
            render: (value) => moment(value).format("YYYY.MM.DD"),
            width: 140,
        },
    ]

    const renderContentModal = () => {
        if(itemSelected && itemSelected.template === 'app_coin_received') { // app_coin_received
            if(itemSelected && itemSelected.transaction_history && 
                itemSelected.transaction_history.transaction_type === 'receiver_coin') { /// RECEVICE_COIN
                return (
                    <div className="modal_content">
                        <div className="d-flex j-c-b">
                            <div className="name">META 코인을 받았습니다.</div>
                            <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                        </div>
                        <div className="content_2">
                            <div className="d-flex">
                                <div className="summary_left">
                                    <p>구분 </p>
                                    <p>수량 </p>
                                    <p className="num_of_2">보낸 주소</p> 
                                    <p>TXID</p>
                                </div>
                                <div className="summary_right">
                                    <p>{TRANSACTION_TYPE[itemSelected.transaction_history.transaction_type]} </p>
                                    <p>{`${formatMETA(itemSelected.transaction_history.value)} META`}</p>
                                    <p className="word-break">{itemSelected.transaction_history.sender}</p>
                                    <div onClick={() => {
                                             window.open(`${META_BLOCK_EXPLORER_URL}tx/${itemSelected.transaction_history.transaction_hash}`, "_blank") //to open new page
                                        }} className="txid word-break">{itemSelected.transaction_history.transaction_hash}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                ) 
            }
            return ( /// SENNDDDD COIN
                <div className="modal_content">
                    <div className="d-flex j-c-b">
                        <div className="name">META 코인을 보냈습니다.</div>
                        <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                    </div>
                    <div className="content_2">
                        <div className="d-flex">
                            <div className="summary_left">
                                <p>구분 </p>
                                <p>수량 </p>
                                <p>수수료</p> 
                                <p>수령 주소</p>
                                <p>TXID</p>
                            </div>
                            <div className="summary_right">
                                <p>{TRANSACTION_TYPE[itemSelected.transaction_history.transaction_type]} </p>
                                <p>{`${formatMETA(itemSelected.transaction_history.value)} META`}</p>
                                <p>{`${formatMETA(itemSelected.transaction_history.tx_fee)} META`}</p>
                                <p className="word-break">{itemSelected.transaction_history.receiver}</p>
                                <div onClick={() => {
                                        window.open(`${META_BLOCK_EXPLORER_URL}tx/${itemSelected.transaction_history.transaction_hash}`, "_blank") //to open new page
                                }} className="txid word-break">{itemSelected.transaction_history.transaction_hash}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if(itemSelected && itemSelected.template === 'web_survey_resumed') {
            return ( // web_survey_resumed
                <div className="modal_content">
                    <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                    <div className="content">
                        <div className="title_notifi">{itemSelected.event && itemSelected.event.title} </div>
                        <div className="title_notifi">{`${NOTIFI_TYPE[itemSelected.template]}.`} </div>
                        <div className="nofi_info pt_10">{`중단이 필요한 경우 상세 페이지에서 설정할 수 있습니다.`}</div>
                    </div>
                </div>
            )
        }
        if(itemSelected && itemSelected.template === 'web_survey_closed') {
            return ( // web_survey_closed
                <div className="modal_content">
                    <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                    <div className="content">
                        <div className="title_notifi">{itemSelected.event && itemSelected.event.title} </div>
                        <div className="title_notifi">{`${NOTIFI_TYPE[itemSelected.template]}.`} </div>
                        <div className="nofi_info pt_10">{`설문 결과를 확인해보세요!`}</div>
                    </div>
                </div>
            )
        }
        if(itemSelected && itemSelected.template === 'web_survey_stopped') {
            return ( // web_survey_stopped
                <div className="modal_content">
                    <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                    <div className="content">
                        <div className="title_notifi">{itemSelected.event && itemSelected.event.title} </div>
                        <div className="title_notifi">{`${NOTIFI_TYPE[itemSelected.template]}.`} </div>
                        <div className="nofi_info pt_10">{`설문 상세 페이지에서 다시 진행할 수 있습니다.`}</div>
                    </div>
                </div>
            )
        }
        if(itemSelected && itemSelected.template === 'web_survey_opened') {
            return ( // web_survey_opened
                <div className="modal_content">
                    <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                    <div className="content">
                        <div className="title_notifi">{itemSelected.event && itemSelected.event.title} </div>
                        <div className="title_notifi">{`${NOTIFI_TYPE[itemSelected.template]}.`} </div>
                        <div className="nofi_info pt_10">{`중단이 필요한 경우 상세 페이지에서 설정할 수 있습니다.`}</div>
                    </div>
                </div>
            )
        }

        if(itemSelected && itemSelected.template === 'web_survey_registered') {
            return (
                <div className="modal_content">
                    <div className="date">{itemSelected && moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                    <div className="content">
                        <div className="title_notifi">{itemSelected.event && itemSelected.event.title} </div>
                        <div className="title_notifi">{`${NOTIFI_TYPE[itemSelected.template]}.`} </div>
                        <div className="nofi_info pt_10">{`${moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")} 설문이 진행될 예정입니다`}</div>
                        <div className="nofi_info">{`설문이 시작되기 전 잘못된 부분이 없는지 확인해보세요.`}</div>
                    </div>
                </div>
            )
        }
        
        else {
            return (
                <div className="modal_content">
                    <div className="date">2020.08.13 15:16</div>
                    <div className="content">
                        <div className="title_notifi">web_survey_approved </div>
                        <div className="title_notifi title_notifi_2">설문이 시작되었습니다.  </div>
                        <div className="nofi_info">중단이 필요한 경우 상세 페이지에서 설정할 수 있습니다.</div>
                    </div>
                </div>
            )
        }
    }

    const renderTextFooter = () => {
        if(itemSelected && 
            (itemSelected.template === 'web_survey_registered' || 
            itemSelected.template === 'web_survey_opened' || 
            itemSelected.template === 'web_survey_stopped' || 
            itemSelected.template === 'web_survey_resumed'
        )) {
            return '설문 상세 페이지 바로가기'
        }
        if(itemSelected && itemSelected.template === 'web_survey_closed') {
            return '설문 결과 바로가기'
        }
        if(itemSelected && itemSelected.template === 'app_coin_received') {
            return '닫기'
        }
        return '설문 상세 페이지 바로가기'
    }

    const renderTitle = () => {
        if(itemSelected && itemSelected.template === 'app_coin_received') {
            return 'META 코인 거래내역'
        }
        return "알림";
    }
    return (
		<div className={className}>
			<Table
                locale={{ emptyText: <EmptyTable/> }}
                loading={loadding}
				onRow={(record, rowIndex) => {
					return {
                        onClick: event => {
                            setItemSelected(record);
                            setShowModal(true)
                        },
					};
				}}
				columns={columns} 
				dataSource={data}
				rowKey="id"
				scroll={{ x: "calc(100%)" }}
				pagination={false}
			/>
            
            <PopUpBase
                width={470}
                className={className}
                title={renderTitle()}
                visible={isShowModal}
                closable={true}
                footer={renderTextFooter()}
                onClickFooter={async () => {
                    await setShowModal(false)
                    if(itemSelected && itemSelected.template === 'web_survey_closed') {
                        history.push({
                            pathname: '/survey-result',
                            search: `?hash_value=${itemSelected.event.hash_value}`,
                            state: { hash_value: itemSelected.event.hash_value }
                        });
                    }
                    if(itemSelected && itemSelected.template === 'web_survey_stopped' || 
                        itemSelected && itemSelected.template === 'web_survey_resumed' || 
                        itemSelected && itemSelected.template === 'web_survey_registered'
                    ) { 
                        history.push({
                            pathname: '/survey-detail',
                            search: `?hash_value=${itemSelected.event.hash_value}`,
                            state: { hash_value: itemSelected.event.hash_value }
                        });
                    }
                    
                }}
                onCancel={() => {setShowModal(false)}}
            >
                {itemSelected && renderContentModal()}
            </PopUpBase>
		</div>
    );
});

ListNoti.propTypes = {
  className: PropTypes.any,
};
export default styled(ListNoti)`
    .ant-table-row {
        cursor: pointer;
    }
    .btn-point {
        cursor: pointer;
        font-weight: bold;
    }
    .btn-point:hover {
        color: #1890ff;
    }
    .ant-modal-body {
        padding: 6px 24px;
        .modal_content {
            .name {
                color: #aeaeb5;
            }
            .date {
                text-align: right;
                color: #aeaeb5;
            }
            .content {
                text-align: center;
                padding: 40px 0;
                .title_notifi_2 {
                    margin-bottom: 12px;
                }
                .title_notifi {
                    font-weight: bold;
                    font-size: 20px;
                }
                .pt_10 {
                    padding-top: 10px;
                }
                .nofi_info {
                    color: #aeaeb5;
                }
            }
            .content_2 {
                padding: 30px;
                .summary_left {
                    padding: 10px 15px;
                    background: #f7f5f5;
                    text-align: right;
                    min-width: 120px;
                    .num_of_2 {
                        padding-bottom: 22px;
                    }
                  }
                  .summary_right {
                    padding: 10px 15px;
                    .word-break {
                        word-break: break-all;
                        white-space: normal;
                        width: 235px;
                    }
                    .txid {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                  }
            }
            
        }
    }
    
`;
