import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import serialize from './serialize';

const Header = memo(({ className, statistics, setParams, params, history, getListEvent }) => {
  const pushStatus = (status) => {
    getListEvent({
      ...params,
      status: status,
      offset: 0,
      currentPage: 1,
    })
    history.push({
      pathname: '/my-workspace',
      search: serialize({
          ...params,
          status: status.includes("") ? '' : status,
          offset: 0,
          currentPage: 1,
      }),
    });
  }
  return (
    <div className={className}>
      <div className="list_status">
        {/* all  Ô 1*/}
        <div 
          onClick={() => {
            let status = params.status;
            if(!status.includes("")) {
              status = [""]
            }
            setParams({...params, 
              status: status,
              offset: 0,
              currentPage: 1,
            })
            pushStatus(status)
          }}
          className="item item_border">
          <div className="item_name">등록된 설문</div>
          <div className="item_value">{statistics.total_registered_surveys}</div>
        </div>
        {/* total_draft_surveys + total_stand_by_surveys Ô 2 */}
        <div 
         onClick={() => {
          let status = params.status;
          if(!status.includes("draft")) {
            status = ["draft"]
          }
          setParams({...params, 
            status: status,
            offset: 0,
            currentPage: 1,
          })
          pushStatus(status)
        }} 
         
        className="item item_border">
          <div className="item_name">승인 전</div>
          <div className="item_value">{statistics.total_draft_surveys}</div>
        </div>

        {/* waiting_display  Ô 3 */}
        <div 
          onClick={() => {
            let status = params.status;
            if(!status.includes("waiting_display")) {
              status = ["waiting_display"]
            }
            setParams({...params, 
              status: status,
              offset: 0,
              currentPage: 1,
            })
            pushStatus(status)
          }} 
        className="item item_border">
          <div className="item_name">대기 중</div>
          <div className="item_value">{statistics.total_waitting_display_surveys + statistics.total_stand_by_surveys}</div>
        </div>


        {/* total_on_going_surveys  Ô 4*/}
        <div 
            onClick={() => {
              let status = params.status;
              if(!status.includes("on_going")) {
                status = ["on_going"]
              }
              setParams({...params, 
                status: status,
                offset: 0,
                currentPage: 1,
              })
              pushStatus(status)
            }} 
        className="item item_border">
          <div className="item_name">진행 중</div> 
          <div className="item_value">{statistics.total_on_going_surveys}</div>
        </div>

         {/* total_stopped_surveys Ô 5 */}
        <div 
          onClick={() => {
            let status = params.status;
            if(!status.includes("stopped")) {
              status = ["stopped"]
            }
            setParams({...params, 
              status: status,
              offset: 0,
              currentPage: 1,
            })
            pushStatus(status)
          }} 
        className="item item_border">
          <div className="item_name">중단</div>
          <div className="item_value">{statistics.total_stopped_surveys}</div>
        </div>

        {/* total_completed_surveys Ô 6 */}
        <div 
          onClick={() => {
            let status = params.status;
            if(!status.includes("completed")) {
              status = ["completed"]
            }
            setParams({...params, 
              status: status,
              offset: 0,
              currentPage: 1,
            })
            pushStatus(status)
          }} 
          className="item">
  
          <div className="item_name">종료</div>
          <div className="item_value">{statistics.total_completed_surveys}</div>
        </div>
      </div>
    </div>
  );
});

Header.propTypes = {
  className: PropTypes.any,
};
export default styled(Header)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  .list_status {
    box-shadow: #000 0px 0px 2px 0px, rgba(9,30,66,0.25) 0px 5px 5px 0px;
    display: flex;
    justify-content: space-around;
    border-radius: 12px;
    padding: 15px 20px;
    width: 80%;
    .item_border {
      border-right: 1px solid #000;
    }
    .item {
      width: 20%;
      cursor: pointer;
      text-align: center;
      color: #000;
      .item_name {
        font-size: 16px;
      }
      .item_value {
        font-size: 30px;
      }
    }
  }
  
`;
