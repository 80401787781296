import React, { memo } from "react";
import { Row, Col, Button, Form, Input } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import Pagination from "../../components/Pagination";
import serialize from './serialize';

let inputTimer = null;

const Filter = memo(({ className, setParams, params, total, data, getListEvent, history }) => {
  const onPushPage = (currentPage, limit) => {
    getListEvent({
      ...params,
      currentPage: currentPage,
      limit: limit
    })
    history.push({
      pathname: '/my-workspace',
      search: serialize({
          ...params,
          currentPage: currentPage,
          limit: limit,
      }),
    });
  }
  return (
    <div className={className}>
      <div className="search">
        <div>검색</div>
        <Input
          value={params.search}
          allowClear
          placeholder="제목으로 검색하세요"
          onChange={e => {
            const value = e.target.value
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.search = value;
              nextState.currentPage = 1;
              return nextState;
            });
            getListEvent({
              ...params,
              search: value,
              offset: 0,
              currentPage: 1,
            })
            history.push({
              pathname: '/my-workspace',
              search: serialize({
                  ...params,
                  search: value,
                  offset: 0,
                  currentPage: 1,
              }),
            });
          }}
          style={{ width: 400, marginLeft: 20 }}
        />
      </div>
      <div className="pagination">
        <Pagination
          onFirstPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              return nextState;
            });
            onPushPage(1, params.limit)
          }}
          onEndPage={() => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = Math.ceil(total / params.limit);
              return nextState;
            });
            onPushPage(Math.ceil(total / params.limit), params.limit)
          }}
          onChangeSize={(pageSize) => {
            setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = 1;
              nextState.limit = pageSize;
              return nextState;
            });
            onPushPage(1, pageSize)
          }}
          onChange={(currentPage) => {
             setParams((prevState) => {
              let nextState = { ...prevState };
              nextState.currentPage = currentPage;
              return nextState;
            });
            onPushPage(currentPage, params.limit)
          }}
          pageSize={params.limit}
          current={params.currentPage}
          total={data.length === 0 ? total + 1 : total}
        />
      </div>
    </div>
  );
});
Filter.propTypes = {
  className: PropTypes.any,
};
export default styled(Filter)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0px 0px 20px 0;
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    .name {
      padding-right: 20px;
    }
  }

`;
