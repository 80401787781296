import React, { useState, useCallback, memo } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as style from '../../../components/Variables';

import {Doughnut, HorizontalBar, Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import _ from "lodash";

import { useLocation } from "react-router-dom";


const dataFix = ['teenager', '20s', '30s', '40s', '50s', 'over_60s', 'other'];


let maxArray4 = 100;

const Tab2 = memo(({ className, event_info, isDetail }) => {
	const location = useLocation();
	const isNotEvent = location.search.includes("?hash_value");
	
	const convertData2 = useCallback(() => { //// OVERVIEWWWWWWWWWWWW
		if(!event_info.statistics) return {}
		const {overview} = event_info.statistics;
		let labels = ['남성', '바로참여'];
		if(Object.keys(event_info.statistics.overview).length === 0) {
			return {}
		}

		try {
			const total_overview = (_.get(overview, 'age.FNSURVEY', 0) + _.get(overview, 'age.THEPOL', 0));
			const legend1 = (_.get(overview, 'age.FNSURVEY', 0) / total_overview);
			const legend2 = (_.get(overview, 'age.THEPOL', 0) / total_overview);
			const data2New = [_.get(overview, 'age.FNSURVEY', 0), _.get(overview, 'age.THEPOL', 0)];
			return {
				labels: labels,
				value1: _.get(overview, 'age.FNSURVEY', 0),
				value2: _.get(overview, 'age.THEPOL', 0),
				legend1: legend1,
				legend2: legend2,
				datasets: [{
					dataTitle: [legend1, legend2],
					data: data2New,
					backgroundColor: [
						'#24DDA6',
						`${style.color.blueDefault}`,
					],
					hoverBackgroundColor: [
						'#24DDA6',
						`${style.color.blueDefault}`,
					]
				}]
			}
		} catch (error) {
			return {}
		}
	}, [event_info.statistics])

	const convertData3 = useCallback(() => { //// GENDERERERERERERERER
		if(Object.keys(event_info.statistics.gender).length === 0) {
			return {}
		}
		const {gender} = event_info.statistics;
		let labels = [];
		let data = [];

		Object.keys(gender).map((item) => {
			if(item !== 'total') {
				labels.push(item)
				data.push((gender[item].FNSURVEY ? gender[item].FNSURVEY : 0) + (gender[item].THEPOL ? gender[item].THEPOL : 0))
			}
		})
		try {
			const total_gender = _.get(gender, 'total.FNSURVEY', 0) + _.get(gender, 'total.THEPOL', 0)
			const legend21 = (_.get(gender, 'female.FNSURVEY', 0) + _.get(gender, 'female.THEPOL', 0)) / total_gender;
			const legend22 = (_.get(gender, 'male.FNSURVEY', 0) + _.get(gender, 'male.THEPOL', 0)) / total_gender;
			const legend23 = gender.other ? (_.get(gender, 'other.FNSURVEY', 0) + _.get(gender, 'other.THEPOL', 0)) / total_gender : 0;
			return {
				value21: _.get(gender, 'female.FNSURVEY', 0) + _.get(gender, 'female.THEPOL', 0),
				legend21: legend21,
				value22: _.get(gender, 'male.FNSURVEY', 0) + _.get(gender, 'male.THEPOL', 0),
				legend22: legend22,
				value23: _.get(gender, 'other.FNSURVEY', 0) + _.get(gender, 'other.THEPOL', 0),
				legend23: legend23, 
				labels: labels,
				datasets: [{
					dataTitle: [legend22, legend23, legend21],
					data: data,
					backgroundColor: [
						`${style.color.blueDefault}`,
						'#F4F4F4',
						'#24DDA6',
					],
					hoverBackgroundColor: [
						`${style.color.blueDefault}`,
						'#F4F4F4',
						'#24DDA6',
					]
				}]
			}
		} catch (error) {
			return {}
		}
	}, [event_info.statistics])

	
	const data2 = convertData2()
	const data3 = convertData3()

	const convertData4 = useCallback(() => {
		if(!event_info.statistics.age) return {}
		const {age} = event_info.statistics;

		let dataChart4 = [];
		let dataChart42 = [];
		let value_list_item = [];
		dataFix.map((item) => {
			const item_value = age[item] ? ((age[item].FNSURVEY ? age[item].FNSURVEY : 0) + (age[item].THEPOL ? age[item].THEPOL : 0)) / (_.get(age, 'total.FNSURVEY', 0) + _.get(age, 'total.THEPOL', 0)) : 0
			dataChart4.push(item_value * 100)
			dataChart42.push(100 - (item_value * 100))
			value_list_item.push(age[item] ? (age[item].FNSURVEY ? age[item].FNSURVEY : 0) + (age[item].THEPOL ? age[item].THEPOL : 0) : 0)
		})
		maxArray4 = (Math.ceil(_.max(dataChart4) / 10) * 10) > 100 ? 100 : Math.ceil(_.max(dataChart4) / 10) * 10
		return {
			labels: ['10대', '20대', '30대', '40대', '50대', '60대 이상', '알수없음'],
			dataChart4: dataChart4,
			value_list_item: value_list_item,
			datasets: [
				{
					label: '',
					backgroundColor: `${style.color.blueDefault}`,
					borderColor: `${style.color.blueDefault}`,
					borderWidth: 1,
					hoverBackgroundColor: `${style.color.blueDefault}`,
					hoverBorderColor: `${style.color.blueDefault}`,
					data: dataChart4,
				},
				{
					label: '',
					backgroundColor: '#F4F4F4',
					borderColor: '#F4F4F4',
					borderWidth: 1,
					hoverBackgroundColor: '#F4F4F4',
					hoverBorderColor: '#F4F4F4',
					data: dataChart42,
				}

			]
		}
	}, [event_info.statistics])



	const data4 = convertData4()
	console.log("data4.dataChart4", data4.dataChart4)
	const {number_of_clicking, participation_count} = event_info;
	const renderChart4 = () => {
		return (
			Object.keys(data4).length > 0 ? (
				<div className="chart_hori">
					<HorizontalBar 
						data={data4} 
						options ={{
							layout: {
								padding: {
									right: 50,
								}
							},
							plugins: {
								datalabels: {
									display: false,
									anchor: 'end',
									align: 'end',
									font: {
										size: isNotEvent ? 13 : 8
									},
									formatter: function(value, context) {
										return context.dataset ? `${context.dataset.value_list_item[context.dataIndex]}명,\n${value.toFixed(2)}%` : '';
									}
								},
							},
							tooltips: {
								enabled: false,
							},
							legend: {
								display: false,
							},
							scales: {
								yAxes: [{
									barPercentage: 0.37,
									ticks: {
										callback: (a) => `${a.replace("s", '대')}   `,
										fontFamily: "MalgunGothicRegular",
									},
									stacked: true,
									gridLines: {
										drawTicks: false,
										display: true,
										drawOnChartArea: false,
									},
								}],
								xAxes: [{
									ticks: {
										max: maxArray4,
										callback: (value) => `${value.toFixed(Number.isInteger(value) ? 0 : 2)}%`,
										beginAtZero: true,
										stepSize: 10,
										fontFamily: "MalgunGothicRegular",
									},
									stacked: true,
									gridLines: {
										display: false
									}
								}]
							},
						}}
					/>
				</div>
			) : null
		)
	}
	const renderSpan = () => {
		if(isDetail) {
			return 24
		}
		return 12;
	}

	return (
		<div className={className}>
		<Row gutter={[16, 16]}>
			<Col className={isDetail ? "" : "col_chart3"} span={renderSpan()}>
				<div style={{ breakBefore: 'auto' }}/>
				<div className="row_item pb_one_item">
					<div className="chart_title" >클릭 대비 참여율</div>
					<div>
						<div className="process" style={isNotEvent ? {marginTop: 40} : {marginTop: 20}}>
							<div className="process_children" style={{width: `${participation_count  !== 0 ? parseInt(participation_count/number_of_clicking * 100) : 0}%`}}/>
						</div>
						<div className="d-flex j-c-b">
							<div style={{color: '#1273e2', fontWeight: 600}}>
								{`${participation_count !== 0 ? (parseInt(participation_count/number_of_clicking * 100)).toFixed(2) : 0}% `} 
									<span style={{fontSize: 11, fontWeight: 400}}>{`(참여 ${parseInt(participation_count)}명)`}</span>
							</div>
							<div style={{color: '#686868'}}>
								{`총 참여자수 ${number_of_clicking}명`}
							</div>
						</div>
					</div>
				</div>
				<div style={{ breakAfter: 'auto' }}/>
			</Col>

			{/* NUMBER 2222 22 2 */}
			<Col span={12} style={{marginTop: isNotEvent ? 0 : 20}}>
				<div style={{ breakBefore: 'auto' }}/>
				<div className="row_item pb_one_item">
					<div className="chart_title" style={isNotEvent ? {} : {fontSize: 14}} >참여방법</div>
					{
						Object.keys(data2).length > 0 ? (
							<div className="chart_wrapper d-flex" style={isDetail ? {} : {alignItems: 'center', marginBottom: 20}}>
								<div className="chart_circle" style={isDetail ? {width: 270, height: 160} : {width: 280, height: 130}}>
									<div style={isDetail ? {position: 'absolute', left: -48, top: 60, width: 270, height: 160} : {}}>
										<Doughnut
											options={{
												elements: {
													arc: {
														borderWidth: 0
													}
												},
												plugins: {
													datalabels: {
														display: true,
														color: '#fff',
														anchor: 'center',
														align: 'center',
														font: {
															size: isNotEvent ? 12 : 9
														},
														formatter: function(value, context) {
															try {
																return context.dataset.dataTitle[context.dataIndex] === 0 ? '' : `${(context.dataset.dataTitle[context.dataIndex] * 100).toFixed(Number.isInteger(context.dataset.dataTitle[context.dataIndex] * 100) ? 0 : 2)}%`
															} catch (error) {
															return ''		
															}
														}
													}
												},
												maintainAspectRatio: true,
												legend: {
													display: false,
												},
												tooltips: {
													enabled: false,
												},
												cutoutPercentage: 40
											}}
											onElementsClick={(e) => {
											}} 
											data={data2}
										/>
									</div>
								</div>	
								<div style={{position: 'relative'}}>
									<div style={isNotEvent ? {position: 'absolute', left: -32, top: -76} : {fontSize: 12, marginTop: 14, marginRight: 30}}>
										<div className="d-flex" style={isNotEvent ? {} : {marginBottom: 10}}>
											<div className="d-flex title_legend_custom" style={{color: `${style.color.blueDefault}`, marginRight: isNotEvent ? 6 : 0}}>
												<div className="legend_custom" style={{background: `${style.color.blueDefault}`, marginTop: isDetail ? 5: 8 }}><div className="chirden_legend"/></div><span>더폴</span>
											</div>
											<div style={{textAlign: 'right', flex: 1}}>
												<div>{`${(data2.legend2 * 100).toFixed(2)}%`}</div>
												<div style={{color: '#B6B6B6'}}>{`${data2.value2}명`}</div>
											</div>
										</div>
										
										<div className="d-flex">
											<div className="d-flex title_legend_custom" style={{color: '#24DDA6', marginRight: isNotEvent ? 6 : 0}}>
												<div className="legend_custom" style={{background: '#24DDA6',  marginTop: isDetail ? 5: 8}}><div className="chirden_legend"/></div><span>바로참여</span>
											</div>
											<div style={{textAlign: 'right', flex: 1}}>
												<div>{`${(data2.legend1 * 100).toFixed(2)}%`}</div>
												<div style={{color: '#B6B6B6'}}>{`${data2.value1}명`}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : null
					}
					
				</div>
				<div style={{ breakAfter: 'auto' }}/>
			</Col>
			{
				isNotEvent ? <div style={{width: '100%', height: 1.2, background: '#F0F0F0'}}/> : null
			}
			{/* 333333333333333 */}
			<Col className={isDetail ? "" : "col_chart3"} span={12} style={{marginTop: isNotEvent ? 0 : 20}}>
				<div style={{ breakBefore: 'auto' }}/>
				<div className="row_item pb_one_item">
					<div className="chart_title" style={isNotEvent ? {} : {fontSize: 14}}>성별</div>
					<div className="chart_3" style={isDetail ? {} : {height: '100%'}}>
						{
						Object.keys(data3).length > 0 ? (
							<div className="chart_wrapper d-flex" style={isDetail ? {} : {alignItems: 'center'}}>
								<div className="chart_circle" style={isDetail ? {width: 270, height: 160} : {width: 280, height: 130}}>
									<div style={isDetail ? {position: 'absolute', left: -48, top: 60, width: 270, height: 160} : {}}>
										<Doughnut
											options={{
												elements: {
													arc: {
														borderWidth: 0
													}
												},
												plugins: {
													datalabels: {
														display: true,
														color: function(context) {
															var index = context.dataIndex;
															return index === 1 ? '#A4A4A4' : '#fff';
														},
														anchor: 'center',
														align: 'center',
														font: {
															size: isNotEvent ? 10 : 9
														},
														formatter: function(value, context) {
															try {
																return `${(context.dataset.dataTitle[context.dataIndex] * 100).toFixed(Number.isInteger(context.dataset.dataTitle[context.dataIndex] * 100) ? 0 : 2)}%`
															} catch (error) {
															return ''		
															}
														}
													}
												},
												maintainAspectRatio: true,
												legend: {
													display: false,
												},
												tooltips: {
													enabled: false,
												},
												cutoutPercentage: 40
											}}
											onElementsClick={(e) => {
											}} 
											data={data3}
										/>
									</div>
								</div>

								<div style={{position: 'relative'}}>
									<div style={isNotEvent ? {position: 'absolute', left: -32, top: -76} : {fontSize: 12, marginTop: 14, marginRight: 24}}>
										<div className="d-flex" >
											<div className="d-flex title_legend_custom" style={{color: `${style.color.blueDefault}`, marginRight: isNotEvent ? 6 : 0}}>
												<div className="legend_custom" style={{background: `${style.color.blueDefault}`,  marginTop: isDetail ? 5: 8}}>
													<div className="chirden_legend"/>
												</div>
												<span>남성</span>
											</div>
											<div style={{textAlign: 'right', flex: 1}}>
												<div>{`${(data3.legend22 * 100).toFixed(2)}%`}</div>
												<div style={{color: '#B6B6B6'}}>{`${data3.value22}명`}</div>
											</div>
										</div>
										<div className="d-flex" style={isNotEvent ? {} : {marginBottom: 10, marginTop: 10}}>
											<div className="d-flex title_legend_custom" style={{color: '#24DDA6', marginRight: isNotEvent ? 6 : 0}}>
												<div className="legend_custom" style={{background: '#24DDA6',  marginTop: isDetail ? 5: 8}}><div className="chirden_legend"/></div><span>여성</span>
											</div>
											<div style={{textAlign: 'right', flex: 1}}>
												<div>{`${(data3.legend21 * 100).toFixed(2)}%`}</div>
												<div style={{color: '#B6B6B6'}}>{`${data3.value21}명`}</div>
											</div>
										</div>
										<div className="d-flex">
											<div className="d-flex title_legend_custom" style={{color: '#A4A4A4', marginRight: isNotEvent ? 6 : 0}}>
												<div className="legend_custom" style={{background: '#F4F4F4',  marginTop: isDetail ? 5: 8}}><div className="chirden_legend"/></div><span>알수없음</span>
											</div>
											<div style={{textAlign: 'right', flex: 1}}>
												<div>{`${(data3.legend23 * 100).toFixed(2)}%`}</div>
												<div style={{color: '#B6B6B6'}}>{`${data3.value23}명`}</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						) : null
					}
					</div>
				</div>
				<div style={{ breakAfter: 'auto' }}/>
			</Col>
			{/* 44444444444444 */} 
			<Col span={renderSpan()}>
				<div style={{ breakBefore: 'auto' }}/>
				<div className="row_item pb_one_item">
					<div className="chart_title" style={{marginBottom: 10}}>연령</div>
					{
						isDetail ? (
							<div className="d-flex" style={isNotEvent ? {justifyContent: 'center',} : {}}>
								<div style={{ position: 'absolute', left: isNotEvent ? 10 : 0,
									height: isNotEvent ? 350 : 400, width: isNotEvent ? 470 : 550}}>
									{renderChart4()}
								</div>
								<div style={{height: '100%', width: '100%', alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end'}}>
									<div style={{marginTop: 5, width: 150, marginBottom: isNotEvent ? 50 : 0}}>
										{
											data4.dataChart4.map((item, index) => (
												<div className="item_chart_report" key={index} style={isDetail ? {minWidth: 64, fontSize: 13, height: 34} : {height: 29, fontSize: 11.5}}>
													<div style={{width: 60, textAlign: 'right'}}> {item.toFixed(2)}% &nbsp;</div>
													<div style={{color: '#B0B0B0', textAlign: 'left'}}>{` (${data4.value_list_item[index]}명)`}</div>
												</div>
											))
										}
									</div>
								</div>
							</div>
						) : (
							<div className="d-flex" style={{justifyContent: 'center', position: 'relative', marginBottom: 260, marginLeft: 40}}>
								<div style={{ position: 'absolute', height: 420, width: 620}}>
									<div className="d-flex" >
										<div style={{height: 350, width: 470}}>{renderChart4()}</div>
										<div style={{marginTop: 5, width: 150, marginBottom: isNotEvent ? 50 : 0, position: 'absolute', left: 423}}>
											{
												data4.dataChart4.map((item, index) => (
													<div className="item_chart_report" key={index} style={isDetail ? {minWidth: 64, fontSize: 13, height: 34} : {height: 28.5, fontSize: 11.5}}>
														<div style={{width: 60, textAlign: 'right'}}> {item.toFixed(2)}% &nbsp;</div>
														<div style={{color: '#B0B0B0', textAlign: 'left'}}>{` (${data4.value_list_item[index]}명)`}</div>
													</div>
												))
											}
										</div>
									</div>
								</div>

							</div>
						)
					}
					
				</div>
				<div style={{ breakAfter: 'auto' }}/>
			</Col>
			{
				isNotEvent ? <div style={{width: '100%', height: 1.2, background: '#F0F0F0'}}/> : null
			}
		</Row>
		</div>
	);
});

Tab2.propTypes = {
  className: PropTypes.any,
};
export default styled(Tab2)`
	font-family: MalgunGothicRegular;
	.item_chart_report {
		justify-content: flex-start;
		align-items: center;
		display: flex;
	}
	.title_legend_custom {
		min-width: 76px;
	}
	.col_chart3 {
		border-right: 1.5px solid #F0F0F0; 
	}
	.a-i-c {
		align-items: center
	}
	.chart_title {
		font-weight: bold;
	}
	.legend_custom {
		margin-right: 3px;
		width: 10px;
		height: 10px;
		border-radius: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		.chirden_legend {
			// width: 10px;
			// height: 10px;
			// border-radius: 5px;
			// background: #fff;
		}
	}
	.chart_3 {
		justify-content: center;
		display: flex;
		flex-direction: column;
	}
	.row_item {
		border: 1px solid;
		padding: 10px;
		height: 100%;
		
	}
	.process {
		margin-bottom: 10px;
		height: 38px;
   		width: 100%;
		position: relative;
		background: #F4F4F4;
		.process_children {
			position: absolute;
			background: ${style.color.blueDefault};
			height: 100%;
		}
	}
	.chart_wrapper {
		margin: 10px 0;
		justify-content: center;
		.chart_circle {
			height: 200px;
			justify-content: center;
			width: 400px;
			align-items: center;
			display: flex;
			.chartjs-size-monitor {
				justify-content: center;
				align-items: center;
				display: flex !important;
			}
		}

	}
	.chart_hori {
		font-family: MalgunGothicRegular;
	}
	


	@media print {
		// /* Page Breaks */

		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
  	}
 
	@media screen {
		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
	}
`;


// phần đen đậm là thepol (1), đen nhạt là fnsurvey (9)

