import React, { useState, useRef } from "react"
import { Row, Col } from 'antd'
import ReactPlayer from 'react-player'
import styled from "styled-components";
import OpenDetail from './OpenDetail';
import Pagination from './Pagination';
import { useLocation } from "react-router-dom";

 function OpenDescMulti({ event, className }) {
    const list_question = event.questions ? ['open_detail',...event.questions] : []
    const [list_answer_select, setListAnswerSelect] = useState(Array(list_question.length).fill(null))
    const [position, setPosition] = useState(0)
    const question = list_question[position]
    const [index_playing, setIndexPlaying] = useState(null);

    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");

    const renderQuestionText = () => {
        return <>
            <div style={{ fontSize: isNotEvent ? 14 : 12, marginTop: 10 }}>
                <b>{question.content}</b>
            </div>
            <div style={{ padding: '16px 0px', fontSize: isNotEvent ? 13 : 11 }}>
                {
                    question.answers.map((item, idx) => {
                        return <div style={{
                            borderRadius: 8, backgroundColor: '#fcfcfc', marginTop: 16,
                            border: list_answer_select[position] === item.hash_value ? 'solid 1px #1273e2' : 'solid 1px #ededed'
                        }}
                            className='d-flex flex-row noselect' key={idx} onClick={() => {
                                list_answer_select[position] = item.hash_value
                                setListAnswerSelect([...list_answer_select])
                            }}>
                            <div className='d-flex justify-content-center align-items-center'
                                style={{
                                    width: 50, borderTopLeftRadius: 7, borderBottomLeftRadius: 7,
                                    color: list_answer_select[position] === item.hash_value ? 'white' : '#777777',
                                    backgroundColor: list_answer_select[position] === item.hash_value ? '#1273e2' : '#fcfcfc',
                                    borderRight: list_answer_select[position] === item.hash_value ? 'none' : 'solid 1px #ededed'
                                }}>
                                <b>{idx < 10 ? `0${idx + 1}` : idx + 1}</b>
                            </div>
                            <div style={{
                                padding: '24px 16px', flex: 1,
                                fontWeight: list_answer_select[position] === item.hash_value ? 'bold' : 'normal'
                            }}>{item.content}</div>
                        </div>
                    })
                }
            </div>
        </>
    }

    const renderQuestionImage = () => {
        return <>
            <div style={{ fontSize: isNotEvent ? 14 : 12, marginTop: 10 }}>
                <b>{question.content}</b>
            </div>
            <div style={{ padding: '16px 0px' }}>
                <Row gutter={[{ xs: 8, sm: 16, md: 32 }, { xs: 8, sm: 16, md: 32 }]}>
                    {
                        question.answers.map((item, idx) => {
                            return <Col xs={12} sm={8} lg={6} key={idx}>
                                <div style={{ position: 'relative', paddingTop: '75%' }} className='noselect' onClick={() => {
                                    list_answer_select[position] = item.hash_value
                                    setListAnswerSelect([...list_answer_select])
                                }}>
                                    <img src={item.content}
                                        width='100%' height='100%' className='image-antd'
                                        style={{
                                            width: '100%', height: '100%', borderRadius: 8, position: 'absolute', top: 0, left: 0, objectFit: 'cover',
                                            border: list_answer_select[position] === item.hash_value ? 'solid 2px #1273e2' : 'solid 1px #ededed'
                                        }} />
                                    <div style={{
                                        position: 'absolute', top: 1, left: 1, width: 32, height: 32,
                                        borderTopLeftRadius: 6,
                                        backgroundColor: list_answer_select[position] === item.hash_value ? '#1273e2' : 'white',
                                        textAlign: 'center'
                                    }}>
                                        <b style={{
                                            color: list_answer_select[position] === item.hash_value ? 'white' : '#111111',
                                            fontSize: isNotEvent ? 13 : 11, lineHeight: '30px'
                                        }}>{idx < 10 ? `0${idx + 1}` : idx + 1}</b>
                                    </div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </>
    }

    const renderQuestionVideo = () => {
        return <>
            <div style={{ fontSize: isNotEvent ? 14 : 12, marginTop: 10 }}>
                <b>{question.content}</b>
            </div>
            <div style={{ padding: '16px 0px' }}>
                <Row gutter={[{ xs: 8, sm: 16, md: 32 }, { xs: 8, sm: 16, md: 32 }]}>
                    {
                        question.answers.map((item, idx) => {
                            return <Col xs={12} sm={8} lg={6} key={idx}>
                                <div id={list_answer_select[position] === item.hash_value ? 'outer-video-active' : 'outer-video-deactive'}
                                    onClick={() => {
                                        list_answer_select[position] = item.hash_value
                                        setListAnswerSelect([...list_answer_select])
                                    }} className='noselect'>
                                    <ReactPlayer
                                        url={item.content}
                                        playing={index_playing === idx ? true : false}
                                        width='100%'
                                        height='100%'
                                        style={{ borderRadius: 6, position: 'absolute', top: 0, left: 0 }}
                                        controls={false}
                                        loop={true}
                                        onEnded={() => setIndexPlaying(null)}
                                    />
                                    <div style={{
                                        position: 'absolute', top: 0, left: 0, width: 32, height: 32, textAlign: 'center',
                                        backgroundColor: list_answer_select[position] === item.hash_value ? '#1273e2' : 'white'
                                    }}>
                                        <b style={{
                                            color: list_answer_select[position] === item.hash_value ? 'white' : '#111111',
                                            fontSize: isNotEvent ? 13 : 11, lineHeight: '30px'
                                        }}>{idx < 10 ? `0${idx + 1}` : idx + 1}</b>
                                    </div>
                                </div>
                                <div id='outer-play-button' onClick={() => {
                                    if (index_playing === idx) setIndexPlaying(null)
                                    else setIndexPlaying(idx)
                                }}
                                    className='d-flex flex-row justify-content-center align-items-center noselect'>
                                    <img src={index_playing === idx ? '/icons/icon_play_select.png' : '/icons/icon_play.png'} style={{ width: 10, height: 14 }} />
                                    <div style={{ marginLeft: 10, color: index_playing === idx ? 'rgb(18,115,226)' : 'rgb(67,67,67)' }}>Play</div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </>
    }
    return (
        <div className={className}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Pagination
                    onFirstPage={() => {
                        setPosition(0)
                    }}
                    onEndPage={() => {
                        setPosition(list_question.length - 1)
                    }}
                
                    onChange={(currentPage) => {
                        setPosition(currentPage - 1)
                    }}
                    current={position + 1}
                    total={list_question.length}
                />
            </div>
            <div className='d-flex flex-column' style={{ minHeight: '100vh', backgroundColor: '#F8F8F8' }}>
                <div className='flex-fill d-flex flex-column'>
                    <div className='d-none d-sm-block'>
                        <div className='d-flex flex-column justify-content-center' style={{ backgroundColor: '#F8F8F8', padding: '0 40px', margin: '10px 0' }}>
                            <div style={{ padding: 20, backgroundColor: 'white', fontSize: 13 }}>
                               {
                                   position !== 0 ? (
                                    <div className='d-flex flex-row' style={{ fontSize: 13 }}>
                                        <b style={{ color: '#1273e2' }}>Q{position}</b>
                                        <div style={{ marginLeft: 4 }}>/ {list_question.length - 1}</div>
                                    </div>
                                   ) : null
                               }
                                {
                                    position !== 0 ? question.answer_type === 'text' ? renderQuestionText() : (
                                        question.answer_type === 'image' ? renderQuestionImage() : renderQuestionVideo()
                                    ) : <div>
                                        <OpenDetail event={event}/>
                                    </div>
                                }
                            </div>
                            <div style={{ width: '100%', height: 1, backgroundColor: '#e6e6e6' }}></div>

                            {
                                position === list_question.length - 1 ? <div className='d-flex flex-row justify-content-center align-items-center'
                                    style={{ backgroundColor: '#fae7e7', borderRadius: 8, padding: 11, marginTop: 16, alignItems: 'center' }}>
                                    <img src='/icons/icon_alert.svg' style={{ width: 12, height: 12 }} />
                                    <div style={{ marginLeft: 8, fontSize: 13 }}>
                                        더폴 또는 바로참여하기를 통해 설문을 마무리해주세요.
                                        </div>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default styled(OpenDescMulti)`
    .noselect {
        align-items: center;
    }
    
`;
  