import React, { useState, useCallback, useEffect, memo } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import Countdown from 'react-countdown';
const TimeDown = memo(({ className, setIsSend, onSendEmail, setShowModalSendEmail, setShowModalFail }) => {
  const [time, setTime] = useState(Date.now() + 179000);
  const renderer = useCallback(({ hours, minutes, seconds, completed }) => {
    if(completed) {
      setIsSend(false)
      setShowModalSendEmail(false)
      setShowModalFail(true)
    }
    return (
      <div className="viewTime">
        <div>{minutes.toString().length === 1 ? `0${minutes}` : minutes}:{seconds.toString().length === 1 ? `0${seconds}` : seconds}</div>
        {
          ((minutes  === 2 && seconds < 30) || (minutes  === 1 && seconds <= 59) || (minutes  === 0 && seconds <= 59)) && <div style={{cursor: 'pointer'}} onClick={() => {
            onSendEmail();
            setTime(Date.now() + 179000)
          }} className="red btn_re_send">재요청</div>
        }
        
      </div>
    );
  });
  return (
    <div>
        <Countdown
            date={time}
            renderer={renderer}
            />
    </div>
  );
});

TimeDown.propTypes = {
  className: PropTypes.any,
};
export default styled(TimeDown)`
  .viewTime {
    text-align: right;
    min-width: 90px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .btn_re_send {
    cursor: pointer;
  }
`;
