import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import OpenDetail from '../../SurveyDetail/Detail/OpenDetail';
import OpenDescYesNo from '../../SurveyDetail/Detail/OpenDescYesNo';
import Detail from '../../SurveyDetail/Result/Tab1/SurveyYesNo/Detail';
import StaticYesNo from '../../SurveyDetail/Result/Tab1/SurveyYesNo/StaticYesNo';

const SurveyYesNo = memo(({ className, event, event_info, questions_info }) => {
  return (
    <div className={className}>
      <div style={{width: '100%'}}> 
        <OpenDetail className={className} isDetail event={event}/>
      </div>
      {
        questions_info && questions_info[0] ? (
          <div className="content">
            <div className="pb_before">
              <div className="title" style={{fontSize: 14, fontFamily: 'NotoSansCJKkr'}}>답변 통계 (요약)</div>
              <div className="header_black" style={{fontSize: 12, fontWeight: 'bold', marginBottom: 20}}><span style={{fontFamily: 'NotoSansCJKkr'}}>1.</span>&nbsp;&nbsp;&nbsp;{`${questions_info[0].content}`}</div>
              <StaticYesNo className={className} question_info={questions_info && questions_info[0]} event_info={event_info}/>
            </div>
			<div style={{marginTop: 38}}/>
            <Detail className={className} question_info={questions_info && questions_info[0]} event_info={event_info}/>
          </div>
        ) : null
      }
    </div>
  );
});

SurveyYesNo.propTypes = {
  className: PropTypes.any,
};
export default styled(SurveyYesNo)`
  width: 100%;
  .content {
    width: 100%;
  }
 
  .title {
    margin: 20px 0px;
	font-weight: 700;
  }
  .header_black {
	margin-left: 20px;
	font-weight: 500;
    padding: 4 10px;
    display: flex;
  }




  @media print {
		// /* Page Breaks */

		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
  	}
 
	@media screen {
		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
	}
`;
