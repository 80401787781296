import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from 'lodash';
import ItemBaseNew from '../ItemBaseNew';

const StaticYesNo = memo(({ className, event_info, question_info }) => {
    const renderContent = () => {
        let question_answers_new = _.values(question_info.answers);
        if(question_info?.statistics?.overview?.age) {
            let total_count = Object.values(question_info?.statistics?.overview?.age?.total).reduce((a, b) => a + b, 0)
            question_answers_new.map(answer => {
                let array_count = Object.values(question_info?.statistics?.overview?.age[answer.hash_value])
                answer.count = array_count.reduce((a, b) => a + b, 0)
                answer.percent = (answer.count / total_count) * 100
            })

            let temp_question = JSON.parse(JSON.stringify(question_answers_new))
            temp_question.sort((a, b) => { return b.count - a.count })
            let max_count = 0
            try {
                max_count = temp_question[0].count
                question_answers_new.map(answer => {
                    if (answer.count === max_count) answer.is_max = true
                    else answer.is_max = false
                })
            } catch (error) {
            }

            return <div className="sum_content pb_one_item">
                {
                    question_answers_new.map((item, index) => (
                        <ItemBaseNew key={index} index={index}
                            is_max={item?.is_max}
                            content={item?.content || ''}
                            percent={item?.percent || 0}
                            count={item?.count || 0}
                        />
                    ))
                }
            </div>
        } else {
            return (
                <div className="sum_content pb_one_item">
                    {
                        question_answers_new.map((item, index) => (
                            <ItemBaseNew key={index} index={index}
                                content={item?.content || ''}
                                percent={item?.percent || 0}
                                count={item?.count || 0}
                            />
                        ))
                    }
                </div>
            )
        }
    }
    return (
        <div className={className}>
         {renderContent()}
        </div>
    );
});

StaticYesNo.propTypes = {
  className: PropTypes.any,
};
export default styled(StaticYesNo)`
    .sum_content {
        margin-top: 10px;
        padding-left: 60px;
    }
   
`;
