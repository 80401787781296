import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Campaign from './Campaign';
import SurveyMultiple from './SurveyMultiple';
import SurveyYesNo from './SurveyYesNo';


const Tab1 = memo(({ className, event_info, questions_info, detail_event }) => {
    if(event_info.event_type === 'survey_multiple') { // acb17f72-93e2-411f-88dc-d98eec3134ff   57a133de-a304-430f-b3d1-9c7f8e77f8c3
      return <SurveyMultiple event_info={event_info} questions_info={questions_info}/>
    }
    if(event_info.event_type === 'campaign') { // b4e8c680-883c-4738-9dbc-4229d15bf4eb
      return detail_event ? <Campaign event={detail_event}/> : null
    }
    if(event_info.event_type === 'survey_yes_no') { //259e7b8d-7a12-4571-8475-fc69f572e5cb
      if(questions_info && questions_info[0]) {
        return <SurveyYesNo event_info={event_info} question_info={questions_info && questions_info[0]}/>
      }
    }
    return (
        <div className={className}>
       
        </div>
    );
});

Tab1.propTypes = {
  className: PropTypes.any,
};
export default styled(Tab1)`
    position: relative;
    font-family: MalgunGothicRegular;
    .header_black {
      background: #000;
      padding: 10px;
      justify-content: space-between;
      display: flex;
      .title_header {
        font-size: 20px;
        color: #fff;
      }
      .view_btn {
        color: #fff;
        display: flex;
        .btn {
          min-width: 83px;
          cursor: pointer;
          margin: 0 5px;
          border: 1px solid;
          text-align: center;
          justify-content: center;
          align-items: center;
          background: #ffff;
          border-radius: 10px;
          padding: 2px 17px;
          color: #000;
          display: flex;
        }
      }
    }
  
    .content1 {
      margin-top: 10px;
      iframe {
        width: 100%;
        height: 1000px;
      }
    }
    .tab1_content {
        padding: 10px;
    }
    .btn_save {
        position: absolute;
        bottom: 0;
        height: 52px;
        background: #000;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
    }
`;
