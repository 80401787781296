import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import OpenDescMutiList from './OpenDescMutiList'

import OpenDetail from '../../SurveyDetail/Detail/OpenDetail';

import DetailSurveyText from '../../SurveyDetail/Result/Tab1/SurveyMultiple/SurveyText/DetailSurveyText';
import StaticSurveyText from '../../SurveyDetail/Result/Tab1/SurveyMultiple/SurveyText/StaticSurveyText';

import DetailSurveyImage from '../../SurveyDetail/Result/Tab1/SurveyMultiple/SurveyImage/DetailSurveyImage';
import StaticSurveyImage from '../../SurveyDetail/Result/Tab1/SurveyMultiple/SurveyImage/StaticSurveyImage';

const SurveyMultiple = memo(({ className, event, questions_info }) => {
  const location = useLocation();
  const isNotEvent = location.search.includes("?hash_value");

  return (
    <div className={className}>
       <OpenDetail event={event} isDetail/>
       <div className="pb_before"/>
       <OpenDescMutiList className={className} event={event}/>
       <div className="pb_before"/>
       {
            questions_info && questions_info.map((item_question, index) => {
                if(item_question.answer_type === 'text') {
                    return (
                      <div key={index} style={{width: '100%'}}>
                          <div style={{ breakBefore: 'auto' }}/>
                          <div className="pb_one_item">
                            <div className="title" style={isNotEvent ? {} : {fontSize: 14, fontFamily: 'NotoSansCJKkr'}}>답변 통계 (요약)</div>
                              <div className="header_black" style={isNotEvent ? {} : {fontSize: 12}}><span style={{fontFamily: 'NotoSansCJKkr'}}>{`${index + 1}.`}</span>{` ${item_question.content}?`}</div>
                              <div className="static_survey">
                                <StaticSurveyText className={className} item_question={item_question}/> 
                              </div>
                          </div>
                          <div style={{ breakAfter: 'auto' }}/>
                          <div style={{marginTop: 46}}/>
                          <DetailSurveyText className={className} answer_type={item_question.answer_type} item_question={item_question} indexParent={index}/>
                      </div>
                    )
                } else {
                    return (
                      <div key={index} style={{width: '100%', paddingRight: 60}}>
                        <div style={{ breakBefore: 'auto' }}/>
                            <div className="pb_one_item">
                                <div className="title" style={isNotEvent ? {} : {fontSize: 14, fontFamily: 'NotoSansCJKkr'}}>답변 통계 (요약)</div>
                                <div className="header_black" style={isNotEvent ? {} : {fontSize: 12}}><span style={{fontFamily: 'NotoSansCJKkr'}}>{`${index + 1}.`}</span>{` ${item_question.content}?`}</div>
                                <div className="static_survey">
                                  <StaticSurveyImage className={className} answer_type={item_question.answer_type} item_question={item_question}/>
                                </div> 
                            </div>
                        <div style={{ breakAfter: 'auto' }}/>
                        <DetailSurveyImage className={className} answer_type={item_question.answer_type} item_question={item_question} indexParent={index}/>
                      </div>
                    )
                }
            })
        }
    </div>
  );
});

SurveyMultiple.propTypes = {
  className: PropTypes.any,
};
export default styled(SurveyMultiple)`
  width: 100%;
  .title {
    font-weight: bold;
    margin: 10px 0;
  }
  .header_black {
    font-weight: 600;
    margin: 0px 10px 0px 10px;
    padding: 20px 20px 10px 20px;
    font-size: 15px;
    span {
    }
  }
  .static_survey {
  }
  @media print {
		// /* Page Breaks */

		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
  	}
 
	@media screen {
		/***Always insert a page break before the element***/
		.pb_before {
			page-break-before: always !important;
		}

		/***Always insert a page break after the element***/
		.pb_after {
			page-break-after: always !important;
		}

		/***Avoid page break before the element (if possible)***/
		.pb_before_avoid {
			page-break-before: avoid !important;
		}

		/***Avoid page break after the element (if possible)***/
		.pb_after_avoid {
			page-break-after: avoid !important;
		}

		/* Avoid page break inside the element (if possible) */
		.pbi_avoid {
			page-break-inside: avoid !important;
		}

		.pb_one_item {
			page-break-inside: avoid !important;
			page-break-after: avoid !important;
			page-break-before: avoid !important;
			border: none;
			width: 100%;
		}
	}
`;
