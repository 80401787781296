import React, { memo } from "react";
import { Pagination, Select, Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
const {Option} = Select;

const Page = memo(({ className, total, onFirstPage, onEndPage, current, pageSize, onChangeSize, onChange }) => {
	return (
		<div className={className}>
			<div className="pagination">
				<div className="name">보기 옵션</div>
						<Select
							onChange={onChangeSize}
							className="pageSize" value={pageSize} style={{ width: 100 }}>
							<Option value={10}>10 개씩</Option>
							<Option value={25}>25 개씩</Option>
							<Option value={50}>50 개씩</Option>
							<Option value={100}>100 개씩</Option>
						</Select>
						<Button onClick={onFirstPage} disabled={current === 1}
							className={current === 1 ? "btn_more color_disable" : "btn_more"}><DoubleLeftOutlined /> 
						</Button>
						<Pagination
							onChange={onChange}
							total={total}
							pageSize={pageSize}
							current={current}
							showSizeChanger={false}
						/>
						<Button onClick={onEndPage} disabled={current >= Math.ceil(total / pageSize)}
							className={current === total ? "btn_more color_disable" : "btn_more"}><DoubleRightOutlined />
						</Button>
			</div>
		</div>
  );
});
Page.propTypes = {
  className: PropTypes.any,
};
export default styled(Page)`
	display: flex;
	.btn_more {
		display: flex;
    justify-content: center;
    align-items: center;
		width: 33px;
		height: 32px;
		line-height: 30px;
		text-align: center;
		background-color: #fff;
    border: 1px solid #d9d9d9;
		border-radius: 2px;
		cursor: pointer;
	}
	.color_disable {
		color: #cccc;
	}
  .pagination {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    .name {
      padding-right: 20px;
		}
		li {
			margin: 0;
		}
		.pageSize {
			margin-right: 10px;
		}
		.ant-pagination-item-link {
			width: 32px;
			height: 32px;
			.ant-pagination-item-container {
				width: 100%;
				height: 100%;
			}
			.ant-pagination-item-ellipsis {
				opacity: 1;
			}
		}
		
	}
	span.anticon.anticon-left {
        display: block;
    }
    span.anticon.anticon-right {
        display: block;
    }
	span.anticon.anticon-double-left.ant-pagination-item-link-icon {
    	display: none;
	}
	span.anticon.anticon-double-right.ant-pagination-item-link-icon {
    	display: none;
  	}
`;
