import React, { useState, useCallback, useEffect, memo, } from "react";
import { Input, Form } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux'
import ServiceBase from "../../utils/ServiceBase";
import { Ui } from "../../utils/Ui";
import PopUpBase from '../../components/PopUpBase'

const ChangePassWord = memo(({ className, profile, onChangePassWord, textWarinng }) => {
	const [form] = Form.useForm();

	return (
		<div className={className}>
		    <Form
                onFinishFailed={() => {}}
                onFinish={onChangePassWord}
                name="control-hooks"
                
                form={form}
            >
                <div className="modal_change">
                    <div className="modal_content">
                        <div className="header_modal d-flex">
                            <div className="left">
                                <div className="title_left">현재 비밀번호</div>
                                <div className="title_left">변경 비밀번호</div>
                            </div>
                            <div className="input_pw">
                                <Form.Item 
                                    rules={[{ required: true, message: '현재 비밀번호를 입력하세요.' }]}
                                    name="old_password">
                                    <Input.Password style={{height: 40, width: '100%', marginBottom: 10}} placeholder="현재 비밀번호를 입력하세요."/>
                                </Form.Item>
                                <Form.Item 
                                    rules={[{ required: true, message: '변경할 비밀번호를 입력하세요' }]}
                                    name="new_password">
                                    <Input.Password style={{height: 40, width: '100%'}} placeholder="변경할 비밀번호를 입력하세요."/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="desc">
                            비밀번호 작성규칙 
                            <ul>
                                <li>영문 대소문자 각 1자리 이상 </li>
                                <li>숫자 1자리 이상 </li>
                                <li>특수문자 1자리 이상</li>
                                <li>8자리 이상 </li>
                                <li>비밀번호 입력 값 일치 </li>
                                <li>비밀번호 사용 불가능 예제 
                                    <ul>
                                        <li>연속된 숫자 3자 이상: 123 </li>
                                        <li>연속된 알파벳 3자 이상: abc  </li>
                                        <li>연속된 자판 배열 3자 이상: qwe</li>
                                        <li>동일한 숫자 또는 알파벳 2자 이상: aa, 11 </li>
                                        <li>쉬운 영문 단어: arm 등 </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="header_modal d-flex">
                            <div className="left">
                                <div className="title_left">변경 비밀번호 확인</div>
                            </div>
                            <div className="input_pw">
                                <Form.Item 
                                    rules={[{ required: true, message: '변경할 비밀번호를 한번 더 입력하세요' }]}
                                    name="new_password_1">
                                    <Input.Password style={{height: 40, width: '100%'}} placeholder="변경할 비밀번호를 한번 더 입력하세요."/>
                                </Form.Item>
                            </div>
                        </div>
						{
							textWarinng !== '' ? (
								<div className="warning">{textWarinng}</div>
							) : null
						}
						
                    </div>
					
                </div>
                <div>
                    <button htmlType="submit" className="footer">
						비밀번호 변경
                    </button>
                </div>
            </Form>
		</div>
	);
});

ChangePassWord.propTypes = {
  className: PropTypes.any,
};
export default styled(ChangePassWord)`
	.warning {
		background: #f14e4e;
		color: #ffff;
		padding: 10px;
		margin-top: 10px;
	}
	.desc {
		color: #8c8c8c;
	}
	.input_pw {
		width: 100%;
	}
	.modal_success {
		text-align: center;
    	padding: 50px 0;
	}
	.ant-form-item {
		margin-bottom: 0px;
	}
	.btn_bottom {
		margin-left: 120px;
		margin-top: 30px;
		.cancel {
			cursor: pointer;
			color: #000;
			padding: 9px 15px;
			background: #f2f2f2;
			margin-right: 20px;
			min-width: 100px;
			text-align: center;
		}
		.submit {
			cursor: pointer;
			min-width: 100px;
			color: #fff;
			background: #000;
			padding: 9px 15px;
		}
	}
  .title_profile {
		color: #000;
		margin-bottom: 30px;
	}
	.content {
		margin-left: 20px;
	}
	.left {
		min-width: 180px;
		margin-right: 20px;
		background: #f2f2f2;
		text-align: center;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		.title_left {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 35px;
			margin-bottom: 10px;
		}
	}
	.right {
		display: flex;
		flex-direction: column;
		.right_bottom {
			height: 35px;
			align-items: center;
			display: flex;
			color: #000;
		}
	
		.underline {
			cursor: pointer;
			font-weight: bold;
    		text-decoration: underline;
		}
	}
	.modal_change {
		.modal_content {
			padding: 24px;
		}
		.left {
			min-width: 120px;
			margin-right: 20px;
			background: #f2f2f2;
			text-align: center;
			.title_left {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 35px;
				margin-bottom: 10px;
			}
		}
	}
	.footer {
		justify-content: center;
		background: #000;
		display: flex;
		padding: 0;
		cursor: pointer;
		color: #fff;
		padding: 20px;
		width: 100%;
		text-align: center;
		border: none;
	}
	.ant-modal-body {
		padding: 0px;
	}
  
`;
