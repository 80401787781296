import React, { useState, useRef } from "react"
import { Row, Col } from 'antd'
import styled from "styled-components";
import { useLocation } from "react-router-dom";

 function OpenDescMulti({ event, className }) {
    const list_question = event.questions ? event.questions : []
    const [list_answer_select, setListAnswerSelect] = useState(Array(list_question.length).fill(null))
    const [index_playing, setIndexPlaying] = useState(null);
    const location = useLocation();
    const isNotEvent = location.search.includes("?hash_value");
    const renderQuestionText = (question, position) => {
        return <>
            <div style={{ fontSize: isNotEvent ? 14 : 12, marginTop: 10 }}>
                <b>{`${question.content}?`}</b>
            </div>
            <div style={{ fontSize: isNotEvent ? 13 : 11 }}>
                {
                   question && question.answers.map((item, idx) => {
                        return <div style={{ marginTop: 5,
                        }}
                            className='d-flex flex-row noselect' key={idx} onClick={() => {
                                list_answer_select[position] = item.hash_value
                                setListAnswerSelect([...list_answer_select])
                            }}>
                            <div
                                style={{
                                    width: 15,
                                    color: '#000',
                                    backgroundColor: list_answer_select[position] === item.hash_value ? '#1273e2' : '#fcfcfc',
                                }}>
                                <div style={{fontFamily: 'MalgunGothicRegular'}}>{idx + 1}.</div>
                            </div>
                            <div style={{
                                padding: '10px 10px', flex: 1,
                                fontWeight: list_answer_select[position] === item.hash_value ? 'bold' : 'normal'
                            }}>{item.content}</div>
                        </div>
                    })
                }
            </div>
        </>
    }

    const renderQuestionImage = (question, position) => {
        return <>
            <div style={{ fontSize: isNotEvent ? 14 : 12, marginTop: 10 }}>
                <b>{`${question.content}?`}</b>
            </div>
            <div style={{ padding: '16px 0px 0px 0px' }}>
                <Row gutter={[16, 16]}>
                    {
                         question && question.answers.map((item, idx) => {
                            return <Col key={idx}>
                                <div className="d-flex" style={{marginRight: 24}} onClick={() => {
                                    list_answer_select[position] = item.hash_value
                                    setListAnswerSelect([...list_answer_select])
                                }}>
                                    <div style={{ width: 20,}}>
                                        <div style={{ fontSize: isNotEvent ? 13 : 11, fontFamily: 'MalgunGothicRegular' }}>{idx + 1}.</div>
                                    </div>
                                    <img src={item.content}
                                        width={90} height={70} className='image-antd'
                                        style={{ borderRadius: 3, objectFit: 'cover',
                                            border: list_answer_select[position] === item.hash_value ? 'solid 2px #1273e2' : 'solid 1px #ededed'
                                    }} />
                                   
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </>
    }

    const renderQuestionVideo = (question, position) => {
        return <>
            <div style={{ fontSize: isNotEvent ? 14 : 12, marginTop: 10, marginBottom: 3 }}>
                <b>{`${question.content}?`}</b>
            </div>
            <div style={{ padding: '16px 0px' }}>
                <Row gutter={[{ xs: 8, sm: 16, md: 32 }, { xs: 8, sm: 16, md: 32 }]}>
                    {
                         question && question.answers.map((item, idx) => {
                            return <Col key={idx}>
                                <div className="d-flex">
                                    <div style={{ width: 17,}}>
                                        <div style={{ fontSize: isNotEvent ? 13 : 11, fontFamily: 'MalgunGothicRegular' }}>{idx + 1}.</div>
                                    </div>
                                    <div className="video_content">
                                        <video width="100%" height="100%" controls className='video'>
                                            <source src={item.content} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </>
    }
    return (
        <div className={className}>
            <div className='d-flex flex-column' style={{width: '100%'}}>
                <div className='flex-fill d-flex flex-column'>
                    <div className='d-none'>
                        <div className='d-flex flex-column justify-content-center' style={{ backgroundColor: '#F8F8F8', margin: '10px 0' }}>
                            <div style={{ paddingTop: 20, backgroundColor: 'white', fontSize: 13 }}>
                                {
                                    list_question.map((question, index) => (
                                       <div key={index} style={{marginTop: 19}}>
                                            <div style={{ breakBefore: 'auto' }}/>
                                            <div  className="pb_one_item">
                                                <div><b style={{ color: '#1273e2', fontSize: 14, fontFamily: 'MalgunGothicRegular' }}>Q{index + 1}</b> /{list_question.length}</div>
                                                {
                                                    question.answer_type === 'text' ? renderQuestionText(question, index) : (
                                                        question.answer_type === 'image' ? renderQuestionImage(question, index) : renderQuestionVideo(question, index)
                                                    )
                                                }
                                            </div>
                                            <div style={{ breakAfter: 'auto' }}/>
                                       </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default styled(OpenDescMulti)`
    width: 100%;
    .video_content {
        margin-right: 24px;
        overflow: hidden;
        position: relative;
        background-color: #000;
        border: 2px solid rgb(18, 115, 226);
        width: 96px;
        height: 70px;
        padding-top: 0px;
        .video {
            position: absolute;
            left: 0;
            top: 0;
            -webkit-object-fit: cover;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .noselect {
        align-items: center;
    }
    
`;
  