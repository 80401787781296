import React, { useState, useCallback, useEffect, memo } from "react";
import { Table, Checkbox } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { formatNumber, formatMETA } from "../../utils/helper";
import { TRANSACTION_TYPE } from "../../utils/constants";
import PopUpBase from '../../components/PopUpBase'
import { DownOutlined } from '@ant-design/icons';
import {META_BLOCK_EXPLORER_URL} from '../../utils/constants';
import * as style from '../../components/Variables';
import EmptyTable from '../EmptyTable';

const TRANSACTION_TYPE_ALL = {
    '': '전체',
    ...TRANSACTION_TYPE,
}

const ListWorkSpace = memo(
    ({ className, data, loadding, params, setParams, detail }) => {
    const [isShowModalReward, setShowModalReward] = useState(false)
    const [isShowModalReceiver_coin, setShowModalReceiver_coin] = useState(false)
    const [isShowModalSend_coin, setShowModalSend_coin] = useState(false)
    const [itemSelected, setItemSelected] = useState(null)

    const onFilterForColumn = ( objectNew, data, item ) => {
        if(objectNew.includes(item)) { /// Trạng thái active
            if(item === '') {
                objectNew = []
            } else {
                const index = objectNew.indexOf(item);
                objectNew.splice(index, 1);
            }
        } else { // trạng thái not_active
            if(item === '') {
                objectNew = [];
                for (var key in data) {
                    objectNew.push(key);
                }
            } else {
                objectNew.push(item)
            }
        }
        if(!objectNew.includes("") && objectNew.length ===  Object.keys(data).length - 1) {
            objectNew.push("")
        }
        if(objectNew.includes("") && item !== "" && objectNew.length <  Object.keys(data).length) {
            objectNew = objectNew.filter(x => x !== "")
        }
        return objectNew;
      
    }

    const getColumnSearchProps = useCallback(
        (dataIndex, dataName) => ({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <div style={{ padding: 8 }}>
                {
                    Object.keys(TRANSACTION_TYPE_ALL).map((item, index) => (
                        <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} key={index} className="d-flex" 
                        onClick={async () => {
                            let transaction_type = params.transaction_type;
                            const arrayNew  = await onFilterForColumn(transaction_type, TRANSACTION_TYPE_ALL, item)
                            if(arrayNew.length === 0) {
                                confirm()
                            }
                            setParams({...params, 
                                transaction_type: arrayNew,
                                offset: 1,
                                currentPage: 1,
                            })
                        }}
                      
                        >
                            <Checkbox checked={params.transaction_type.includes(item) ? true : false}/> 
                            <div style={{marginLeft: 10, color: params.transaction_type.includes(item) ? '#1890ff' : ''}}>
                                {TRANSACTION_TYPE_ALL[item]}</div>
                        </div>
                    ))
                }
              </div>
            ),
            filterIcon: filtered => <DownOutlined style={{ color: params.transaction_type.length > 0 ? '#1890ff' : '#000' }} />,
        }),
        [params]
    );

    const columns = [
        {
            title: "구분",
            dataIndex: "transaction_type",
            align: 'center',
            render: (value) => TRANSACTION_TYPE[value],
            ...getColumnSearchProps('transaction_type'),
            width: 150,
        },
        {
            title: "제목",
            dataIndex: "event_title",
            align: 'center',
        },
        {
            title: "수량",
            dataIndex: "value",
            align: 'center',
            render: (value) => <div><span style={{fontSize: formatMETA(value).length > 8 ? 13 : 14}}>{`${formatMETA(value)}`}</span> META</div>,
        },
        {
            title: "지갑주소",
            dataIndex: "receiver",
            align: 'center',
            render: (value, row) => {
                if(row.transaction_type === 'send_coin' || row.transaction_type === 'reward') {
                    return row.receiver
                } 
                if(row.transaction_type === 'receiver_coin') {
                    return row.sender
                } else {
                    return detail?.address
                }
            },
        },
        {
            title: "거래일자",
            dataIndex: "created_date",
            align: 'center',
            render: (value) => moment(value).format("YYYY.MM.DD"),
            width: 150,
        },
    ]

  
    return (
        <div className={className}>
            <Table
                locale={{ emptyText: <EmptyTable/> }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            if(record.transaction_type === 'reward') {
                                setShowModalReward(true)
                                setItemSelected(record)
                            } 
                            if(record.transaction_type === 'receiver_coin') {
                                setShowModalReceiver_coin(true)
                                setItemSelected(record)
                            } 
                            if(record.transaction_type === 'send_coin') {
                                setShowModalSend_coin(true)
                                setItemSelected(record)
                            } 
                        },
                    };
                }}
                loading={loadding}
                columns={columns} 
                dataSource={data}
                rowKey="transaction_hash"
                scroll={{ x: "calc(100%)" }}
                pagination={false}
            />
    {/* REWWARRRDDD */}
            <PopUpBase
                className={className}
                title={"META 코인 거래내역"}
                visible={isShowModalReward}
                closable={false}
                footer={"닫기"}
                width={500}
                onClickFooter={() => {setShowModalReward(false)}}
                onCancel={() => {setShowModalReward(false)}}
            >
                {
                    itemSelected ? (
                        <div className="modal_reward">
                            <div className="d-flex j-c-b">
                                <div className="name">리워드를 지급한 내역입니다.</div>
                                <div className="date">{ moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                            </div>
                            <div style={{textAlign: 'center', fontSize: 16, fontWeight: 'bold', paddingTop: 14, paddingBottom: 12}}>{itemSelected.event_title}</div>
                            <div className="content">
                                <div className="left">
                                    <div className="title_left">구분</div>
                                    <div className="title_left">수량</div>
                                    <div className="title_left">수수료</div>
                                    <div className="title_left">수령인 (META ID)</div>
                                    <div className="title_left m_b_10">수령 주소</div>
                                    <div className="title_left m_b_10">TXID</div>
                                </div>
                                <div className="right">
                                    <div className="right_bottom">리워드 지급 </div>
                                    <div className="right_bottom">{`${formatMETA(itemSelected.value)} META`}</div>
                                    <div className="right_bottom">{`${formatMETA(itemSelected.tx_fee)} META`}</div>
                                    <div className="right_bottom">{`${itemSelected.receiver_meta_id ? itemSelected.receiver_meta_id.substring(0, 2) : ''}...${itemSelected.receiver_meta_id ? itemSelected.receiver_meta_id.substr(itemSelected.receiver_meta_id.length - 4) : ''}`}</div>
                                    <div className="right_bottom m_b_10 word-break ">{itemSelected.receiver}</div>
                                    <div onClick={() => {
                                        window.open(`${META_BLOCK_EXPLORER_URL}tx/${itemSelected.transaction_hash}`, "_blank") //to open new page
                                    }} className="right_bottom m_b_10 underline word-break curor">{itemSelected.transaction_hash}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </PopUpBase>


            {/* REVicerIcon */}

            <PopUpBase
                className={className}
                title={"META 코인 거래내역"}
                visible={isShowModalReceiver_coin}
                closable={false}
                footer={"닫기"}
                width={500}
                onClickFooter={() => {setShowModalReceiver_coin(false)}}
                onCancel={() => {setShowModalReceiver_coin(false)}}
            >
                {
                    itemSelected ? (
                        <div className="modal_reward">
                            <div className="d-flex j-c-b">
                                <div className="name">META 코인을 받았습니다.</div>
                                <div className="date">{ moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                            </div>
                            <div className="content_2">
                                <div className="d-flex">
                                    <div className="summary_left">
                                        <p>구분</p>
                                        <p>수량</p>
                                        <p className="num_of_2">보낸 주소 </p> 
                                        <p>TXID</p>
                                    </div>
                                    <div className="summary_right">
                                        <p>{TRANSACTION_TYPE[itemSelected.transaction_type]} </p>
                                        <p>{`${formatMETA(itemSelected.value)} META`}</p>
                                        <p className="word-break">{itemSelected.sender}</p>
                                        <div onClick={() => {
                                             window.open(`${META_BLOCK_EXPLORER_URL}tx/${itemSelected.transaction_hash}`, "_blank") //to open new page
                                        }} className="txid word-break">{itemSelected.transaction_hash}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </PopUpBase>


                {/* /// SEND ICON */}
            <PopUpBase
                className={className}
                title={"META 코인 거래내역"}
                visible={isShowModalSend_coin}
                closable={false}
                footer={"닫기"}
                width={500}
                onClickFooter={() => {setShowModalSend_coin(false)}}
                onCancel={() => {setShowModalSend_coin(false)}}
            >
                {
                    itemSelected ? (
                        <div className="modal_reward">
                            <div className="d-flex j-c-b">
                                <div className="name">META 코인을 보냈습니다.</div>
                                <div className="date">{ moment(itemSelected.created_date).format("YYYY.MM.DD HH:mm")}</div>
                            </div>
                            <div className="content_2">
                                <div className="d-flex">
                                    <div className="summary_left">
                                        <p>구분</p>
                                        <p>수량</p>
                                        <p>수수료</p>
                                        <p className="num_of_2">수령 주소</p> 
                                        <p>TXID</p>
                                    </div>
                                    <div className="summary_right">
                                        <p>{TRANSACTION_TYPE[itemSelected.transaction_type]} </p>
                                        <p>{`${formatMETA(itemSelected.value)} META`}</p>
                                        <p>{`${formatMETA(itemSelected.tx_fee)} META`}</p>
                                        <p  className="word-break">{itemSelected.receiver}</p>
                                        <div onClick={() => {
                                             window.open(`${META_BLOCK_EXPLORER_URL}tx/${itemSelected.transaction_hash}`, "_blank") //to open new page
                                        }} className="txid word-break">{itemSelected.transaction_hash}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </PopUpBase>
        </div>
    );
});

ListWorkSpace.propTypes = {
  className: PropTypes.any,
};
export default styled(ListWorkSpace)`
    .ant-table-cell {
        word-break: break-word;
    }
    .ant-table-row {
        cursor: pointer;
    }
    .modal_reward {
        .name {
            color: #aeaeb5;
        }
        .date {
            text-align: right;
            color: #aeaeb5;
        }
        .content {
            display: flex;
            .m_b_10 {
                margin-bottom: 10px;
            }
            .underline {
                text-decoration: underline;
            }
            .left {
                padding-top: 4px;
                min-width: 140px;
                padding-right: 20px;
                background: ${style.color.backgroundGray};
                text-align: right;
                .title_left {
                    display: flex;
                    justify-content: flex-end;
                    height: 40px;
                }
            }
            .right {
                padding-top: 4px;
                display: flex;
                margin-left: 10px;
                flex-direction: column;
                .word-break {
                    word-break: break-all;
                    white-space: normal;
                    width: 300px;
                }
                .right_bottom {
                    overflow: hidden;
                    display: flex;
                    justify-content: flex-start;
                    height: 40px;
                }
            }
         }
        .content_2 {
            padding: 15px;
            
            .summary_left {
                padding: 10px 15px;
                background: ${style.color.backgroundGray};
                text-align: right;
                min-width: 140px;
                .num_of_2 {
                    padding-bottom: 22px;
                }
              }
              .summary_right {
                padding: 10px 15px;
                .word-break {
                    word-break: break-all;
                    white-space: normal;
                    width: 250px;
                }
                .txid {
                    cursor: pointer;
                    text-decoration: underline;
                }
              }
        }
        
    }
`;
