import React, { useState, useCallback, memo } from "react";
import { Checkbox, Table } from "antd";
import styled from "styled-components";
import PopUpBase from '../../components/PopUpBase'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import moment from "moment";
import ServiceBase from "../../utils/ServiceBase";
import { DownOutlined } from '@ant-design/icons';
import EmptyTable from '../EmptyTable';
import { useSelector } from 'react-redux'
import { setAuthenticated, logOut } from "../../store/actions";
import Globals from "../../utils/globals";

const ListUser = memo(
    ({ className, data, loadding, params, setParams, onRefreshList, onGetDetail, setAuthenticated }) => {
    const userProfile = useSelector(state => state.App.toJS().profile.user);

    const [isShowModalDel, setShowModalDel] = useState(false);
    const [isShowModalDelSuccess, setShowModalDelSuccess] = useState(false);
    const [itemSelectedDel, setItemSelectedDel] = useState(null)

    const onDeleteItem = useCallback(async () => {
        setShowModalDel(false)
        const result = await ServiceBase.requestJson({
            method: "DELETE",
            url: `accounts/${itemSelectedDel.id}`,
            data: params,
          });
          if (result.hasErrors) {
           
          } else {
            if(userProfile.id === itemSelectedDel.id) {
                Globals.clear();
                setAuthenticated({
                  isAuthenticated: false,
                  profile: null,
                });
            } else {
                onRefreshList()
            }
            setShowModalDelSuccess(true)
          }
    }, [itemSelectedDel])

    const getColumnSearchProps = useCallback(
        (dataIndex, dataName) => ({
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <div style={{ padding: 8 }}>
                    <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} className="d-flex" onClick={() => {
                        confirm()
                        setParams({...params, is_active: ""})
                    } }>
                        <Checkbox checked={params.is_active === "" ? true : false}/> 
                        <div style={{marginLeft: 10, color: params.is_active === "" ? '#1890ff' : ''}}>전체	</div>
                    </div>
                    <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} className="d-flex" onClick={() => {
                            confirm()
                            setParams({...params, is_active: false})
                        } }>
                        <Checkbox checked={params.is_active === false}/> 
                        <div style={{marginLeft: 10, color: params.is_active === false ? '#1890ff' : ''}}>비활성화</div>
                    </div>
                    <div style={{paddingTop: 2, paddingBottom: 2, cursor: 'pointer'}} className="d-flex" onClick={() => {
                        confirm()
                        setParams({...params, is_active: true})
                    } }>
                        <Checkbox checked={params.is_active}/> 
                        <div style={{marginLeft: 10, color: params.is_active ? '#1890ff' : ''}}>활성화	</div>
                    </div>
                  
              </div>
            ),
        }),
        [params]
    );

    const columns = [
        {
            title: "등록일",
            dataIndex: "date_joined",
            render: (value) => value ? moment(value).format('YYYY.MM.DD') : '',
            align: 'center',
        },
        {
            title: "상태",
            dataIndex: "is_active",
            render: (value) => value ? '활성화' : '비활성화',
            ...getColumnSearchProps("is_active"),
            filterIcon: filtered => <DownOutlined style={{ color: params.is_active !== '' ? '#1890ff' : '#000' }} />,
            align: 'center',
        },
        {
            title: "이름",
            dataIndex: "username",
            align: 'center',
        },
        {
            title: "이메일",
            dataIndex: "email",
            align: 'center',
        },
        {
            title: "전화번호",
            dataIndex: "phone_number",
            align: 'center',
        },
        {
            title: "관리",
            dataIndex: "action",
            align: 'center',
            render: (value, row) => (
                <div className="action d-flex">
                    <div className="btn-point"
                        onClick={() => {
                            setItemSelectedDel(row)
                            setShowModalDel(true)
                        }}
                    >삭제</div> | 
                    <div className="btn-point"
                        onClick={() => {
                            onGetDetail(row.id)
                        }}
                    >수정</div>
                </div>
            )
        },
    ]

    const renderContentModal = () => {
        return (
            <div className="modal_content">
                <div className="date">2020.08.13 15:16</div>
                <div className="content">
                    <div className="title_notifi">[survey_title] </div>
                    <div className="title_notifi title_notifi_2">설문이 시작되었습니다.  </div>
                    <div className="nofi_info">중단이 필요한 경우 상세 페이지에서 설정할 수 있습니다.</div>
                </div>
            </div>
        )
    }
		
    return (
		<div className={className}>
			<Table
                locale={{ emptyText: <EmptyTable/>}}
                loading={loadding}
				columns={columns} 
				dataSource={data}
				rowKey="id"
				scroll={{ x: "calc(100%)" }}
				pagination={false}
			/>
            {/* DELETTTEEEEEE */}
            <PopUpBase
                width={440}
                className={className}
                title={"관리자 삭제"}
                visible={isShowModalDel}
                closable={false}
                footer={"관리자 삭제"}
                onCancel={() => {setShowModalDel(false)}}
                footerNew={
                    <div className="footer_new">
                        <div onClick={() => setShowModalDel(false)} className="cancel">취소</div>
                        <div onClick={onDeleteItem} className="confirm">확인</div>
                    </div>
                }
            >
                {
                    itemSelectedDel ? (
                        <div className="modal_content">
                            <div>{itemSelectedDel.email}</div>
                            <div>관리자를 정말로 삭제하시겠습니까?</div>
                        </div>
                    ) : null
                }
                   
            </PopUpBase>


                {/* DELETTTEEEEEE SCUCEESSS */}
            <PopUpBase
                className={className}
                title={"관리자 삭제 완료"}
                visible={isShowModalDelSuccess}
                closable={false}
                footer={"확인"}
                onClickFooter={() => {setShowModalDelSuccess(false)}}
                onCancel={() => {setShowModalDelSuccess(false)}}
            >
                {
                    itemSelectedDel ? (
                        <div className="modal_content">
                            <div>{itemSelectedDel.email}</div>
                            <div>관리자가 삭제되었습니다.</div>
                        </div>
                    ) : null
                }
            </PopUpBase>
		</div>
    );
});

ListUser.propTypes = {
  className: PropTypes.any,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuthenticated,
      logOut,
    },
    dispatch
  );
const withConnect = connect(
	null,
	mapDispatchToProps
);

ListUser.propTypes = {
  className: PropTypes.any,
};
export default compose(
	withConnect,
	memo
)(styled(ListUser)`
    .modal_content {
        padding: 54px;
        text-align: center;
    }
    .action {
        justify-content: center;
        cursor: pointer;
        font-weight: bold;
    }
    .action div:hover {
        color: #1890ff;
    }
    .ant-modal-body {
        padding: 6px 24px;
        .modal_content {
            
        }
    }
    .btn-point {
        padding: 0 4px;
    }
    .footer_new {
        width: 100%;
        display: flex;
        .confirm {
            cursor: pointer;
            text-align: center;
            padding: 20px 0;
            width: 50%;
            background: #000;
            height: 100%;
            color: #fff;
        }
        .cancel {
            cursor: pointer;
            text-align: center;
            padding: 20px 0;
            width: 50%;
            background: #fff;
            height: 100%;
            color: #000;
        }
    }
`);
