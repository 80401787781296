
const constants = {
    EVENT_CATEGORY_LIST: {
        'brand_issue': 'Brand Issue',
        'hot_and_new': 'Hot & New',
        'politics': 'Politics',
    },
    EVENT_TYPE_LIST: {
        'campaign': 'Campaign',
        'survey_yes_no': 'Survey Yes No',
        'survey_multiple': 'Survey Multiple',
    },
    STATUS: {
        'draft': 'Draft',
        'stand_by': 'Standby',
        'waiting_display': 'Waiting for Display',
        'displaying': 'Displaying',
        'on_going': 'On Going',
        'completed': 'Completed',
    },
    EVENT_REWARD_TITLE: {
        'campaign': '서명/ 캠페인',
        'survey_yes_no': '찬반투표',
        'survey_multiple': '설문(다문항)',
    },
    EVENT_STANDBY: {
        'campaign': '대기 중 상태입니다. 진행기간을 확인한 후 다시 참여해주세요.',
        'survey_yes_no': '대기 중 상태입니다. 진행기간을 확인한 후 다시 참여해주세요.',
        'survey_multiple': '대기 중 상태입니다. 진행기간을 확인한 후 다시 참여해주세요.',
    },
    EVENT_STOP: {
        'campaign': '중단된 서명/ 캠페인입니다.',
        'survey_yes_no': '중단된 찬반투표입니다.',
        'survey_multiple': '중단된 설문입니다.',
    },
    EVENT_OPEN: {
        'campaign': '서명/ 캠페인이 진행 중입니다. 많이 참여해주세요.',
        'survey_yes_no': '찬반투표가 진행 중입니다. 많이 참여해주세요.',
        'survey_multiple': '설문이 진행 중입니다. 많이 참여해주세요.',
    },
    EVENT_CLOSE: {
        'campaign': '서명/ 캠페인이 종료되었습니다. 결과를 확인해보세요.',
        'survey_yes_no': '찬반투표가 종료되었습니다. 결과를 확인해보세요.',
        'survey_multiple': '설문이 종료되었습니다. 결과를 확인해보세요.',
    }
}

export default constants